import React from 'react'
import InputLabel from '@mui/material/InputLabel/InputLabel'
import Box from '@mui/material/Box/Box'
import InputBase, { InputBaseProps } from '@mui/material/InputBase/InputBase'
import { SxProps } from '@mui/material/styles'
import { Typography } from '@mui/material'

import { useUIBuilderHint } from '../Hint/UIBuilderHintContext'

export interface StringAttributeInputProps extends InputBaseProps {
  inputStyles: SxProps
  labelStyles: SxProps
  attrKey: string
  setValState: (val: string) => void
  fireChange: () => void
  val: string | undefined
  optional?: boolean
  hint?: string
}

export const StringAttributeInput: React.FC<StringAttributeInputProps> = ({
  attrKey,
  setValState,
  fireChange,
  labelStyles,
  inputStyles,
  val,
  optional,
  hint,
  ...otherProps
}) => {
  const { hintButton } = useUIBuilderHint()
  return (
    <Box>
      <InputLabel variant="standard" sx={labelStyles}>
        {attrKey}
        {optional && (
          <Typography
            variant="caption"
            sx={{
              color: theme => theme.palette.primary.light,
            }}
          >
            {' - optional'}
          </Typography>
        )}
        {hint && hintButton({ title: attrKey, body: hint })}
      </InputLabel>
      <InputBase
        id={`${attrKey}-input`}
        onChange={event => setValState(event.target.value)}
        onBlur={fireChange}
        value={val ?? ''}
        sx={inputStyles}
        {...otherProps}
      />
    </Box>
  )
}
