import React from 'react'
import getSymbolFromCurrency from 'currency-symbol-map'
import clsx from 'clsx'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import InputLabel from '@mui/material/InputLabel'
import { InputBase } from '@mui/material'

import { CurrencyInputModel } from '../../../types'
import { getJuvoInfo } from '../../../store'
import {
  DisabledWheelProps,
  InputStateProps,
  isDefined,
  sanitizeInputName,
} from '../../../utils'

const CurrencyInput: React.ForwardRefRenderFunction<
  HTMLDivElement,
  {
    comp: CurrencyInputModel
    inputStateProps: InputStateProps
    disabledWheelProps: DisabledWheelProps
  }
> = ({ comp, inputStateProps, disabledWheelProps }, ref) => {
  const juvoInfo = getJuvoInfo('CurrencyInput', comp)
  const currencySymbol = getSymbolFromCurrency(comp.currency_code || '')
  const label = comp.text || ''
  const recommendation = comp.recommendation
  const { handleFocused } = disabledWheelProps

  const {
    value,
    setValue,
    handleBlur,
    handlersFromServer,
    hasErrors,
    errorMessage,
  } = inputStateProps
  const inputName = sanitizeInputName(label)

  return (
    <FormControl
      error={hasErrors}
      variant="standard"
      fullWidth
      className="juvo-input-control"
      {...juvoInfo}
    >
      <InputLabel shrink htmlFor={inputName} className="juvo-input-label">
        {label}
      </InputLabel>
      <InputBase
        {...handlersFromServer}
        ref={ref}
        type="number"
        id={inputName}
        placeholder={comp.placeholder}
        name={inputName}
        value={
          isDefined(value)
            ? value
            : isDefined(recommendation)
              ? recommendation
              : ''
        }
        onChange={event => {
          setValue(event.target.value)
        }}
        onFocus={() => {
          handleFocused(true)
          handlersFromServer.onFocus && handlersFromServer.onFocus()
        }}
        onBlur={() => {
          handleFocused(false)
          handleBlur()
        }}
        className={clsx(
          'juvo-input-base',
          isDefined(recommendation) && !isDefined(value) && 'recommendation',
        )}
        sx={{
          '.MuiInputBase-input': {
            paddingLeft: '28px',
          },
          '&:before': {
            display: 'block',
            content: `"${currencySymbol}"`,
            position: 'absolute',
            left: '12px',
          },
        }}
        data-testid={comp.data_testid}
      />
      {hasErrors && (
        <FormHelperText
          sx={{ visibility: hasErrors ? 'visible' : 'hidden' }}
          data-testid={`currency-helptext-${comp.data_testid}`}
        >
          {errorMessage || ' '}
        </FormHelperText>
      )}
    </FormControl>
  )
}

export default React.forwardRef(CurrencyInput)
