import React from 'react'
import InputLabel from '@mui/material/InputLabel/InputLabel'
import Box from '@mui/material/Box/Box'
import List from '@mui/material/List/List'
import ListItem from '@mui/material/ListItem/ListItem'
import Button from '@mui/material/Button/Button'
import Icon from '@mui/material/Icon/Icon'
import { IconButton, SxProps } from '@mui/material'

import { ButtonModel, ComponentModel } from '../../../types'
import { useUIBuilder } from '../../../providers/UIBuilderProvider'
import { DrawerComponentEditor } from '../Drawer/DrawerComponentEditor'

interface ButtonsAttributeInputProps {
  labelStyles: SxProps
  inputStyles: SxProps
  applyVal: (comp: ComponentModel) => void
  comp: ComponentModel
}

export const ButtonsAttributeInput: React.FC<ButtonsAttributeInputProps> = ({
  applyVal,
  labelStyles,
  inputStyles,
  comp,
}) => {
  const { genIdsWithChildren } = useUIBuilder()

  const addButton = () => {
    const newButton = genIdsWithChildren({ ...comp.buttons[0] })
    const newComp = {
      ...comp,
      buttons: comp.buttons.concat([newButton]),
    }
    applyVal(newComp)
  }

  const deleteButton = (index: number) => {
    if (comp.buttons.length <= 1) {
      return
    }
    const newButtons = [...comp.buttons].toSpliced(index, 1)
    const newComp = {
      ...comp,
      buttons: newButtons,
    }
    applyVal(newComp)
  }

  const applyBtn = (button: ComponentModel, index: number) => {
    const newButtons = [...comp.buttons]
    newButtons[index] = button
    const newComp = {
      ...comp,
      buttons: newButtons,
    }
    applyVal(newComp)
  }

  return (
    <Box>
      <List
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '4px',
        }}
      >
        {comp.buttons.map((b: ButtonModel, index: number) => (
          <ListItem
            key={index}
            sx={{
              position: 'relative',
              border: '1px solid var(--color-border)',
              padding: '6px',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <InputLabel sx={{ ...labelStyles }}>Button</InputLabel>
              <IconButton
                className="fas fa-sharp fa-trash"
                onClick={() => deleteButton(index)}
                sx={{
                  fontSize: '0.8em',
                  color: theme => theme.palette.tertiary.main,
                }}
              />
            </Box>
            <DrawerComponentEditor
              comp={b}
              labelStyles={labelStyles}
              inputStyles={inputStyles}
              updateComp={b => applyBtn(b, index)}
            />
          </ListItem>
        ))}
      </List>
      <Button
        variant="outlined"
        color="secondary"
        onClick={addButton}
        startIcon={<Icon className="fas fa-sharp fa-plus" />}
        sx={{
          width: '100%',
        }}
      >
        Button
      </Button>
    </Box>
  )
}
