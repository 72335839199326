import { useState } from 'react'
import React from 'react'

import { ComponentModel } from '../../types'
import { useSnackbar } from '../../providers/SnackbarProvider'
import { jsonToXmlComponent } from '../../network/XmlInterop'
import { logErr } from '../../utils/Logger'

import { InteropResType, ResponseModal } from './ResponseModal'

export type ConvertableChildren = (
  compToJson: () => void,
  compToXml: () => void,
  isLoading: boolean,
) => React.ReactElement<HTMLElement>

type ComponentToJsonXmlProps = {
  myComp: ComponentModel
  children: ConvertableChildren
}

export const ComponentToJsonXml = ({
  myComp,
  children,
}: ComponentToJsonXmlProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const [jsonOrXmlRes, setJsonOrXml] = useState<string>('')
  const [resType, setResType] = useState<InteropResType>('xml')
  const { queueMessage } = useSnackbar()

  const compToJson = () => {
    setResType('json')
    const json = JSON.stringify(myComp, null, 2)
    setJsonOrXml(json)
  }
  const compToXml = () => {
    setIsLoading(true)
    setResType('xml')
    jsonToXmlComponent(myComp)
      .then(res => {
        if (typeof res === 'string') {
          setJsonOrXml(res)
        } else {
          queueMessage({
            title: 'Request Error',
            subtitle: 'The backend could not process the json',
            subType: 'failure',
          })
        }
      })
      .catch(e => {
        logErr(e)
        queueMessage({
          title: 'Request Error',
          subtitle: 'Something went wrong',
          subType: 'failure',
        })
      })
      .finally(() => setIsLoading(false))
  }

  return (
    <>
      <ResponseModal
        response={jsonOrXmlRes}
        type={resType}
        closeFn={() => setJsonOrXml('')}
      />
      {children(compToJson, compToXml, isLoading)}
    </>
  )
}
