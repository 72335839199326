import React from 'react'
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Dialog,
  AlertColor,
  DialogProps,
  SxProps,
} from '@mui/material'

import { isDefined } from '../../utils'
import { getNotificationIconFromSeverity } from '../../utils/Notifications'

interface JuvoDialogProps extends DialogProps {
  title: string
  children: React.ReactNode
  severity: AlertColor
  open: boolean
  handleClose: () => void
  buttonText?: string
  buttons?: React.ReactElement
  sx?: SxProps
}

export const JuvoDialog: React.FC<JuvoDialogProps> = ({
  title,
  children,
  severity,
  open,
  handleClose,
  buttonText,
  buttons,
  ...otherProps
}) => {
  const { sx, ...notSxProps } = otherProps
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="juvo-modal-title"
      aria-describedby="juvo-modal-description"
      sx={{
        zIndex: theme => theme.zIndex.modal,
        '.MuiDialog-paper': {
          width: { xs: '90vw', sm: '75vw', lg: '50vw' },
          borderRadius: 0,
          maxHeight: '80vh',
          maxWidth: 'unset',
        },
        ...sx,
      }}
      {...notSxProps}
    >
      <DialogTitle
        sx={{
          background: style =>
            severity === 'info'
              ? style.palette.primary.dark
              : style.palette[severity].dark,
          padding: '22px 32px',
          display: 'flex',
          alignItems: 'center',
          gap: '12px',
          color: 'white',
        }}
        data-testid="juvo-modal-title"
      >
        {getNotificationIconFromSeverity(severity, {
          background: 'white',
          padding: '8px',
          fontSize: '1.6rem',
        })}
        {title}
      </DialogTitle>

      <DialogContent
        sx={{
          padding: '20px 32px !important',
          minHeight: '100px',
        }}
      >
        {children}
      </DialogContent>

      <DialogActions
        sx={{
          padding: '12px 32px',
          display: 'flex',
          justifyContent: 'right',
          gap: '12px',
          background: style => style.palette.secondary.main,
        }}
      >
        {buttons ? (
          buttons
        ) : (
          <Button
            onClick={handleClose}
            data-testid={`btn-modal-${buttonText ?? 'close'}`}
          >
            {isDefined(buttonText) ? buttonText : 'Close'}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}
