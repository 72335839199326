import { Box, Typography } from '@mui/material'
import React, { useEffect, useRef } from 'react'
import { useDrag } from 'react-dnd'

import { ComponentModel, JuvoDroppableType } from '../../../types'

export const DrawerComponent = ({
  image,
  compModel,
  name,
  onClickToAdd,
}: {
  image: any
  compModel: ComponentModel
  name: string
  onClickToAdd: () => void
}) => {
  const dragPreview = useRef<HTMLImageElement>(null)
  const [, drag, preview] = useDrag(
    () => ({
      type: JuvoDroppableType.BUILDER_ITEM,
      item: { comp: compModel, init: true },
      collect: monitor => ({
        opacity: monitor.isDragging() ? 0.4 : 1,
      }),
    }),
    [name, JuvoDroppableType.BUILDER_ITEM],
  )
  useEffect(() => {
    if (dragPreview.current) {
      preview(dragPreview.current)
    }
  }, [preview])
  return (
    <Box
      sx={{
        padding: '6px',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        textAlign: 'center',
        cursor: 'grab',
        width: '92px',
        img: {
          border: '1px solid transparent',
          width: '100%',
          transition: 'border 0.2s ease',
        },
        '&:hover': {
          img: {
            border: theme => `1px solid ${theme.palette.primary.light}`,
          },
        },
      }}
      onClick={onClickToAdd}
    >
      <img src={image} ref={drag} />
      <img src={image} ref={dragPreview} style={{ display: 'none' }} />
      <Typography
        variant="caption"
        sx={{
          textTransform: 'capitalize',
          color: theme => {
            return theme.palette.common.white
          },
        }}
      >
        {name}
      </Typography>
    </Box>
  )
}
