import { useEffect } from 'react'
import { EventEmitter } from 'eventemitter3'

const emitter = new EventEmitter()

export const useSub = <T>(event: string, callback: (d: T) => void) => {
  const unsubscribe = () => {
    emitter.off(event, callback)
  }

  useEffect(() => {
    emitter.on(event, callback)
    return unsubscribe
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callback])

  return unsubscribe
}

export const usePub = <T>() => {
  return (event: string, data: T) => {
    emitter.emit(event, data)
  }
}
