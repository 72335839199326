import React from 'react'
import Box from '@mui/material/Box'
import { alpha, Icon, IconButton, Typography } from '@mui/material'

const EnvironmentBar: React.FC<{
  display: boolean
  environment: string
  setEnvDisplay: (d: boolean) => void
}> = ({ display, environment, setEnvDisplay }) => {
  return (
    <>
      {display && (
        <Box
          sx={{
            position: 'absolute',
            top: {
              xs: 'var(--header-height-mobile)',
              sm: 'var(--header-height)',
            },
            left: 0,
            width: '100%',
            borderBottom: theme => `1px solid ${theme.palette.primary.light}`,
            height: 'var(--ui-environment-bar)',
            display: 'flex',
            zIndex: theme => theme.zIndex.drawer - 1,
            alignItems: 'center',
            backdropFilter: 'blur(2px)',
            backgroundColor: theme => alpha(theme.palette.text.primary, 0.7),
            justifyContent: 'center',
          }}
        >
          <Typography
            sx={{
              zIndex: 1,
              color: theme => theme.palette.common.white,
              fontWeight: 600,
              display: 'flex',
              alignItems: 'center',
              fontFamily: '"Ubuntu Mono", monospace !important',
              textTransform: 'capitalize',
            }}
          >
            <Icon
              className="fas fa-sharp fa-traffic-cone"
              sx={{
                marginRight: '8px',
              }}
            />
            {`${environment} environment`}
            <IconButton
              className="fas fa-sharp fa-circle-xmark"
              onClick={() => setEnvDisplay(false)}
              sx={{
                fontSize: '1em',
                position: 'absolute',
                opacity: 0.75,
                top: '50%',
                right: '16px',
                transform: 'translateY(-50%)',
              }}
            />
          </Typography>
        </Box>
      )}
    </>
  )
}

export default EnvironmentBar
