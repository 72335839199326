import React from 'react'
import CodeMirror, { ViewUpdate } from '@uiw/react-codemirror'
import { langs } from '@uiw/codemirror-extensions-langs'
import { LanguageSupport } from '@codemirror/language'

import { isDefined } from '../../utils'

export const JuvoCodeMirror: React.FC<{
  value: string
  onChange?: (value: string, viewUpdate: ViewUpdate) => void
  extensions?: LanguageSupport[]
}> = ({ value, onChange, extensions }) => {
  return (
    <CodeMirror
      value={value}
      extensions={isDefined(extensions) ? extensions : [langs.xml()]}
      editable={isDefined(onChange)}
      onChange={onChange}
    />
  )
}
