import {
  isWhenCaseBoolString,
  isWhenCaseInclusive,
  isWhenDefault,
  WhenChildModel,
} from '../types'

import { isUndefined } from './Undefined'

export function findMatchingChildIndex(
  children: WhenChildModel[],
  whenBranch: any,
): number {
  return children.findIndex((c: WhenChildModel) => {
    if (isWhenDefault(c)) {
      return isUndefined(whenBranch)
    } else if (isWhenCaseBoolString(c)) {
      return (
        c.when === whenBranch ||
        (isWhenCaseInclusive(c) && c.when._inclusive_ === whenBranch)
      )
    }
    return false
  })
}
