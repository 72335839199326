import React, { useEffect, useState } from 'react'
import InputLabel from '@mui/material/InputLabel/InputLabel'
import Box from '@mui/material/Box/Box'
import List from '@mui/material/List/List'
import ListItem from '@mui/material/ListItem/ListItem'
import Button from '@mui/material/Button/Button'
import Icon from '@mui/material/Icon/Icon'
import { SxProps } from '@mui/material'

import { AppSkeleton, ComponentModel, Tab } from '../../../types'

import { TabNameInput } from './TabsAttributeInput'

interface TopLevelTabsInputProps {
  inputStyles: SxProps
  labelStyles: SxProps
  applyVal: (comp: ComponentModel) => void
  appSkel: AppSkeleton
}

export const TopLevelTabsInput: React.FC<TopLevelTabsInputProps> = ({
  applyVal,
  labelStyles,
  inputStyles,
  appSkel,
}) => {
  const [tabs, setTabs] = useState<Tab[]>(appSkel.tabs ?? [])

  const updateTab = (val: Tab, index: number) => {
    const newTabs = [...tabs]
    newTabs[index] = val
    const newSkel = {
      ...appSkel,
      tabs: newTabs,
    }
    applyVal(newSkel)
  }

  const deleteTab = (index: number) => {
    if (tabs.length === 1 || index === 0) {
      return
    }
    const newValues = [...tabs].toSpliced(index, 1)
    const newComps = appSkel.components.map(c => {
      if (c.tab_id && c.tab_id === index) {
        return { ...c, tab_id: index - 1 }
      } else return c
    })
    const newSkel = {
      ...appSkel,
      components: newComps,
      tabs: newValues,
    }
    applyVal(newSkel)
  }

  const addFirstTabs = () => {
    const newTabs = [
      { tab_icon: '', tab_name: 'tab-1', id: 0 },
      { tab_icon: '', tab_name: 'tab-2', id: 1 },
    ]
    const newComps = appSkel.components.map(c => {
      c.tab_id = 0
      return c
    })
    const newSkel = {
      ...appSkel,
      components: newComps,
      tabs: newTabs,
    }
    applyVal(newSkel)
  }

  const addTab = () => {
    const tabName = `Tab-${tabs.length + 1}`
    const newTabs = [...tabs].concat([
      { tab_icon: '', tab_name: tabName, id: tabs.length },
    ])
    const newSkel = {
      ...appSkel,
      tabs: newTabs,
    }
    applyVal(newSkel)
  }

  useEffect(() => {
    setTabs(appSkel.tabs ?? [])
  }, [appSkel])

  return (
    <Box>
      <InputLabel sx={labelStyles}>Tabs</InputLabel>
      {tabs.length > 0 && (
        <>
          <List
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '4px',
            }}
          >
            {tabs.map((tab, index) => (
              <ListItem
                key={index}
                sx={{
                  padding: 0,
                }}
              >
                <TabNameInput
                  inputStyles={inputStyles}
                  value={tab.tab_name}
                  updateTab={v => updateTab({ ...tab, tab_name: v }, index)}
                  deleteTab={() => deleteTab(index)}
                />
              </ListItem>
            ))}
          </List>
          <Button
            sx={{
              width: '100%',
            }}
            onClick={addTab}
            variant="outlined"
            color="secondary"
            startIcon={<Icon className="fa fa-plus" />}
          >
            Tab
          </Button>
        </>
      )}
      {!tabs.length && (
        <Button
          sx={{
            width: '100%',
          }}
          onClick={addFirstTabs}
          variant="outlined"
          color="secondary"
          startIcon={<Icon className="fa-sharp fa-solid fa-table-columns" />}
        >
          Add Tabs
        </Button>
      )}
    </Box>
  )
}
