import { ComponentModel } from '../types'

import { isUndefined } from './Undefined'

export const findSharingContainer = (
  compArray: ComponentModel[] | null,
  useSharingContainer: string,
): ComponentModel | null => {
  if (!compArray) return null

  for (const item of compArray) {
    if (item.sharing_container === useSharingContainer) {
      return item
    }
    if (item.tchildren) {
      const parent = findSharingContainer(item.tchildren, useSharingContainer)
      if (parent) return item
    }
  }
  return null
}

export const findSharedItems = (
  comp: ComponentModel,
  useSharingProperty: string,
) => {
  const data: string[] = []
  if (isUndefined(comp)) return data

  if (comp.sharing_property === useSharingProperty) {
    data.push(comp.value)
  }

  if (comp.tchildren) {
    for (const child of comp.tchildren) {
      const compValue = findSharedItems(child, useSharingProperty)
      data.push(...compValue)
    }
  }
  return data
}
