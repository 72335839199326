import React, { useEffect, useState } from 'react'
import { Box, Icon, InputLabel, Link } from '@mui/material'
import { isUndefined } from 'lodash'
import { BlobUploadCommonResponse } from '@azure/storage-blob'

import {
  AppRegistration,
  Command,
  FileModel,
  LinkUpload,
  UploadModel,
  Warning,
} from '../../../types'
import { useGuid } from '../../../providers/GuidProvider'
import { getFileToken, uploadFile } from '../../../network/BlobUpload'
import { isDefined, logErr, sanitizeUrl } from '../../../utils'
import { setFileDropValue, setUploadLinksValue } from '../../../store/File'

import { UploadInput } from './UploadInput'
import { FileContainer } from './FileContainer'
import { FileError } from './FileError'

type BlobUpload = {
  name: string
  uploadResponse: BlobUploadCommonResponse
}

export const Upload: React.FC<{
  comp: UploadModel
  onCommand: (cmd: Command) => void
  onChange: (_: UploadModel) => void
  appInfo: [Warning, AppRegistration]
}> = ({ comp, onCommand, onChange, appInfo }) => {
  const [errors, setErrors] = useState<Array<string>>([])
  const [loading, setLoading] = useState<boolean>(false)
  const { guid } = useGuid()
  const hasLinks =
    isDefined(comp.links) &&
    comp.links.length > 0 &&
    comp.links.filter(v => v.url !== '').length > 0
  const [, { app_id }] = appInfo

  useEffect(() => {
    if (isDefined(comp.files_raw) && comp.files_raw.length > 0) {
      upload(comp.files_raw)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comp.files_raw])

  const pushError = (newE: string) => {
    setErrors(errors.concat([newE]))
  }

  const getTokenAndUpload = async (f: FileModel) => {
    return getFileToken(guid, f.name, app_id)
      .then(async res => {
        if (res.type === 'right') {
          const content = res.content
          const uploadRes = await uploadFile(
            content.url,
            content.token,
            f.contents,
          )
          return { name: f.name, uploadResponse: uploadRes }
        } else {
          pushError(f.name)
          logErr(res.content.errorMsg)
          return null
        }
      })
      .catch(err => {
        pushError(f.name)
        logErr(err)
        return null
      })
  }

  const mapUploadResponse = (
    uploadResponse: (null | BlobUpload)[],
  ): LinkUpload[] => {
    return uploadResponse.map(res => {
      if (res == null || isUndefined(res?.uploadResponse)) {
        logErr('Null upload response')
        return { '@': 'FileUploadLink', name: '', url: '' }
      }
      const tokenlessUrl =
        res.uploadResponse._response.request.url.split('?')[0]
      return {
        '@': 'FileUploadLink',
        name: res.name,
        url: tokenlessUrl,
      }
    })
  }

  const removeFileFromComp = (filename: string): void => {
    const filtered = comp.links?.filter(f => f.name !== filename)
    onChange(setUploadLinksValue(comp, filtered ?? []))
  }

  const attachLinksToComp = (
    uploadResponse: Array<null | BlobUpload>,
    comp: UploadModel,
  ) => {
    try {
      const urls = mapUploadResponse(uploadResponse)
      const current = comp.links ?? []
      return setUploadLinksValue(comp, current.concat(urls))
    } catch (e) {
      logErr('Failed to parse upload response ', e)
      return comp
    }
  }

  const upload = (files: FileModel[]) => {
    setLoading(true)
    const noFailedFiles = files.filter(f => f.contents !== '')
    const noDupes = noFailedFiles.filter(
      file => !comp.links?.some(l => l.name === file.name),
    )
    const tokenPromises = noDupes.map(getTokenAndUpload)
    Promise.all(tokenPromises)
      .then(res => {
        if (noDupes.length < 1) return
        comp = attachLinksToComp(res, comp)
      })
      .finally(() => {
        onChange(setFileDropValue(comp, []))
        setLoading(false)
      })
  }

  return (
    <Box
      sx={{
        width: {
          sm: '100%',
          md: '600px',
        },
        maxWidth: '100%',
        marginBottom: '24px',
      }}
    >
      <InputLabel shrink className="juvo-input-label">
        Upload one or more files
      </InputLabel>
      <UploadInput
        compData={comp}
        onChange={onChange}
        onCommand={onCommand}
        accept={comp.accept}
        text={comp.title ?? 'Click to add files'}
        exteriorLoading={loading}
      />
      {hasLinks &&
        // Non-null assertion because typescript seems unaware of hasLinks checking nullness already
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        comp.links!.map((link, index) => (
          <FileContainer
            key={index}
            success
            removeAction={() => removeFileFromComp(link.name)}
          >
            <>
              <Icon
                className="fa-regular fa-external-link"
                fontSize="medium"
                sx={{
                  color: theme => theme.palette.text.secondary,
                  fontSize: '1.2rem',
                }}
              />
              <Link
                href={sanitizeUrl(link.url)}
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  fontWeight: 500,
                  fontSize: '0.9rem',
                  color: theme => theme.palette.text.primary,
                }}
                data-testid={`link-upload-${link.name}`}
              >
                {link.name}
              </Link>
              <Box
                sx={{
                  fontWeight: 500,
                  fontSize: '0.9rem',
                }}
              >
                - Uploaded
              </Box>
            </>
          </FileContainer>
        ))}
      {errors.map((e, index) => {
        return <FileError e={e} key={index} />
      })}
    </Box>
  )
}
