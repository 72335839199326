import React from 'react'
import Icon from '@mui/material/Icon/Icon'

import {
  StringAttributeInput,
  StringAttributeInputProps,
} from './StringAttributeInput'

export const IconAttributeInput: React.FC<
  StringAttributeInputProps
> = props => {
  return (
    <StringAttributeInput
      {...props}
      startAdornment={<Icon className={props.val} sx={{ padding: '0 6px' }} />}
    />
  )
}
