import React from 'react'
import Chip from '@mui/material/Chip'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import Downshift from 'downshift'

import { MakeState, TagListModel } from '../../../types'
import { setComponentValue } from '../../../store/Component'
import { isDefined, isUndefined, reorder } from '../../../utils'
import { ReorderElement } from '../../juvo-container/ReorderElement/ReorderElement'

const TagList: React.FC<{
  comp: TagListModel
  onChange: (_: TagListModel) => void
  stateHandler: MakeState<string>
}> = ({ comp, onChange, stateHandler }) => {
  const { recommendation, placeholder, title, value } = comp
  const showedItems = isDefined(value) ? (value ?? []) : (recommendation ?? [])
  const isRecommended = isDefined(recommendation) && isUndefined(value)
  const [inputValue, setInputValue] = stateHandler
  const [focused, setFocused] = React.useState(false)

  const changeItems = (newItems: string[]) =>
    onChange(setComponentValue<string[]>(comp)(newItems))

  const moveTag = (dragIndex: number, hoverIndex: number) => {
    const items = reorder(showedItems, dragIndex, hoverIndex)
    changeItems(items)
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement
    if (event.key === 'Enter') {
      const newSelectedItem = [...showedItems]
      // avoid white values
      if (!target.value.replace(/\s/g, '').length) return

      // avoid duplicated values
      const duplicatedValues = newSelectedItem.find(
        item => item === target.value.trim(),
      )

      if (duplicatedValues) {
        setInputValue('')
        return
      }

      newSelectedItem.push(target.value.trim())
      changeItems(newSelectedItem)
      setInputValue('')
    }
    if (showedItems.length && !inputValue.length && event.key === 'Backspace') {
      changeItems(showedItems.slice(0, showedItems.length - 1))
    }
  }

  const getFocusStyle = () => {
    return focused
      ? {
          borderColor: 'transparent !important',
          outline: '2px solid var(--color-primary) !important',
        }
      : null
  }

  const handleDelete = (item: string) => () => {
    const newSelectedItem: string[] = [...showedItems]
    newSelectedItem.splice(newSelectedItem.indexOf(item), 1)
    changeItems(newSelectedItem)
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value)
  }

  return (
    <Downshift
      id="downshift-multiple"
      inputValue={inputValue}
      selectedItem={showedItems}
    >
      {({ getInputProps }) => {
        const { onBlur, onChange, onFocus } = getInputProps({
          onKeyDown: handleKeyDown,
          placeholder,
        })
        return (
          <div>
            <TextField
              slotProps={{
                input: {
                  startAdornment: (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                      {showedItems.map((item: any, index: number) => (
                        <ReorderElement
                          id={`${comp.id}-listItem-${index}`}
                          acceptType="any"
                          index={index}
                          key={index}
                          updateOrder={moveTag}
                        >
                          {(_isDragging, dragHandle, preview) => (
                            <Box
                              sx={{
                                display: 'flex',
                                cursor: 'move',
                                flexWrap: 'wrap',
                                overflow: 'hidden',
                              }}
                            >
                              <Box sx={{ margin: '4px' }} ref={preview}>
                                <Chip
                                  ref={dragHandle}
                                  tabIndex={-1}
                                  label={item}
                                  onDelete={handleDelete(item)}
                                  sx={{
                                    background: theme =>
                                      isRecommended
                                        ? theme.palette.info.main
                                        : theme.palette.grey[300],
                                  }}
                                />
                              </Box>
                            </Box>
                          )}
                        </ReorderElement>
                      ))}
                    </Box>
                  ),
                  onBlur: (event: React.FocusEvent<any>) => {
                    setFocused(false)
                    onBlur?.(event)
                  },
                  onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                    handleInputChange(event)
                    onChange?.(event)
                  },
                  onFocus: (event: React.FocusEvent<any>) => {
                    setFocused(true)
                    onFocus?.(event)
                  },
                  onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => {
                    handleKeyDown(event)
                  },
                  placeholder: placeholder ?? 'Add tags',
                },
                inputLabel: {
                  className: 'juvo-input-label',
                },
              }}
              value={inputValue}
              fullWidth
              variant="standard"
              id="tags"
              name="tags"
              label={title}
              color="primary"
              sx={{
                marginBottom: '16px',
                '& .MuiInputBase-root': {
                  display: 'flex',
                  flexDirection: 'column',
                  width: { xs: '275px', sm: '500px', md: '650px' },
                  border: '1px solid var(--color-border)',
                  padding: '4px 12px',
                  marginTop: '0',
                  '&:before': {
                    display: 'none',
                  },
                  '&:after': {
                    display: 'none',
                  },
                  '&:hover': {
                    borderColor: 'transparent !important',
                    outline: '2px solid var(--color-hover)',
                  },
                  ...getFocusStyle(),
                },
              }}
              data-testid={comp.data_testid}
            />
          </div>
        )
      }}
    </Downshift>
  )
}

export default TagList
