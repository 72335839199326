import { Box, alpha } from '@mui/material'
import React, { ReactElement } from 'react'
import { useDrop } from 'react-dnd'

import { ComponentModel, JuvoDroppableType } from '../../types'
import { useUIBuilder } from '../../providers/UIBuilderProvider'
import { isDefined } from '../../utils/Undefined'
import { mergeDroppedWithCheatsheet } from '../../store/Component'

interface DroppableWrapperProps {
  children: ReactElement
  myComp: ComponentModel
  addToChildComps: (c: ComponentModel, parent: ComponentModel) => void
}

export const DroppableWrapper: React.FC<DroppableWrapperProps> = ({
  children,
  myComp,
  addToChildComps,
}) => {
  const { compSheet } = useUIBuilder()

  const cheatSheetLoaded = isDefined(compSheet) && compSheet.size > 0

  const onDrop = (item: any, monitor: any) => {
    if (!monitor.didDrop()) {
      if (cheatSheetLoaded) {
        const comp = item.comp
        const newComp = mergeDroppedWithCheatsheet(comp, compSheet)
        addToChildComps(newComp, myComp)
      }
    }
  }

  const [{ isOver, canDrop, isOverCurrent }, drop] = useDrop({
    accept: JuvoDroppableType.BUILDER_ITEM,
    drop: onDrop,
    collect: monitor => ({
      isOver: monitor.isOver(),
      isOverCurrent: monitor.isOver({ shallow: true }),
      canDrop: monitor.canDrop(),
    }),
  })

  return (
    <Box
      ref={drop}
      className="droppable-component"
      sx={{
        outline: '2px solid transparent',
        borderRadius: '4px',
        transition: 'padding 0.24s ease, margin 0.24s ease',
        ...(canDrop && {
          outline: theme =>
            `2px dashed ${alpha(theme.palette.primary.main, 0.3)}`,
          minHeight: '64px',
          padding: '4px',
          margin: '4px 0',
        }),
        ...(isOver &&
          isOverCurrent && {
            outline: theme => `2px dashed ${theme.palette.primary.main}`,
            background: theme => alpha(theme.palette.primary.light, 0.33),
          }),
      }}
    >
      {children}
    </Box>
  )
}
