import React from 'react'
import useTheme from '@mui/material/styles/useTheme'
import Toolbar from '@mui/material/Toolbar/Toolbar'
import AppBar from '@mui/material/AppBar/AppBar'
import Box from '@mui/material/Box/Box'
import Button from '@mui/material/Button/Button'
import { alpha, SxProps } from '@mui/material/styles'

import { useUIBuilder } from '../../../providers/UIBuilderProvider'
import {
  ComponentModel,
  isButtonsComponent,
  isCarouselModel,
  isStepperModel,
} from '../../../types'
import { UIBuilderHeaderButton } from '../HeaderButton/UIBuilderHeaderButton'
import { isDefined } from '../../../utils/Undefined'
import { CarouselAttributeInput } from '../ComponentAttribute/CarouselAttributeInput'
import { ButtonsAttributeInput } from '../ComponentAttribute/ButtonsAttributeInput'
import { StepperAttributeInput } from '../ComponentAttribute/StepperAttributeInput'

import { DrawerComponentEditor } from './DrawerComponentEditor'
import { DrawerWrapper } from './DrawerWrapper'

interface UIBuilderCompDrawerProps {
  selectedComponent: ComponentModel
}

export const UIBuilderCompDrawer: React.FC<UIBuilderCompDrawerProps> = () => {
  const { selectedComponent, setSelectedComponent, updateComp } = useUIBuilder()

  const theme = useTheme()

  const updateSelectedComponent = (c: ComponentModel) => {
    updateComp(c)
    setSelectedComponent(c)
  }

  const labelStyles: SxProps = {
    color: theme.palette.grey[300],
    fontSize: '0.9em',
  }

  const inputStyles: SxProps = {
    padding: '6px',
    background: alpha(theme.palette.grey[800], 0.5),
    borderRadius: 0,
    boxSizing: 'border-box',
    width: '100% !important',
    '& *': {
      color: `${theme.palette.grey[200]} !important`,
      border: 'none',
      outline: 'none',
    },
    '.MuiSelect-select': {
      padding: '4px 2px',
    },
  }

  const closeComponent = () => {
    setSelectedComponent(null)
  }

  return (
    <DrawerWrapper
      sx={{ position: 'absolute', zIndex: theme => theme.zIndex.drawer + 1 }}
    >
      <>
        <AppBar
          position="absolute"
          sx={{
            background: theme => theme.palette.grey[800],
            height: {
              xs: 'var(--header-height-mobile)',
              sm: 'var(--header-height)',
            },
            boxSizing: 'border-box',
          }}
        >
          <Toolbar
            sx={{
              flexDirection: 'row',
              minHeight: 'unset !important',
              height: '100%',
              gap: '6px',
              justifyContent: 'flex-end',
              padding: '0 16px !important',
            }}
          >
            <UIBuilderHeaderButton
              icon="fa-xmark"
              action={closeComponent}
              tooltip="Close"
            />
          </Toolbar>
        </AppBar>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
            marginTop: {
              xs: 'var(--header-height-mobile)',
              sm: 'var(--header-height)',
            },
          }}
        >
          <DrawerComponentEditor
            comp={selectedComponent}
            labelStyles={labelStyles}
            inputStyles={inputStyles}
            updateComp={updateSelectedComponent}
          />
          {isCarouselModel(selectedComponent) &&
            isDefined(selectedComponent.emptycomponent) && (
              <CarouselAttributeInput
                comp={selectedComponent}
                applyVal={newComp => updateSelectedComponent(newComp)}
                inputStyles={inputStyles}
                labelStyles={labelStyles}
              />
            )}
          {isButtonsComponent(selectedComponent) && (
            <ButtonsAttributeInput
              comp={selectedComponent}
              applyVal={newComp => updateSelectedComponent(newComp)}
              inputStyles={inputStyles}
              labelStyles={labelStyles}
            />
          )}
          {isStepperModel(selectedComponent) && (
            <StepperAttributeInput
              comp={selectedComponent}
              applyVal={newComp => updateSelectedComponent(newComp)}
              inputStyles={inputStyles}
              labelStyles={labelStyles}
            />
          )}
          <Button variant="contained" onClick={closeComponent}>
            Done
          </Button>
        </Box>
      </>
    </DrawerWrapper>
  )
}
