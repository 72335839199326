import { Box, SxProps, Typography, alpha } from '@mui/material'
import React from 'react'

const getDisplayType = (gridDisplay: boolean): SxProps => {
  return gridDisplay
    ? {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
      }
    : {
        display: 'flex',
        flexWrap: 'wrap',
      }
}

type DrawerComponentGroupProps = {
  title: string
  children: React.ReactNode
  highlightBorder?: boolean
  gridDisplay?: boolean
}

export const DrawerComponentGroup = ({
  title,
  children,
  highlightBorder,
  gridDisplay,
}: DrawerComponentGroupProps) => {
  return (
    <Box
      sx={{
        padding: '8px',
        position: 'relative',
        border: style =>
          `1px solid ${alpha(highlightBorder ? style.palette.tertiary.main : style.palette.grey[400], 0.5)}`,
      }}
    >
      <Typography
        variant="subtitle2"
        color="white"
        sx={{
          marginBottom: '8px',
        }}
      >
        {title}
      </Typography>

      <Box
        sx={{
          gap: '8px',
          ...getDisplayType(gridDisplay ?? false),
        }}
      >
        {children}
      </Box>
    </Box>
  )
}
