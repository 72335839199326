import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material'

import SwitchYard from '../../juvo-component/SwitchYard/SwitchYard'
import {
  adjustStdComponentArgs,
  ComponentModel,
  FolderModel,
  StdComponentArgs,
} from '../../../types'
import {
  containerSetChild,
  getDefinedStyles,
  getJuvoInfo,
} from '../../../store'
import { isDefined } from '../../../utils'

const Folder: React.FC<StdComponentArgs<FolderModel>> = props => {
  const { comp, onComponentChange } = props
  const theme = useTheme()
  const children: ComponentModel[] = comp.tchildren || []

  const styles = getDefinedStyles(comp, theme)

  return (
    <Box
      {...getJuvoInfo('FolderCont', comp)}
      sx={{
        marginLeft: comp.indent ? `${comp.indent * 10}px` : 0,
        borderRadius: '4px',
        marginBottom: isDefined(comp.render_as) ? '16px' : 'unset',
        transition: 'all 0.18s ease-in-out',
        ...styles,
      }}
      className={comp.render_as ?? ''}
    >
      {comp.label && <Typography variant="body1">{comp.label}</Typography>}
      {children.map((cp, index) => {
        const childProps = adjustStdComponentArgs(
          props,
          cp,
          nc => onComponentChange(containerSetChild(nc, index, comp)),
          index,
        )
        return <SwitchYard key={index} {...childProps} />
      })}
    </Box>
  )
}

export default Folder
