import { useEffect, useState } from 'react'

import { SAVED_COMPS } from '../constants/Constants'
import { SaveableComp } from '../containers/UIBuilder/types/properties'
import { ComponentModel } from '../types'
import { isDefined, logErr } from '../utils'
import { useSnackbar } from '../providers/SnackbarProvider'

import { usePub, useSub } from './useSubscription'

const shorterMessageDuration = 3000

type SavedUIBuilderCompsApi = {
  currentComps: SaveableComp[]
  saveUIBuilderComp: (c: ComponentModel) => void
  deleteSavedUIBuilderComp: (c: SaveableComp) => void
  updateSaveCompName: (old: string, newId: string) => void
}

export const useSavedUIBuilderComps = (): SavedUIBuilderCompsApi => {
  const [currentComps, setCurrentComps] = useState<SaveableComp[]>([])
  const publish = usePub<SaveableComp[]>()

  const { queueMessage } = useSnackbar()

  useEffect(() => {
    setCurrentComps(getSavedUIBuilderComps())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useSub<SaveableComp[]>('updateSavedComps', data => {
    setCurrentComps(data)
  })

  const update = () => {
    publish('updateSavedComps', getSavedUIBuilderComps())
  }

  const deleteSavedUIBuilderComp = (c: SaveableComp) => {
    const parsed: SaveableComp[] = getSavedUIBuilderComps()
    if (parsed.length) {
      updateComps(parsed.filter(item => item.saveId !== c.saveId))
      update()
      queueMessage({
        title: 'Component removed',
        subtitle: 'Previously added components are unaffected',
        subType: 'info',
        duration: shorterMessageDuration,
      })
    }
  }

  const updateSaveCompName = (oldName: string, newName: string) => {
    const currentlySaved = getSavedUIBuilderComps()
    const alreadySaved = currentlySaved.findIndex(
      item => item.saveId === oldName,
    )
    if (alreadySaved < 0) {
      queueMessage({
        title: 'Component error',
        subtitle: 'Could not save component name',
        subType: 'failure',
        duration: shorterMessageDuration,
      })
    } else {
      const currentComp = currentlySaved[alreadySaved]
      currentlySaved[alreadySaved] = { ...currentComp, saveId: newName }
      updateComps(currentlySaved)
      update()
      queueMessage({
        title: 'Component updated',
        subtitle: 'Click the "Custom" tab to see saved components.',
        subType: 'success',
        duration: shorterMessageDuration,
      })
    }
  }

  const getSavedUIBuilderComps = (): SaveableComp[] => {
    const currentlySaved = localStorage.getItem(SAVED_COMPS)
    if (isDefined(currentlySaved)) {
      try {
        return JSON.parse(currentlySaved)
      } catch (e) {
        logErr(e)
        queueMessage({
          title: 'Removal failed',
          subtitle: 'Error trying to parse component json',
          subType: 'info',
          duration: shorterMessageDuration,
        })
        return []
      }
    } else {
      return []
    }
  }

  const updateComps = (comps: SaveableComp[]) => {
    localStorage.setItem(
      SAVED_COMPS,
      // The stringify replacer func is to preserve attributes that are set to null
      JSON.stringify(comps, function (k, v) {
        return v === undefined ? null : v
      }),
    )
  }

  const saveUIBuilderComp = (c: ComponentModel) => {
    const saveableComp: SaveableComp = { saveId: c.id, comp: c }
    const currentlySaved = getSavedUIBuilderComps()
    const alreadySaved = currentlySaved.find(
      item => item.saveId === saveableComp.saveId,
    )
    if (isDefined(alreadySaved)) {
      queueMessage({
        title: 'Duplicate component',
        subtitle: 'Saved components must have unique IDs',
        subType: 'failure',
        duration: shorterMessageDuration,
      })
      return
    }
    if (isDefined(currentlySaved)) {
      updateComps(currentlySaved.concat([saveableComp]))
    } else {
      updateComps([c])
    }
    update()
    queueMessage({
      title: 'Component saved',
      subtitle: 'Click the "Custom" tab to see saved components.',
      subType: 'success',
      duration: shorterMessageDuration,
    })
  }
  return {
    currentComps,
    saveUIBuilderComp,
    deleteSavedUIBuilderComp,
    updateSaveCompName,
  }
}
