import React from 'react'
import Box from '@mui/material/Box/Box'

import { ComponentsDrawer } from '../data/commonComponents'

import { DrawerComponent } from './DrawerComponent'
import { DrawerComponentGroup } from './DrawerComponentGroup'
import { UIBuilderCompProps } from './UIBuilderCustomComps'

export const UIBuilderDefaultComps: React.FC<UIBuilderCompProps> = ({
  addMe,
}) => {
  const comps = ComponentsDrawer
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
      }}
    >
      {comps.map((group, groupIndex) => (
        <DrawerComponentGroup title={group.groupName} key={groupIndex}>
          {group.components.map((c, index) => (
            <DrawerComponent
              key={index}
              image={c.image}
              name={c.name}
              compModel={c.compModel}
              onClickToAdd={() => addMe(c.compModel)}
            />
          ))}
        </DrawerComponentGroup>
      ))}
    </Box>
  )
}
