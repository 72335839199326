import folder from '../icons/folder.svg'
import accordion from '../icons/accordion.svg'
import text_input from '../icons/text-input.svg'
import number_input from '../icons/number-input.svg'
import currency_input from '../icons/currency-input.svg'
import button from '../icons/button.svg'
import checkbox_input from '../icons/checkbox-input.svg'
import columns from '../icons/columns.svg'
import carousel from '../icons/carousel.svg'
import date_input from '../icons/date-input.svg'
import ratings from '../icons/ratings.svg'
import file_drop from '../icons/file-drop.svg'
import radio_input from '../icons/radio-input.svg'
import select_input from '../icons/select-input.svg'
import multiselect_input from '../icons/multi-select.svg'
import tab_select from '../icons/tab-select.svg'
import tag_list from '../icons/tag-list.svg'
import body from '../icons/typography-body.svg'
import stepper from '../icons/stepper.svg'
import h1 from '../icons/typography-h1.svg'
import h2 from '../icons/typography-h2.svg'
import h3 from '../icons/typography-h3.svg'
import sub1 from '../icons/typography-sub1.svg'
import sub2 from '../icons/typography-sub2.svg'
import { ComponentModel } from '../../../types'

import {
  GenericAccordion,
  GenericBodyText,
  GenericButtons,
  GenericCarousel,
  GenericCheckbox,
  GenericColumns,
  GenericCurrencyInput,
  GenericDateInput,
  GenericFileDrop,
  GenericFolder,
  GenericH1Text,
  GenericH2Text,
  GenericH3Text,
  GenericMultiselect,
  GenericNumberInput,
  GenericRadioInput,
  GenericRatingsInput,
  GenericSelect,
  GenericStepper,
  GenericSub1Text,
  GenericSub2Text,
  GenericTabSelect,
  GenericTagList,
  GenericTextInput,
} from './generics'

type DrawerComponent = {
  name: string
  compModel: ComponentModel
  image: any
}

type ComponentDrawerGroup = {
  groupName: string
  components: DrawerComponent[]
}

export const COMP_IMAGE_MAP: Map<string, any> = new Map([
  ['accordion', accordion],
  ['buttons', button],
  ['carousel', carousel],
  ['checkbox', checkbox_input],
  ['columns', columns],
  ['currency', currency_input],
  ['date', date_input],
  ['folder', folder],
  ['multi-select', multiselect_input],
  ['number', number_input],
  ['options', radio_input],
  ['ratings', ratings],
  ['select', select_input],
  ['text', h1],
  ['stepper', stepper],
  ['tab-select', tab_select],
  ['tag-list', tag_list],
  ['input', text_input],
])

export const ComponentsDrawer: ComponentDrawerGroup[] = [
  {
    groupName: 'Containers',
    components: [
      {
        name: 'Folder',
        compModel: GenericFolder,
        image: folder,
      },
      {
        name: 'Accordion',
        compModel: GenericAccordion,
        image: accordion,
      },
      {
        name: 'Columns',
        compModel: GenericColumns,
        image: columns,
      },
      {
        name: 'Tab Select',
        compModel: GenericTabSelect,
        image: tab_select,
      },
      {
        name: 'Stepper',
        compModel: GenericStepper,
        image: stepper,
      },
      {
        name: 'Carousel',
        compModel: GenericCarousel,
        image: carousel,
      },
    ],
  },
  {
    groupName: 'Typography',
    components: [
      {
        name: 'H1',
        compModel: GenericH1Text,
        image: h1,
      },
      {
        name: 'H2',
        compModel: GenericH2Text,
        image: h2,
      },
      {
        name: 'H3',
        compModel: GenericH3Text,
        image: h3,
      },
      {
        name: 'Subtitle 1',
        compModel: GenericSub1Text,
        image: sub1,
      },
      {
        name: 'Subtitle 2',
        compModel: GenericSub2Text,
        image: sub2,
      },
      {
        name: 'Body',
        compModel: GenericBodyText,
        image: body,
      },
    ],
  },
  {
    groupName: 'Inputs',
    components: [
      {
        name: 'Text Input',
        compModel: GenericTextInput,
        image: text_input,
      },
      {
        name: 'Number Input',
        compModel: GenericNumberInput,
        image: number_input,
      },
      {
        name: 'Currency Input',
        compModel: GenericCurrencyInput,
        image: currency_input,
      },
      {
        name: 'Checkbox Input',
        compModel: GenericCheckbox,
        image: checkbox_input,
      },
      {
        name: 'Radio Input',
        compModel: GenericRadioInput,
        image: radio_input,
      },
      {
        name: 'Tag List',
        compModel: GenericTagList,
        image: tag_list,
      },
      {
        name: 'Select Input',
        compModel: GenericSelect,
        image: select_input,
      },
      {
        name: 'Multiselect Input',
        compModel: GenericMultiselect,
        image: multiselect_input,
      },
      {
        name: 'Date Input',
        compModel: GenericDateInput,
        image: date_input,
      },
      {
        name: 'Ratings Input',
        compModel: GenericRatingsInput,
        image: ratings,
      },
    ],
  },
  {
    groupName: 'Actions',
    components: [
      {
        name: 'File Drop',
        compModel: GenericFileDrop,
        image: file_drop,
      },
      {
        name: 'Buttons',
        compModel: GenericButtons,
        image: button,
      },
    ],
  },
]
