import React, { useCallback, useEffect, useState } from 'react'
import AppBar from '@mui/material/AppBar/AppBar'
import Toolbar from '@mui/material/Toolbar/Toolbar'
import Box from '@mui/material/Box/Box'
import Tabs from '@mui/material/Tabs/Tabs'
import Tab from '@mui/material/Tab/Tab'
import Button from '@mui/material/Button'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Icon from '@mui/material/Icon'
import { alpha } from '@mui/material/styles'

import { useUIBuilder } from '../../../providers/UIBuilderProvider'
import { isDefined, logErr } from '../../../utils'
import { JuvoSwitch } from '../../../components/JuvoSwitch/JuvoSwitch'
import { appRegToXml } from '../../../network/XmlInterop'
import { useSnackbar } from '../../../providers/SnackbarProvider'
import { AppRegistration, ComponentModel } from '../../../types'
import { genericApp } from '../data/generics'
import { XmlConverter } from '../Conversion/XmlConverter'
import { InteropResType, ResponseModal } from '../ResponseModal'
import { UIBuilderHeaderMenu } from '../HeaderButton/UIBuilderHeaderMenu'
import move_items from '../icons/move-items.svg'
import { JuvoDialog } from '../../JuvoModal/JuvoDialog'
import { UIBuilderHeaderButton } from '../HeaderButton/UIBuilderHeaderButton'

import { DrawerWrapper } from './DrawerWrapper'
import { UIBuilderCompDrawer } from './UIBuilderCompDrawer'
import { UIBuilderAppDrawer } from './UIBuilderAppDrawer'
import { UIBuilderDefaultComps } from './UIBuilderDefaultComps'
import { UIBuilderCustomComps } from './UIBuilderCustomComps'

export const UIBuilderDrawer: React.FC<{
  apps: AppRegistration[]
  addMe: (comp: ComponentModel, dontMerge?: boolean) => void
}> = ({ addMe, apps }) => {
  const { queueMessage } = useSnackbar()
  const [jsonOrXmlRes, setJsonOrXml] = useState('')
  const [showAppsModal, setShowAppsModal] = useState(false)

  const [showConverter, setShowConverter] = useState(false)
  const [resType, setResType] = useState<InteropResType>('xml')

  const [tabsValue, setTabsValue] = useState('default')

  const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
    setTabsValue(newValue)
  }

  const {
    functionMode,
    setAppReg,
    appReg,
    updateFunctionMode,
    selectedComponent,
    setSelectedComponent,
    compSheet,
    hasCutComp,
    pasteCompTop,
  } = useUIBuilder()

  const cheatSheetLoaded = isDefined(compSheet) && compSheet.size > 0

  const addAndScroll = (comp: ComponentModel, dontMerge?: boolean) => {
    addMe(comp, dontMerge)
    setTimeout(() => {
      const scrolled = document.getElementById('app-scroll-content')
      if (scrolled) {
        scrolled.scrollTo(0, scrolled.scrollHeight)
      }
    }, 200)
  }

  const handleKeydown = useCallback(
    (event: KeyboardEvent) => {
      if (
        event.key.toLocaleLowerCase() !== 'escape' ||
        functionMode === 'Default'
      ) {
        return
      }
      if (isDefined(selectedComponent)) {
        setSelectedComponent(null)
      } else {
        updateFunctionMode('Default')
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [functionMode, selectedComponent],
  )

  const appToJson = () => {
    setResType('json')
    const json = JSON.stringify(appReg, null, 2)
    setJsonOrXml(json)
  }

  const appToXml = () => {
    setResType('xml')
    appRegToXml(appReg)
      .then(res => {
        if (typeof res === 'string') {
          setJsonOrXml(res)
        } else {
          queueMessage({
            title: 'Request Error',
            subtitle: 'The backend could not process app structure',
            subType: 'failure',
          })
        }
      })
      .catch(e => {
        logErr(e)
        queueMessage({
          title: 'Request Error',
          subtitle: 'Something went wrong',
          subType: 'failure',
        })
      })
  }

  const openAppMeta = () => {
    updateFunctionMode('Meta')
  }

  const reset = () => {
    setAppReg(genericApp)
  }

  const loadApp = (app: AppRegistration) => {
    setAppReg(app)
    setShowAppsModal(false)
  }

  useEffect(() => {
    document.addEventListener('keydown', handleKeydown)
    return () => {
      document.removeEventListener('keydown', handleKeydown)
    }
  }, [handleKeydown])

  return (
    <>
      <JuvoDialog
        title="Load App"
        severity="info"
        open={showAppsModal}
        handleClose={() => setShowAppsModal(false)}
        buttons={<Button onClick={() => setShowAppsModal(false)}>Close</Button>}
      >
        <List>
          {apps.map((value, index) => {
            return (
              <ListItem
                key={index}
                sx={{
                  padding: 0,
                }}
              >
                <Button
                  sx={{
                    width: '100%',
                    justifyContent: 'start',
                    padding: '10px',
                  }}
                  onClick={() => loadApp(value)}
                  startIcon={
                    <Icon
                      className={`fas fa-sharp ${value.app_agent?.icon}`}
                      sx={{
                        fontSize: '1.2em !important',
                      }}
                    />
                  }
                  variant="text"
                >
                  {value.app_name}
                </Button>
              </ListItem>
            )
          })}
        </List>
      </JuvoDialog>

      <Box
        sx={{
          overflowX: 'hidden',
          position: 'fixed',
          top: 0,
          right: {
            xs: '0px',
            sm: '16px',
            md: '64px',
            lg: '128px',
          },
          height: '100%',
        }}
      >
        {functionMode === 'Edit' && isDefined(selectedComponent) && (
          <UIBuilderCompDrawer selectedComponent={selectedComponent} />
        )}

        {functionMode === 'Meta' && (
          <UIBuilderAppDrawer appReg={appReg} updateAppReg={setAppReg} />
        )}

        <DrawerWrapper>
          <ResponseModal
            response={jsonOrXmlRes}
            type={resType}
            closeFn={() => setJsonOrXml('')}
          />
          <AppBar
            position="absolute"
            sx={{
              background: style => style.palette.grey[800],
              height: {
                xs: 'var(--header-height-mobile)',
                sm: 'var(--header-height)',
              },
              boxSizing: 'border-box',
            }}
          >
            <Toolbar
              sx={{
                flexDirection: 'row',
                minHeight: 'unset !important',
                height: '100%',
                gap: '6px',
                justifyContent: 'flex-end',
                padding: '0 16px !important',
              }}
            >
              <UIBuilderHeaderMenu
                icon="fa-ellipsis-vertical"
                items={[
                  { label: 'Load app', action: () => setShowAppsModal(true) },
                  { label: 'Edit app', action: openAppMeta },
                  { label: 'Reset app', action: reset },
                ]}
              />
              <XmlConverter
                handleClose={() => setShowConverter(false)}
                showModal={showConverter}
              />
              <UIBuilderHeaderMenu
                icon="fa-file-code"
                items={[
                  { label: 'Get JSON', action: appToJson },
                  { label: 'Get XML', action: appToXml },
                  {
                    label: 'Converter',
                    action: () => setShowConverter(true),
                  },
                ]}
              />
              {hasCutComp && (
                <UIBuilderHeaderButton
                  action={pasteCompTop}
                  icon="fa-paste"
                  disabled={false}
                />
              )}
              <JuvoSwitch
                img={move_items}
                isChecked={functionMode === 'Edit'}
                tooltip="Toggle editing"
                action={() =>
                  functionMode === 'Edit'
                    ? updateFunctionMode('Default')
                    : updateFunctionMode('Edit')
                }
              />
            </Toolbar>
          </AppBar>
          {cheatSheetLoaded && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '4px',
                marginTop: {
                  xs: 'var(--header-height-mobile)',
                  sm: 'var(--header-height)',
                },
              }}
            >
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                  value={tabsValue}
                  onChange={handleChange}
                  sx={{
                    '.MuiTabs-flexContainer': {
                      padding: '6px',
                      background: theme =>
                        alpha(theme.palette.common.white, 0.1),
                      border: style =>
                        `1px solid ${alpha(style.palette.grey[400], 0.5)}`,
                    },
                    '.MuiTab-root': {
                      color: theme => theme.palette.grey[400],
                      padding: '8px 12px',
                      minHeight: 'unset',
                      lineHeight: '1.6',
                      fontSize: '0.8em',
                      fontWeight: 600,
                      '&.Mui-selected': {
                        color: theme => theme.palette.common.white,
                      },
                    },
                    '.MuiTabs-indicator': {
                      backgroundColor: theme =>
                        `${theme.palette.primary.main} !important`,
                      top: '6px',
                      bottom: '6px',
                      height: 'auto',
                      zIndex: '-1',
                      boxShadow: '0px 2px 6px 0px rgba(0,0,0,0.41)',
                    },
                  }}
                >
                  <Tab label="Default" value="default" />
                  <Tab label="Custom" value="user" />
                </Tabs>
              </Box>
              {tabsValue === 'default' && (
                <UIBuilderDefaultComps addMe={addAndScroll} />
              )}
              {tabsValue === 'user' && (
                <UIBuilderCustomComps addMe={c => addAndScroll(c.comp, true)} />
              )}
            </Box>
          )}
        </DrawerWrapper>
      </Box>
    </>
  )
}
