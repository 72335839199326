import React from 'react'
import Box from '@mui/material/Box'
import MobileStepper from '@mui/material/MobileStepper'
import Button from '@mui/material/Button'
import Icon from '@mui/material/Icon'

import { getJuvoInfo } from '../../../store'
import {
  adjustStdComponentArgs,
  StdComponentArgs,
  CarouselModel,
} from '../../../types'
import { containerSetChild } from '../../../store/State'
import { SwitchYard } from '../..'
import { logWarn } from '../../../utils/Logger'
import { scrollAppContentToTop } from '../../../utils/Common'

const Carousel: React.FC<{
  stdCompArgs: StdComponentArgs<CarouselModel>
}> = ({ stdCompArgs }) => {
  const props = stdCompArgs
  const { comp, onComponentChange } = stdCompArgs

  const carouselSeats = comp.tchildren || []

  const validModel = carouselSeats.length > 0
  const activeStepIdx0 = comp.position || 0
  if (activeStepIdx0 + 1 > carouselSeats.length && activeStepIdx0 !== 0)
    logWarn(
      'Developer Error, console position out of bounds, check addlet code',
      { gotPosition: comp.position, gotCarouselLength: carouselSeats.length },
    )

  const activeStepIdx =
    activeStepIdx0 + 1 > carouselSeats.length ? 0 : activeStepIdx0

  const activeStep =
    activeStepIdx < carouselSeats.length ? carouselSeats[activeStepIdx] : null

  const activeStepProps = adjustStdComponentArgs(props, activeStep, nc =>
    onComponentChange(containerSetChild(nc, activeStepIdx, comp)),
  )

  const setActiveStep = (n: number): void => {
    onComponentChange({ ...comp, position: n })
  }

  const handleNext = () => {
    setActiveStep(activeStepIdx + 1)
  }

  const handleBack = () => {
    setActiveStep(activeStepIdx - 1)
  }

  React.useEffect(() => {
    scrollAppContentToTop()
  }, [activeStepIdx])

  return validModel ? (
    <Box
      className="carousel-box"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        justifyContent: 'center',
        border: '1px solid var(--color-border)',
        alignItems: 'center',
        flexGrow: 1,
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          padding: { xs: 0, sm: '16px', md: '32px' },
        }}
      >
        <SwitchYard key={activeStepIdx} {...activeStepProps} />
      </Box>
      <MobileStepper
        variant="text"
        steps={carouselSeats.length}
        position="static"
        activeStep={activeStepIdx}
        sx={{
          width: '100%',
          background: 'var(--color-background)',
          borderTop: '1px solid var(--color-border)',
          padding: '0',
        }}
        nextButton={
          <Button
            sx={{ padding: '16px' }}
            onClick={handleNext}
            disabled={activeStepIdx === carouselSeats.length - 1}
          >
            Next
            <Icon className="fas fa-sharp fa-chevron-right" />
          </Button>
        }
        backButton={
          <Button
            sx={{ padding: '16px' }}
            onClick={handleBack}
            disabled={activeStepIdx === 0}
          >
            <Icon className="fas fa-sharp fa-chevron-left" />
            Back
          </Button>
        }
      />
    </Box>
  ) : (
    // for dynamically populated carousels this will always show up until some data comes from the server
    //other components on the page should disambiguate this message
    <Box {...getJuvoInfo('CarouselComponent', comp)}>No data</Box>
  )
}

export default Carousel
