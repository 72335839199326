import React from 'react'
import { Box, BoxProps } from '@mui/material'

interface FormattedTextProps extends BoxProps {
  children: string
  preserveXml?: boolean
}

export const cleanMessage = (msg: string, preserveXml?: boolean) => {
  const nlMsg = msg.replace(/\\n/g, '\n')
  if (preserveXml) {
    return nlMsg
  } else {
    return nlMsg.replace(/<\/?[^>]+(>|$)/g, '')
  }
}

const FormattedText: React.FC<FormattedTextProps> = ({
  preserveXml,
  children,
  ...props
}) => {
  const { sx, ...otherProps } = props

  return (
    <Box
      component="span"
      sx={{
        lineHeight: '1.6',
        whiteSpace: 'pre-wrap',
        display: 'flex',
        ...sx,
      }}
      {...otherProps}
    >
      {cleanMessage(children, preserveXml)}
    </Box>
  )
}

export default FormattedText
