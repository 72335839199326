import Icon from '@mui/material/Icon/Icon'
import Tooltip from '@mui/material/Tooltip/Tooltip'
import React, { MouseEvent } from 'react'

interface OptionsButtonProps {
  title: string
  icon: string
  onClick: (e: MouseEvent<HTMLSpanElement>) => void
  disabled?: boolean
}

export const OptionsButton: React.FC<OptionsButtonProps> = ({
  title,
  icon,
  onClick,
  disabled,
}) => {
  return (
    <Tooltip arrow title={title}>
      <Icon
        baseClassName="fas"
        className={`fa-sharp ${icon}`}
        onClick={onClick}
        sx={{
          color: theme => theme.palette.common.white,
          alignItems: 'center',
          bottom: 'unset',
          fontSize: '0.9em',
          display: 'flex',
          width: '32px',
          cursor: 'pointer !important',
          borderRadius: '4px',
          height: '32px',
          padding: '0',
          justifyContent: 'center',
          transform: 'unset',
          '&:hover': {
            background: theme => theme.palette.grey[800],
          },
          ...(disabled && {
            background: 'transparent !important',
          }),
        }}
      />
    </Tooltip>
  )
}
