import React, { useState } from 'react'
import {
  InputLabel,
  Box,
  IconButton,
  InputBase,
  InputBaseProps,
  ClickAwayListener,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import clsx from 'clsx'
import { format } from 'date-fns'

import { ComparisonDateInputModel, DateModel } from '../../../../types/Model'
import { setComponentValue } from '../../../../store'
import { Command } from '../../../../types'
import { AutoResizeInput } from '../../AutoResizeInput/AutoResizeInput'
import ValueChips from '../ValueChips/ValueChips'
import { useOnSmallScreen } from '../../../../hooks/useOnSmallScreen'
import {
  dateComparer,
  getComparisonClasses,
} from '../../../../utils/Comparison'
import { sanitizeInputName } from '../../../../utils/Common'
import { logWarn } from '../../../../utils/Logger'
import { getJuvoProps } from '../../../../utils/JuvoProps'

const CompareDateInput: React.FC<{
  comp: ComparisonDateInputModel
  onCommand: (cmd: Command) => void
  onChange: (_: DateModel) => void
}> = ({ comp, onCommand, onChange }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isFocused, setFocus] = useState(false)
  const onSmallScreen = useOnSmallScreen()

  const label = comp.text ?? ''
  const chips = comp.compValues ?? []
  const inputName = sanitizeInputName(label)

  const handleChange = (date: Date) => {
    try {
      onChange(setComponentValue(comp)(format(date, 'yyyy-MM-dd')))
    } catch (error) {
      logWarn('Invalid date value', error)
    }
  }

  const { eventHandlers, className, value, juvoInfo } = getJuvoProps<any, any>(
    'CompareDateInput',
    comp,
    onCommand,
    handleChange,
  )

  const {
    onChange: onChangeFromServer,
    onBlur: onBlurFromServer,
    onFocus: onFocusFromServer,
    ...handlersFromServer
  } = eventHandlers

  const setChipValue = (value: string) => {
    onChangeFromServer(new Date(`${value}T00:00:00`))
  }

  const formatDate = (item: string) => {
    return format(new Date(`${item}T00:00:00`), 'MM/dd/yyyy')
  }

  const labelRef = React.useRef(null)
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)
  const [isPopperOpen, setIsPopperOpen] = React.useState(false)

  const handlerOpenCalendar = () => {
    setAnchorEl(labelRef.current)
    setIsOpen(true)
    setIsPopperOpen(true)
  }

  const CustomTextField = React.forwardRef(
    (props: InputBaseProps, ref: React.Ref<HTMLDivElement>) => {
      return (
        <InputBase
          {...props}
          ref={ref}
          className={clsx(
            props.className,
            className,
            'juvo-input-base',
            'juvo-input-control',
            'juvo-compare-input-base',
            ...getComparisonClasses<string>(
              chips ?? [],
              value ?? '',
              dateComparer,
            ),
          )}
          data-testid={comp.data_testid}
          id={inputName}
          name={inputName}
          sx={{
            minWidth: onSmallScreen ? '100%' : 'auto',
            width: '100%',
          }}
          onBlur={() => {
            onBlurFromServer && onBlurFromServer()
            setFocus(false)
          }}
          inputComponent={AutoResizeInput}
          endAdornment={
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
                // No pointer events for this box only, so that the user can click anywhere to focus the input
                pointerEvents: 'none',
                '& *': {
                  pointerEvents: 'all',
                },
              }}
            >
              <ValueChips
                chips={chips ?? []}
                currentValue={value}
                comparisonFunc={(a, b) => dateComparer(a, b)}
                onClickEvent={item => setChipValue(item)}
                labelMutator={item => formatDate(item)}
              />
              <IconButton
                aria-label="open calendar"
                className="fa-regular fa-calendar"
                onClick={handlerOpenCalendar}
                size="small"
              />
            </Box>
          }
          onFocus={() => {
            onFocusFromServer && onFocusFromServer()
            setFocus(true)
          }}
        />
      )
    },
  )

  CustomTextField.displayName = 'CustomTextField'

  const handleClickAwayListener = () => {
    setAnchorEl(null)
    if (isPopperOpen) {
      setIsPopperOpen(false)
    }
  }

  return (
    <ClickAwayListener onClickAway={handleClickAwayListener}>
      <Box
        className={clsx('juvo-date-field', isFocused && 'date-field-focus')}
        sx={{
          '&.date-field-focus .MuiInputBase-formControl': {
            outline: theme =>
              `2px solid ${theme.palette.primary.main} !important`,
          },
          '.MuiInputBase-formControl': {
            outline: theme => `1px solid ${theme.palette.grey[400]}`,
            '&:hover': {
              outline: theme => `2px solid ${theme.palette.grey[500]}`,
            },

            '.MuiInputBase-input': {
              padding: '10px 12px !important',
            },

            '.MuiOutlinedInput-notchedOutline': {
              border: '0 !important',
            },
          },
          marginBottom: '8px',
        }}
      >
        <InputLabel
          shrink
          htmlFor={inputName}
          className="juvo-input-label"
          ref={labelRef}
        >
          {label}
        </InputLabel>
        <DatePicker
          {...juvoInfo}
          {...handlersFromServer}
          value={value ? new Date(`${value}T00:00:00`) : null}
          open={isOpen}
          onChange={date => {
            onChangeFromServer(date)
            setAnchorEl(null)
            setIsPopperOpen(false)
          }}
          onAccept={() => setIsOpen(false)}
          onClose={() => setIsOpen(false)}
          slots={{
            textField: CustomTextField,
          }}
          slotProps={{
            popper: {
              anchorEl: anchorEl,
              open: isPopperOpen,
            },
          }}
        />
      </Box>
    </ClickAwayListener>
  )
}

export default CompareDateInput
