import React from 'react'
import {
  DialogContent,
  Dialog,
  Typography,
  darken,
  IconButton,
} from '@mui/material'

import { isDefined, Nullable } from '../../../utils'
import { HintInfo } from '../data/properties'

interface UIBuilderHintProps {
  hintInfo: Nullable<HintInfo>
  setHintInfo: (i: Nullable<HintInfo>) => void
}

export const UIBuilderHint: React.FC<UIBuilderHintProps> = ({
  hintInfo,
  setHintInfo,
}) => {
  return (
    <Dialog
      open={isDefined(hintInfo)}
      onClose={() => setHintInfo(null)}
      aria-labelledby="juvo-hint-title"
      aria-describedby="juvo-hint-description"
      sx={{
        zIndex: theme => theme.zIndex.modal,
        '.MuiDialog-paper': {
          width: '330px',
          borderRadius: 0,
          maxWidth: 'unset',
        },
      }}
    >
      <DialogContent
        sx={{
          padding: '20px !important',
          minHeight: '100px',
          maxHeight: '600px',
          display: 'flex',
          position: 'relative',
          flexDirection: 'column',
          border: theme => `1px solid ${theme.palette.primary.main}`,
          background: theme =>
            `linear-gradient(-30deg, ${darken(theme.palette.primary.dark, 0.25)}, ${theme.palette.primary.dark})`,
          gap: '12px',
        }}
      >
        <Typography
          variant="h4"
          className="code"
          sx={{
            textTransform: 'uppercase',
            fontSize: '1em',
            color: theme => theme.palette.common.white,
          }}
        >
          {hintInfo?.title}
          <IconButton
            className="fa-solid fa-sharp fa-close"
            onClick={() => setHintInfo(null)}
            sx={{
              top: '4px',
              right: '4px',
              position: 'absolute',
              color: theme => theme.palette.grey[400],
              fontSize: '1em',
            }}
          />
        </Typography>
        <Typography
          sx={{
            fontSize: '0.9em',
            paddingLeft: '12px',
            whiteSpace: 'pre-wrap',
            borderLeft: theme => `3px solid ${theme.palette.primary.light}`,
            color: theme => theme.palette.common.white,
          }}
        >
          {hintInfo?.body}
        </Typography>
      </DialogContent>
    </Dialog>
  )
}
