import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box/Box'
import InputLabel from '@mui/material/InputLabel/InputLabel'
import Typography from '@mui/material/Typography/Typography'
import { SxProps } from '@mui/material/styles'
import { InputBaseProps } from '@mui/material/InputBase/InputBase'

import { EditableProperty } from '../data/properties'

import { StringAttributeInput } from './StringAttributeInput'
import { StringSelectAttributeInput } from './StringSelectAttributeInput'
import { NumberAttributeInput } from './NumberAttributeInput'
import { BooleanAttributeInput } from './BooleanAttributeInput'
import { IconAttributeInput } from './IconAttributeInput'

export interface ComponentAttributeInputProps extends InputBaseProps {
  attrKey: string
  applyVal: (val: any) => void
  attrVal: any
  valType: EditableProperty
  inputStyles: SxProps
  labelStyles: SxProps
}

export const ComponentAttributeSwitchyard: React.FC<
  ComponentAttributeInputProps
> = ({
  attrKey,
  applyVal,
  attrVal,
  valType,
  inputStyles,
  labelStyles,
  ...otherProps
}) => {
  const [val, setValState] = useState<string>('')

  useEffect(() => {
    setValState(attrVal)
  }, [attrVal])

  const fireChange = React.useCallback(
    (value: any) => applyVal(value),
    [applyVal],
  )

  const commonProps = {
    labelStyles: labelStyles,
    inputStyles: inputStyles,
    val: val,
    attrKey: attrKey,
    optional: valType.optional,
    hint: valType.hint,
  }

  if (valType.type === 'string') {
    if (!valType.values || valType.values.length === 0) {
      return (
        <StringAttributeInput
          setValState={setValState}
          fireChange={() => fireChange(val)}
          {...commonProps}
          {...otherProps}
        />
      )
    } else {
      return (
        <StringSelectAttributeInput
          fireChange={applyVal}
          values={valType.values}
          {...commonProps}
        />
      )
    }
  }

  if (valType.type === 'icon') {
    return (
      <IconAttributeInput
        setValState={setValState}
        fireChange={() => fireChange(val)}
        {...commonProps}
        {...otherProps}
      />
    )
  }

  if (valType.type === 'number') {
    return (
      <NumberAttributeInput
        fireChange={applyVal}
        {...commonProps}
        {...otherProps}
      />
    )
  }

  if (valType.type === 'boolean') {
    return <BooleanAttributeInput fireChange={applyVal} {...commonProps} />
  }

  if (valType.type === 'folder') {
    return (
      <Box>
        <InputLabel variant="standard" sx={labelStyles}>
          {attrKey}
        </InputLabel>
        <Box
          sx={{
            border: '1px solid var(--color-border)',
            padding: '8px',
          }}
        >
          {valType.subProperties &&
            Array.from(valType.subProperties).map((obj, index) => {
              return (
                <ComponentAttributeSwitchyard
                  attrKey={obj[0]}
                  valType={obj[1]}
                  attrVal={attrVal[obj[0]]}
                  applyVal={val => {
                    const newObj = { ...attrVal }
                    newObj[obj[0]] = val
                    applyVal(newObj)
                  }}
                  labelStyles={labelStyles}
                  inputStyles={inputStyles}
                  key={index}
                />
              )
            })}
        </Box>
      </Box>
    )
  }

  if (valType.type === 'UNDEFINED') {
    return (
      <Box>
        <Typography variant="subtitle2">DEVELOPER ERROR</Typography>
      </Box>
    )
  }
}
