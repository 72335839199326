import React from 'react'
import Typography from '@mui/material/Typography'
import { Box, Theme } from '@mui/material'

import { StaticTextModel, ThemeStyle } from '../../../types'
import { getJuvoInfo } from '../../../store'
import { Nullable, isDefined } from '../../../utils'
import FormattedText from '../FormattedText/FormattedText'

const getColor =
  (theme: Theme) =>
  (themeStyle: Nullable<ThemeStyle>): string =>
    themeStyle
      ? theme.palette[themeStyle].contrastText
      : theme.palette.text.primary

const getBgColor =
  (theme: Theme) =>
  (themeStyle: Nullable<ThemeStyle>): string => {
    switch (themeStyle) {
      case 'primary':
        return theme.palette.primary.main
      case 'secondary':
        return theme.palette.secondary.main
      case 'info':
        return theme.palette.info.main
      case 'error':
        return theme.palette.error.light
      default:
        return 'transparent'
    }
  }

const getBorder =
  (theme: Theme) =>
  (themeStyle: Nullable<ThemeStyle>): string => {
    if (!themeStyle) return 'none'

    switch (themeStyle) {
      case 'info':
      case 'primary':
        return `1px solid ${theme.palette.primary.main}`
      case 'secondary':
        return '1px solid var(--color-border)'
      case 'error':
        return `1px solid ${theme.palette.error.main}`
    }
  }

const StaticText: React.FC<{ comp: StaticTextModel }> = ({ comp }) => {
  const themeStyle = comp['theme-style']
  const boxStyling = themeStyle === 'info' || themeStyle === 'error'

  const getPadding = (): string => {
    if (!themeStyle) return '0'
    return boxStyling ? '1.1em 1em 1.1em 54px' : '1.1em'
  }

  const text = comp.recommendation ?? comp.title ?? comp.text

  return (
    <Typography
      {...getJuvoInfo('StaticText', comp)}
      variant={comp.variant ?? 'body1'}
      component={isDefined(comp['theme-style']) ? 'div' : 'p'}
      sx={{
        display: 'block',
        bgcolor: theme => getBgColor(theme)(themeStyle),
        color: theme => getColor(theme)(themeStyle),
        padding: getPadding(),
        position: 'relative',
        border: theme => getBorder(theme)(themeStyle),
        margin: '1rem 0 !important',
        whiteSpace: 'pre-line',
      }}
      data-testid={comp.data_testid}
    >
      {boxStyling && (
        <Box
          component="span"
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            width: '36px',
            background: theme =>
              themeStyle === 'info'
                ? theme.palette.primary.main
                : theme.palette.error.main,
            outline: theme =>
              `1px solid ${
                themeStyle === 'info'
                  ? theme.palette.primary.main
                  : theme.palette.error.main
              }`,
            color: theme => theme.palette.common.white,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <i
            className={`fas ${themeStyle === 'info' ? 'fa-info-circle' : 'fa-triangle-exclamation'} fa-sharp fa-lg`}
          />
        </Box>
      )}
      <FormattedText>{text ?? ''}</FormattedText>
    </Typography>
  )
}

const MemoizedStaticText = React.memo(StaticText)

export default MemoizedStaticText
