import { Box, Typography, alpha } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { useDrop } from 'react-dnd'
import { match } from 'ts-pattern'

import {
  allocateTabs,
  AppRegistration,
  ComponentModel,
  JuvoDroppableType,
  TabComponent,
  User,
  Warning,
} from '../../types/Model'
import SwitchYard from '../../components/juvo-component/SwitchYard/SwitchYard'
import { CustomComponentHandler, mergeDroppedWithCheatsheet } from '../../store'
import { ReorderContainer } from '../../components/juvo-container/ReorderContainer/ReorderContainer'
import { useUIBuilder } from '../../providers/UIBuilderProvider'
import { isDefined } from '../../utils'
import AppHelp from '../../components/juvo-component/AppHelp/AppHelp'
import Tabs from '../../components/Tabs/Tabs'
import { AppId } from '../../types'
import { ImmutableMap } from '../../utils/ImmutableMap'

import { UIBuilderDrawer } from './Drawer/UIBuilderDrawer'
import { DroppableItem } from './data/generics'

export interface UIBuilderProps {
  apps: ImmutableMap<AppId, [Warning, AppRegistration]>
  user: User
  customReactComps: CustomComponentHandler
}

export const UIBuilder: React.FC<UIBuilderProps> = ({
  apps,
  user,
  customReactComps,
}) => {
  const {
    appReg,
    setAppComps,
    addToComps,
    addToChildComps,
    updateComp,
    compSheet,
  } = useUIBuilder()
  const [currentTab, setTab] = useState<string | null>(null)

  const simplifiedApps = useMemo(() => {
    const newApps: AppRegistration[] = []
    apps.internal.forEach(value => {
      newApps.push(value[1])
    })
    return newApps
  }, [apps])

  useEffect(() => {
    if (appReg.app_skeleton.tabs?.length && !isDefined(currentTab)) {
      setTab(appReg.app_skeleton.tabs[0].id.toString())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appReg.app_skeleton])

  const cheatSheetLoaded = isDefined(compSheet) && compSheet.size > 0

  const getLoadedComp = (comp: ComponentModel) => {
    if (cheatSheetLoaded) {
      return mergeDroppedWithCheatsheet(comp, compSheet)
    }
  }

  const onDrop = (item: DroppableItem) => {
    const comp = item.comp
    addMe(comp, false)
  }

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: JuvoDroppableType.BUILDER_ITEM,
    drop: onDrop,
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  })

  const updateComponents = (c: ComponentModel) => {
    setAppComps(c.tchildren)
  }

  const updateTabs = (t: TabComponent) => {
    const newComps = appReg.app_skeleton.components.filter(c => {
      const noTab = !isDefined(c.tab_id)
      const differentTab = isDefined(c.tab_id) && c.tab_id !== t.tab.id
      return noTab || differentTab
    })
    setAppComps(newComps.concat(t.components))
  }

  const dummyParent = {
    type: 'ui-builder',
    tchildren: appReg.app_skeleton.components,
  }

  const tabComps = allocateTabs(appReg.app_skeleton)

  const addMe = (comp: ComponentModel, dontMerge?: boolean) => {
    const loadedComp = dontMerge ? comp : getLoadedComp(comp)
    if (!dontMerge && comp.tchildren && comp.tchildren.length > 0) {
      loadedComp.tchildren = comp.tchildren.map((c: ComponentModel) => {
        return getLoadedComp(c)
      })
    }
    if (appReg.app_skeleton.tabs && isDefined(currentTab)) {
      loadedComp.tab_id = parseInt(currentTab)
    }
    addToComps([loadedComp])
  }

  const innerChildren = (
    children: ComponentModel[],
    changeOrder: (dragIndex: number, hoverIndex: number) => void,
    acceptType: string,
  ) => (
    <>
      {children.map((comp: ComponentModel, idx: number) => (
        <SwitchYard
          key={idx}
          customReactComps={customReactComps}
          comp={comp}
          onComponentChange={updateComp}
          onCommand={() => null}
          appInfo={[{ type: 'noWarn' }, appReg]}
          user={user}
          uiBuilderProps={{
            changeOrder: changeOrder,
            acceptType: acceptType,
            index: idx,
            addToChildComps: addToChildComps,
            comps: children,
          }}
        />
      ))}
    </>
  )

  const content = match(tabComps)
    .with({ type: 'notabs' }, () => (
      <>
        <AppHelp app={appReg} />
        <ReorderContainer
          comp={dummyParent}
          acceptType="ui-top"
          changeFn={updateComponents}
        >
          {(changeOrder, acceptType) =>
            innerChildren(dummyParent.tchildren, changeOrder, acceptType)
          }
        </ReorderContainer>
      </>
    ))
    .with({ type: 'tabs' }, ({ data }) => (
      <>
        <AppHelp app={appReg} />
        <Tabs
          data={data}
          currentTabUpdate={setTab}
          renderTab={tab => {
            return (
              <ReorderContainer
                comp={tab}
                acceptType="ui-top"
                changeFn={updateTabs}
                overrideChildren={tab.components}
              >
                {(changeOrder, acceptType) =>
                  innerChildren(tab.components, changeOrder, acceptType)
                }
              </ReorderContainer>
            )
          }}
        />
      </>
    ))
    .exhaustive()

  return (
    <>
      <AppHelp app={appReg} />
      <UIBuilderDrawer addMe={addMe} apps={simplifiedApps} />
      <Box>
        {dummyParent.tchildren.length > 0 && content}

        {(dummyParent.tchildren.length < 1 || canDrop) && (
          <Box
            ref={drop}
            sx={{
              borderRadius: '4px',
              border: theme => `2px dashed ${theme.palette.primary.main}`,
              padding: '32px',
              ...((isOver || dummyParent.tchildren.length < 1) && {
                background: theme => alpha(theme.palette.primary.light, 0.33),
              }),
            }}
          >
            {dummyParent.tchildren.length < 1 && (
              <Typography variant="subtitle2" align="center">
                Drag and drop a component from the drawer to get started!
              </Typography>
            )}
          </Box>
        )}
      </Box>
    </>
  )
}
