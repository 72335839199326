import { Box } from '@mui/material'
import React, { useRef } from 'react'
import {
  ConnectDragPreview,
  ConnectDragSource,
  useDrag,
  useDrop,
  XYCoord,
} from 'react-dnd'

import type { Identifier } from 'dnd-core'

export type ReorderChildrenFn = (
  isDragging: boolean,
  dragHandle: ConnectDragSource,
  preview: ConnectDragPreview,
) => React.ReactElement<HTMLElement>

interface DragItem {
  index: number
  id: string
  type: string
}

export const ReorderElement: React.FC<{
  children: ReorderChildrenFn
  id: string
  index: number
  updateOrder: (dragIndex: number, hoverIndex: number) => void
  acceptType: string
}> = ({ children, id, index, updateOrder, acceptType }) => {
  const ref = useRef<HTMLDivElement>(null)
  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: acceptType,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item: DragItem, monitor) {
      if (!ref.current || !monitor.isOver({ shallow: true })) {
        return
      }

      const dragIndex = item.index
      const hoverIndex = index

      if (dragIndex === hoverIndex) {
        return
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect()

      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

      const clientOffset = monitor.getClientOffset()

      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

      if (
        (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) ||
        (dragIndex > hoverIndex && hoverClientY > hoverMiddleY)
      ) {
        return
      }

      updateOrder(dragIndex, hoverIndex)

      item.index = hoverIndex
    },
  })

  const [{ isDragging }, drag, preview] = useDrag({
    type: acceptType,
    item: () => {
      return { id, index }
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  drop(ref)
  return (
    <Box ref={ref} data-handler-id={handlerId}>
      {children(isDragging, drag, preview)}
    </Box>
  )
}
