import { useState } from 'react'

import { logErr } from '../utils/Logger'
import {
  xmlToJsonApp,
  xmlToJsonComponent,
  appRegToXml,
  jsonToXmlComponent,
} from '../network/XmlInterop'
import { isDefined } from '../utils'

export const useXmlConvert = () => {
  const [xml, setXml] = useState('')
  const [json, setJson] = useState('')
  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const handleUnknownError = (e: Error) => {
    logErr(e)
    setError('Something unexpected happened')
  }

  const sendXml = () => {
    setIsLoading(true)
    const isApp = xml.indexOf('<template') >= 0
    if (!isApp) {
      sendCompXml().finally(() => setIsLoading(false))
    } else {
      sendAppXml().finally(() => setIsLoading(false))
    }
  }

  const addDesignProperty = (obj: any) => {
    if (typeof obj === 'object' && obj !== null) {
      if (Array.isArray(obj)) {
        obj.forEach(element => addDesignProperty(element))
      } else {
        if (!isDefined(obj.design)) {
          obj.design = null
        }

        for (const key in obj) {
          if (obj.hasOwnProperty(key)) {
            addDesignProperty(obj[key])
          }
        }
      }
    }
  }

  const sendJson = () => {
    setIsLoading(true)
    try {
      const parsedJson = JSON.parse(json)
      addDesignProperty(parsedJson)
      const isApp = isDefined(parsedJson.app_id)
      if (!isApp) {
        sendCompJson(parsedJson).finally(() => setIsLoading(false))
      } else {
        sendAppJson(parsedJson).finally(() => setIsLoading(false))
      }
    } catch (e) {
      setIsLoading(false)
      logErr(e)
      setError('Invalid JSON provided')
    }
  }

  const sendAppXml = () => {
    setJson('')
    return xmlToJsonApp(xml)
      .then(res => {
        if (res.type === 'right') {
          setJson(JSON.stringify(res.content, null, 2))
        } else {
          logErr(res)
          setError('The backend could not process the xml')
        }
      })
      .catch(handleUnknownError)
  }

  const sendCompXml = () => {
    setJson('')
    return xmlToJsonComponent(xml)
      .then(res => {
        if (res.type === 'right') {
          setJson(JSON.stringify(res.content, null, 2))
        } else {
          logErr(res)
          setError('The backend could not process the xml')
        }
      })
      .catch(handleUnknownError)
  }

  const sendAppJson = (parsedJson: any) => {
    setXml('')
    return appRegToXml(parsedJson)
      .then(res => {
        if (typeof res === 'string') {
          setXml(res)
        } else {
          logErr(res)
          setError('The backend could not process the json')
        }
      })
      .catch(handleUnknownError)
  }

  const sendCompJson = (parsedJson: any) => {
    setXml('')
    return jsonToXmlComponent(parsedJson)
      .then(res => {
        if (typeof res === 'string') {
          setXml(res)
        } else {
          logErr(res)
          setError('The backend could not process the json')
        }
      })
      .catch(handleUnknownError)
  }

  return {
    xml,
    setXml,
    json,
    setJson,
    error,
    setError,
    isLoading,
    sendJson,
    sendXml,
  }
}
