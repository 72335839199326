import { Tooltip } from '@mui/material'
import FormControlLabel from '@mui/material/FormControlLabel/FormControlLabel'
import Switch from '@mui/material/Switch/Switch'
import React from 'react'

import { AppTheme } from '../../containers/AppStyles/AppStyles'

export const JuvoSwitch: React.FC<{
  img: string
  isChecked: boolean
  action: (event: React.ChangeEvent) => void
  tooltip: string
}> = ({ img, isChecked, action, tooltip }) => {
  return (
    <Tooltip title={tooltip} arrow>
      <FormControlLabel
        sx={{
          margin: 0,
        }}
        control={
          <Switch
            checked={isChecked}
            onChange={action}
            sx={{
              padding: 0,
              width: '70px',
              height: '36px',
              '& .MuiSwitch-switchBase': {
                padding: '3px 5px',
                '&.Mui-checked': {
                  color: '#fff',
                  transform: 'translateX(calc(100% - 6px))',
                  '& .MuiSwitch-thumb': {
                    backgroundColor: (theme: AppTheme) =>
                      theme.palette.tertiary?.main,
                  },
                  '& + .MuiSwitch-track': {
                    opacity: 1,
                    borderRadius: 0,
                    background: theme => theme.palette.grey[700],
                  },
                },
              },
              '& .MuiSwitch-thumb': {
                background: style => style.palette.grey[800],
                height: '28px',
                width: '28px',
                borderRadius: 0,
                '&::before': {
                  content: "''",
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  left: 0,
                  top: 0,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  backgroundImage: `url(${img})`,
                  backgroundSize: '18px',
                },
              },
              '& .MuiSwitch-track': {
                opacity: 1,
                borderRadius: 0,
                position: 'relative',
                background: theme => theme.palette.grey[600],
                '&:before': {
                  fontFamily: '"Ubuntu Mono", monospace !important',
                  color: theme => theme.palette.common.white,
                  fontSize: '0.82em',
                  opacity: isChecked ? '0.4' : '0',
                  position: 'absolute',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  content: '"[esc]"',
                  letterSpacing: '-1px',
                  left: '3px',
                },
              },
            }}
          />
        }
        label=""
      />
    </Tooltip>
  )
}
