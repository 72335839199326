import React, { useEffect, useState } from 'react'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'
import FormHelperText from '@mui/material/FormHelperText'
import FormControl from '@mui/material/FormControl'
import clsx from 'clsx'

import { AppRegistration, Command, SelectModel } from '../../../types'
import { setComponentValue } from '../../../store'
import {
  createSlug,
  getJuvoProps,
  isDefined,
  sanitizeInputName,
  ValidationProps,
  findSharedItems,
  findSharingContainer,
} from '../../../utils'

const SelectField: React.FC<{
  comp: SelectModel
  onCommand: (cmd: Command) => void
  onChange: (_: SelectModel) => void
  app: AppRegistration
  validationProps: ValidationProps
}> = ({ comp, onCommand, onChange, app, validationProps }) => {
  const isSharing = isDefined(comp.use_sharing_container)
  const [sharingSourceItems, setSharingSourceItems] = useState<string[]>([])
  const container = comp.use_sharing_container
    ? findSharingContainer(
        app.app_skeleton.components,
        comp.use_sharing_container,
      )
    : []

  useEffect(() => {
    if (container && comp.use_sharing_property) {
      const data = findSharedItems(container, comp.use_sharing_property)
      setSharingSourceItems(data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [container])

  const handleChange = (e: SelectChangeEvent<string>) => {
    onChange(setComponentValue(comp)(e.target.value))
  }
  const { eventHandlers, value, juvoInfo } = getJuvoProps(
    'Select',
    comp,
    onCommand,
    handleChange,
  )
  const { onBlur: onBlurFromServer, ...handlersFromServer } = eventHandlers
  const label = comp.text || ''
  const selopts = comp.options.concat(
    comp?.bound?.options?.value || comp?.bound?.options?.recommendation || [],
  )
  const { handleVisited, hasErrors, errorMessage } = validationProps
  const inputName = sanitizeInputName(label)

  return (
    <FormControl
      error={hasErrors}
      variant="standard"
      fullWidth
      className="juvo-input-control"
      {...juvoInfo}
    >
      <InputLabel shrink htmlFor={inputName} className="juvo-input-label">
        {label}
      </InputLabel>
      <Select
        {...handlersFromServer}
        value={value}
        name={inputName}
        id={inputName}
        disableUnderline
        onBlur={() => {
          handleVisited()
          onBlurFromServer && onBlurFromServer()
        }}
        className={clsx(
          'juvo-input-base',
          isDefined(comp.recommendation) &&
            !isDefined(comp.value) &&
            'recommendation',
        )}
        sx={{
          marginTop: '0 !important',
          '.MuiSelect-icon': { right: '28px' },
          '.MuiInputBase-input': { paddingRight: '32px !important' },
        }}
        data-testid={comp.data_testid}
      >
        {(isSharing ? sharingSourceItems : selopts).map(option => (
          <MenuItem
            key={option}
            value={option}
            data-testid={`${option ? createSlug(option) : ''}-select-item`}
          >
            {option}
          </MenuItem>
        ))}
      </Select>
      {hasErrors && (
        <FormHelperText
          sx={{ visibility: hasErrors ? 'visible' : 'hidden' }}
          data-testid={`select-helptext-${comp.data_testid}`}
        >
          {errorMessage || ' '}
        </FormHelperText>
      )}
    </FormControl>
  )
}

export default SelectField
