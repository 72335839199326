import { ComponentModel } from '../../../types'
import { Nullable } from '../../../utils'

export type HintInfo = { title: string; body: string }

export type PropertyType =
  | 'string'
  | 'boolean'
  | 'tabs'
  | 'icon'
  | 'folder'
  | 'number'
  | 'UNDEFINED'

export type EditableProperty = {
  type: PropertyType
  values?: Array<any>
  defaultValue?: Nullable<any>
  isArray?: boolean
  multiline?: boolean
  subProperties?: Map<string, EditableProperty>
  optional?: boolean
  hint?: string
}

export type SaveableComp = {
  saveId: string
  comp: ComponentModel
}

export const APP_PROPERTY_WHITELIST: Map<string, EditableProperty> = new Map([
  ['app_help', { type: 'string', multiline: true }],
  ['app_name', { type: 'string' }],
  [
    'app_agent',
    { type: 'folder', subProperties: new Map([['icon', { type: 'icon' }]]) },
  ],
  ['app_id', { type: 'string' }],
])

export const COMP_PROPERTY_WHITELIST: Map<string, EditableProperty> = new Map([
  ['label', { type: 'string' }],
  ['text', { type: 'string' }],
  ['id', { type: 'string' }],
  ['placeholder', { type: 'string' }],
  ['tooltip', { type: 'string', optional: true }],
  ['title', { type: 'string' }],
  ['icon', { type: 'icon', optional: true }],
  [
    'accept',
    {
      type: 'string',
      hint: 'This may be a file extension(s) such as .jpg, a MIME type, or generics such as audio/*',
    },
  ],
  ['tab_id', { type: 'number' }],
  [
    'badges',
    { type: 'boolean', hint: 'Shows information about child inputs.' },
  ],
  [
    'input_options',
    {
      type: 'folder',
      defaultValue: {
        'read-only': false,
        'display-only': false,
        hidden: false,
        password: false,
      },
      subProperties: new Map([
        [
          'read-only',
          {
            type: 'boolean',
            hint: 'If set to read-only, the text cannot be edited.',
          },
        ],
        [
          'display-only',
          {
            type: 'boolean',
            hint: 'If set to display-only, the text is intended only to display information. Requires read-only to also be true.',
          },
        ],
        ['password', { type: 'boolean' }],
        ['hidden', { type: 'boolean' }],
      ]),
    },
  ],
  [
    'theme-style',
    { type: 'string', values: ['primary', 'secondary', 'info', 'error', null] },
  ],
  [
    'button.variant',
    {
      type: 'string',
      values: ['outlined', 'contained', 'text'],
      hint: 'Buttons that are the primary action of a page should use "contained." Generally, only one primary button should appear on a page at once. \n\nAll secondary buttons should use "outlined" and very minor buttons should use "text."',
    },
  ],
  [
    'text.variant',
    {
      type: 'string',
      values: [
        'h1',
        'h2',
        'h3',
        'h4',
        'h5',
        'subtitle1',
        'subtitle2',
        'body1',
        'caption',
      ],
    },
  ],
  [
    'labelVariant',
    {
      type: 'string',
      values: [
        'h1',
        'h2',
        'h3',
        'h4',
        'h5',
        'subtitle1',
        'subtitle2',
        'body1',
        'caption',
      ],
    },
  ],
  ['padding', { type: 'number' }],
  [
    'indent',
    {
      type: 'number',
      hint: 'Indent is different than padding in that it adds whitespace on just the left side of the container.',
    },
  ],
  ['columns', { type: 'number' }],
  [
    'addlabel',
    { type: 'string', hint: 'The text of the button that adds more elements.' },
  ],
  ['tab-select.options', { type: 'tabs' }],
  ['options', { type: 'string', isArray: true }],
  ['tag-list.value', { type: 'string', isArray: true }],
  [
    'folder.render_as',
    {
      type: 'string',
      values: [
        'floating-action-bar',
        'border-box-success',
        'border-box-default',
        null,
      ],
    },
  ],
  [
    'checkbox.render_as',
    {
      type: 'string',
      values: ['options', null],
      hint: 'Options will render the component as radio buttons.',
    },
  ],
  [
    'folder.sub_type',
    {
      type: 'string',
      values: ['form', null],
    },
  ],
  [
    'checkbox.sub_type',
    {
      type: 'string',
      values: ['two-state', null],
      hint: 'Two-state will eliminate the "indeterminate" state',
    },
  ],
])
