import React, { useEffect, useState } from 'react'
import Chip from '@mui/material/Chip'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import { alpha } from '@mui/material'
import Downshift from 'downshift'

import { ReorderElement } from '../../../components/juvo-container/ReorderElement/ReorderElement'
import { reorder } from '../../../utils/Common'

const ArrayAttributeInput: React.FC<{
  attrKey: string
  applyVal: (val: string[]) => void
  attrVal: string[]
}> = ({ attrKey, applyVal, attrVal }) => {
  const [inputValue, setInputValue] = useState('')
  const [listedValues, setListedValues] = useState<string[]>([])

  useEffect(() => {
    setListedValues(attrVal)
  }, [attrVal])

  const moveTag = (dragIndex: number, hoverIndex: number) => {
    const items = reorder(listedValues, dragIndex, hoverIndex)
    applyVal(items)
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement
    if (event.key === 'Enter') {
      const newSelectedItem = [...listedValues]
      // avoid white values
      if (!target.value.replace(/\s/g, '').length) return

      // avoid duplicated values
      const duplicatedValues = newSelectedItem.find(
        item => item === target.value.trim(),
      )

      if (duplicatedValues) {
        setInputValue('')
        return
      }

      newSelectedItem.push(target.value.trim())
      applyVal(newSelectedItem)
      setInputValue('')
    }
    if (
      listedValues.length &&
      !inputValue.length &&
      event.key === 'Backspace'
    ) {
      applyVal(listedValues.slice(0, listedValues.length - 1))
    }
  }

  const handleDelete = (item: string) => () => {
    const newSelectedItem: string[] = [...listedValues]
    newSelectedItem.splice(newSelectedItem.indexOf(item), 1)
    applyVal(newSelectedItem)
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value)
  }

  return (
    <Downshift
      id="downshift-multiple"
      inputValue={inputValue}
      selectedItem={listedValues}
    >
      {({ getInputProps }) => {
        const { onBlur, onChange, onFocus } = getInputProps({
          onKeyDown: handleKeyDown,
        })
        return (
          // This needs to be div, not Box, because Downshift requires it
          <div>
            <TextField
              slotProps={{
                input: {
                  startAdornment: (
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                      }}
                    >
                      {listedValues.map((item: any, index: number) => (
                        <ReorderElement
                          id={`${attrKey}-listItem-${index}`}
                          acceptType="any"
                          index={index}
                          key={index}
                          updateOrder={moveTag}
                        >
                          {(_isDragging, dragHandle, preview) => (
                            <Box
                              ref={preview}
                              sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                overflow: 'hidden',
                              }}
                            >
                              <Box ref={dragHandle} sx={{ margin: '4px' }}>
                                <Chip
                                  tabIndex={-1}
                                  label={item}
                                  onDelete={handleDelete(item)}
                                  sx={{
                                    background: theme =>
                                      theme.palette.grey[300],
                                    cursor: 'move',
                                  }}
                                />
                              </Box>
                            </Box>
                          )}
                        </ReorderElement>
                      ))}
                    </Box>
                  ),
                  onBlur: (event: React.FocusEvent<any>) => {
                    onBlur?.(event)
                  },
                  onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                    handleInputChange(event)
                    onChange?.(event)
                  },
                  onFocus: (event: React.FocusEvent<any>) => {
                    onFocus?.(event)
                  },
                  onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => {
                    handleKeyDown(event)
                  },
                  placeholder: 'Add tags',
                },
                inputLabel: {
                  className: 'juvo-input-label',
                  sx: {
                    color: theme => `${theme.palette.grey[300]} !important`,
                    fontWeight: '400 !important',
                    fontSize: '0.9em !important',
                  },
                },
              }}
              value={inputValue}
              fullWidth
              variant="standard"
              id="tags"
              name="tags"
              label={attrKey}
              color="primary"
              sx={{
                marginBottom: '16px',
                '& .MuiInputBase-root': {
                  display: 'flex',
                  flexDirection: 'column',
                  background: theme => alpha(theme.palette.grey[800], 0.5),
                  alignItems: 'flex-start',
                  padding: '6px',
                  marginTop: '0',
                  color: theme => theme.palette.grey[500],
                  '&:before': {
                    display: 'none',
                  },
                  '&:after': {
                    display: 'none',
                  },
                  input: {
                    color: theme => theme.palette.grey[300],
                    padding: '6px',
                    '&::placeholder': {
                      color: theme => theme.palette.grey[400],
                    },
                  },
                },
              }}
            />
          </div>
        )
      }}
    </Downshift>
  )
}

export default ArrayAttributeInput
