import { v4 as generateUuid } from 'uuid'
import { AlertColor } from '@mui/material'

import { ComponentModel, ResultSubType } from '../types'
import { FAVORITES_NAME } from '../constants/Constants'

import {
  isDefined,
  isUndefined,
  isUndefinedBool,
  Nullable,
  Undefined,
} from './Undefined'
import { logErr, logWarn } from './Logger'
import { ENVIRONMENT } from './Environment'

export type Either<L, R> =
  | { type: 'left'; content: L }
  | { type: 'right'; content: R }

export const right: <L, R>(_: R) => Either<L, R> = r => {
  return { type: 'right', content: r }
}
export const left: <L, R>(_: L) => Either<L, R> = r => {
  return { type: 'left', content: r }
}
export const eitherChain: <L, R1, R2>(
  _1: Either<L, R1>,
  _2: (_: R1) => Either<L, R2>,
) => Either<L, R2> = (e, fn) => {
  if (e.type == 'left') return e
  else return fn(e.content)
}
export const eitherMap: <L, R1, R2>(
  _1: Either<L, R1>,
  _2: (_: R1) => R2,
) => Either<L, R2> = (e, fn) => {
  if (e.type == 'left') return e
  else return right(fn(e.content))
}

//helper needed to avoid casting, R is not in scope inside implementation is type checker gets confused
const filterOutNullsHelper: <R>(_: Nullable<R>[], empty: R[]) => R[] = (
  lst,
  empty,
) => {
  return lst.reduce((acc, el) => {
    if (isUndefined(el)) return acc
    else return [el, ...acc]
  }, empty)
}

export const filterOutNulls: <R>(_: (R | Undefined)[]) => R[] = lst => {
  return filterOutNullsHelper(lst, [])
}

export const assumeRights: <L, R>(_: Either<L, R>[]) => R[] = lst => {
  const res = lst.map(el => {
    if (el.type == 'right') return el.content
    else {
      logErr(`Unexpected Left ${el.content}`)
      return null
    }
  })
  return filterOutNulls(res)
}

export const genGuid = () => window.performance.now().toString()

export const genId = () => generateUuid()

export const curry = <T1, T2, R>(
  fn: (ax: T1, bx: T2) => R,
): ((a: T1) => (b: T2) => R) => {
  return (a: T1) => (b: T2) => fn(a, b)
}

export const curry3 = <T1, T2, T3, R>(
  fn: (ax: T1, bx: T2, cx: T3) => R,
): ((a: T1) => (b: T2) => (c: T3) => R) => {
  return (a: T1) => (b: T2) => (c: T3) => fn(a, b, c)
}

export const pureAsync = <T>(value: T): Promise<T> => {
  return new Promise(resolve => {
    resolve(value)
  })
}

export const isUnit = (a: any): boolean => {
  //this is safe for non-object types too
  return Object.keys(a).length === 0
}

// type hole for code exploration (a.k.a haskell undefined)
export declare function _<T>(): T

export const sanitizeUrl = (url: string): string => {
  if (url.match(/^www\..*$/)) {
    return `https://${url}`
  } else {
    return url
  }
}

export const sanitizeInputName = (label: string): string => {
  return label.toLowerCase().replaceAll(' ', '_')
}

/**
 * Type safer than loash,  Seems to work better.
 */
export const zipWith = <L, R, T>(
  l: Nullable<L[]>,
  r: Nullable<R[]>,
  fn: (a: L, b: R) => T,
): T[] => {
  const useL = l || [] //extra null safety
  const useR = r || []
  const lengthR = useR.length
  const res: T[] = []
  for (let index = 0; index < useL.length; index++) {
    if (index < lengthR) {
      const el = useL[index]
      const er = useR[index]
      res.push(fn(el, er))
    }
  }
  if (lengthR !== useL.length) {
    logWarn('zipWith uneven sizes', { left: l, right: r })
  }
  return res
}

export const scrollAppContentToTop = (): void => {
  const appContent = document.querySelector('.app-content')
  appContent?.scrollTo(0, 0)
}

export const getLastPathSegment = (): string => {
  return location.pathname.replace(/^.*\//, '')
}
export const pathContainsSegment = (segment: string): boolean => {
  return location.pathname.split('/').includes(segment)
}

export const checkBoxTooltipTitle = (
  val: any,
  rec: any,
  twoStates?: boolean,
) => {
  if (isUndefinedBool(val) && isUndefinedBool(rec) && !twoStates) {
    return 'Checkbox value is not set. Click once to select or twice to deselect.'
  } else {
    return ''
  }
}

export const getEnvironmentFavoritesName = (environment: string) => {
  return `${FAVORITES_NAME}${
    environment !== ENVIRONMENT.production ? `_${environment}` : ''
  }`
}

export const getHelpDismissedForApp = (
  appId: string,
  version: Nullable<string>,
  env: string,
) => {
  const v = version ?? '0.0.0'
  const localStorageItem = localStorage.getItem(`${env}-${appId}`)
  return isDefined(localStorageItem) && localStorageItem === v
}

export const setHelpDismissedForApp = (
  appId: string,
  version: Nullable<string>,
  env: string,
) => {
  const v = version ?? '0.0.0'
  localStorage.setItem(`${env}-${appId}`, v)
}

export const getLocalFavorites = (environment: string): string[] => {
  const name = getEnvironmentFavoritesName(environment)
  const localStorageItem =
    localStorage.getItem(name) ?? localStorage.getItem(FAVORITES_NAME) // For backward compatibility
  return !localStorageItem ? [] : (JSON.parse(localStorageItem) as string[])
}

export const setLocalFavorites = (environment: string, value: string[]) => {
  const name = getEnvironmentFavoritesName(environment)
  localStorage.setItem(name, JSON.stringify(value))
}

export const getTwoLetters = (fullName: string) => {
  const splitFirstLastName = fullName.split(' ')
  const firstLettersA = splitFirstLastName.map(name => name.charAt(0))
  return firstLettersA.join('').slice(0, 2)
}

export const getDownloadIconFromFileName = (link: string) => {
  const linkSplit = link.toLowerCase().split('.')
  switch (linkSplit.pop()) {
    case 'docx':
      return 'fa-file-word'
    case 'pdf':
      return 'fa-file-pdf'
    case 'xlsx':
      return 'fa-file-excel'
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'svg':
      return 'fa-file-image'
    case 'eml':
      return 'fa-envelope-open-text'
    default:
      return 'fa-file'
  }
}

export const convertResultSubTypeToColor = (st: ResultSubType): AlertColor => {
  switch (st) {
    case 'failure':
      return 'error'
    case 'success':
      return 'success'
    case 'info':
      return 'info'
  }
}

export const createSlug = (sentence: string): string => {
  const words = sentence.split(' ').map(elem => elem.trim())
  return words.join('-')
}

export const reorder = (
  list: ComponentModel[],
  startIndex: number,
  endIndex: number,
): ComponentModel[] => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}
