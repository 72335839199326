import React from 'react'
import InputLabel from '@mui/material/InputLabel/InputLabel'
import Box from '@mui/material/Box/Box'
import Select from '@mui/material/Select/Select'
import MenuItem from '@mui/material/MenuItem/MenuItem'
import { SxProps } from '@mui/material/styles'

import { useUIBuilderHint } from '../Hint/UIBuilderHintContext'

interface StringSelectAttributeInputProps {
  inputStyles: SxProps
  labelStyles: SxProps
  attrKey: string
  fireChange: (val: string) => void
  val: string | undefined
  values: Array<string>
  hint?: string
}

export const StringSelectAttributeInput: React.FC<
  StringSelectAttributeInputProps
> = ({ attrKey, fireChange, labelStyles, inputStyles, values, val, hint }) => {
  const { hintButton } = useUIBuilderHint()

  return (
    <Box>
      <InputLabel sx={labelStyles}>
        {attrKey}
        {hint && hintButton({ title: attrKey, body: hint })}
      </InputLabel>
      <Select
        onChange={event => fireChange(event.target.value)}
        value={val ?? ''}
        sx={inputStyles}
      >
        {values.map((v, index) => (
          <MenuItem key={index} value={v}>
            {v === null ? '[empty]' : v}
          </MenuItem>
        ))}
      </Select>
    </Box>
  )
}
