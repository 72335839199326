import React, { createContext, useContext, useState } from 'react'
import IconButton from '@mui/material/IconButton'

import { Nullable } from '../../../utils'
import { HintInfo } from '../data/properties'

import { UIBuilderHint } from './UIBuilderHint'

type UIBuilderHintContextProps = {
  showHintInfo: (info: HintInfo) => void
  hintButton: (hint: HintInfo) => React.ReactNode | undefined
}

const UIBuilderHintContext = createContext<UIBuilderHintContextProps>({
  showHintInfo: () => undefined,
  hintButton: () => undefined,
})

export const useUIBuilderHint = () => useContext(UIBuilderHintContext)

export const UIBuilderHintProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [hintInfo, setHintInfo] = useState<Nullable<HintInfo>>(null)

  const showHintInfo = (info: HintInfo) => {
    setHintInfo(info)
  }

  const hintButton = (hint: HintInfo) =>
    hint ? (
      <IconButton
        className="fas fa-sharp fa-square-question"
        sx={{
          fontSize: '1em',
          color: theme => `${theme.palette.primary.light}!important`,
        }}
        onClick={() => showHintInfo({ title: hint.title, body: hint.body })}
      />
    ) : undefined

  return (
    <UIBuilderHintContext.Provider
      value={{
        showHintInfo: showHintInfo,
        hintButton: hintButton,
      }}
    >
      <UIBuilderHint setHintInfo={setHintInfo} hintInfo={hintInfo} />
      {children}
    </UIBuilderHintContext.Provider>
  )
}
