export const FAVORITES_NAME = 'JUVO_FAVORITES'
export const SAVED_COMPS = 'JUVO-UI-BUILDER'

// We could you an array in the future if another custom app is added
export const CUSTOM_XML_APP_ID = 'mod-draft-patent-oa'

// NormalCurve constants
export const MAX_DEV_GRAPH_RANGE = 5
export const MAX_DEV_VISUAL_RANGE = 3
export const GRAPH_SMOOTHNESS = 80

export const NOTIFICATION_MESSAGE_CUTOFF = 70

export const SNACKBAR_TIMER_WIDTH = 40
export const SNACKBAR_TRANSITION = 200
export const SNACKBAR_DURATION = 8000
