import Box from '@mui/material/Box'
import React from 'react'

export const OptionsBar: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <Box
      className="options-box"
      sx={{
        position: 'absolute',
        background: theme => theme.palette.grey[700],
        color: theme => theme.palette.common.white,
        display: 'none',
        alignItems: 'center',
        boxSizing: 'border-box',
        zIndex: 2000,
        padding: '4px',
        bottom: 'unset',
        height: '38px',
        top: '2px',
        gap: '4px',
        right: '2px',
        borderRadius: '4px',
        cursor: 'pointer',
        transform: 'unset',
      }}
    >
      {children}
    </Box>
  )
}
