import { Box, BoxProps } from '@mui/material'
import React from 'react'

interface DrawerWrapperProps extends BoxProps {
  children: React.ReactNode
}

export const DrawerWrapper: React.FC<DrawerWrapperProps> = ({
  children,
  ...props
}) => {
  const { sx, ...myProps } = props

  return (
    <Box
      role="presentation"
      data-testid="builder-drawer"
      className="builder-drawer"
      sx={{
        padding: '16px',
        position: 'relative',
        overflowY: 'auto',
        overflowX: 'visible',
        width: 'var(--ui-drawer-width)',
        height: '100%',
        background: theme => theme.palette.grey[900],
        zIndex: theme => theme.zIndex.drawer,
        ...sx,
      }}
      {...myProps}
    >
      {children}
    </Box>
  )
}
