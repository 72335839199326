import { alpha, Box, Typography, useTheme } from '@mui/material'
import React from 'react'
import { ConnectDragSource, useDragDropManager } from 'react-dnd'

import { ComponentModel } from '../../types'
import { useUIBuilder } from '../../providers/UIBuilderProvider'
import { genGuid, isDefined } from '../../utils'
import { ReorderElement } from '../../components/juvo-container/ReorderElement/ReorderElement'
import { ReorderContainer } from '../../components/juvo-container/ReorderContainer/ReorderContainer'
import { insertComponentAfter, mergeDroppedWithCheatsheet } from '../../store'
import { useSavedUIBuilderComps } from '../../hooks/useSavedUIBuilderComps'

import { DroppableWrapper } from './DroppableWrapper'
import { OptionsButton } from './OptionsButton'
import { ComponentToJsonXml } from './ComponentToJsonXml'
import { GenericList } from './data/generics'
import { OptionsBar } from './OptionsBar'

export type OptionsChildren = (
  changeOrder: (dragIndex: number, hoverIndex: number) => void,
  acceptType: string,
) => React.ReactElement<HTMLElement>

interface OptionsWrapperProps {
  children: OptionsChildren
  myComp: ComponentModel
  isDroppable: boolean
  addToChildComps: (c: ComponentModel, parent: ComponentModel) => void
  index: number
  moveItem: (dragIndex: number, hoverIndex: number) => void
  acceptType: string
  changeFn?: (comp: ComponentModel) => void
}

export const OptionsWrapper: React.FC<OptionsWrapperProps> = ({
  children,
  myComp,
  isDroppable,
  addToChildComps,
  index,
  moveItem,
  acceptType,
  changeFn,
}) => {
  const {
    functionMode,
    appReg,
    setAppComps,
    setSelectedComponent,
    selectedComponent,
    removeComp,
    genIdsWithChildren,
    updateComp,
    compSheet,
    cutComp,
    pasteComp,
    hasCutComp,
  } = useUIBuilder()

  const { saveUIBuilderComp } = useSavedUIBuilderComps()
  const theme = useTheme()
  const cheatSheetLoaded = isDefined(compSheet) && compSheet.size > 0

  const dragDropManager = useDragDropManager()
  const monitor = dragDropManager.getMonitor()
  const isDraggingFromDrawer = monitor.isDragging() && monitor.getItem().init
  const isEditing = functionMode === 'Edit'

  const isCurrentlySelected =
    isDefined(selectedComponent) &&
    isDefined(myComp.uiBuilderId) &&
    myComp.uiBuilderId === selectedComponent.uiBuilderId

  const selectIfEditing = () => {
    if (isEditing) {
      setSelectedComponent(myComp)
    }
  }

  const cloneComp = (c: ComponentModel) => {
    const cloned: ComponentModel = {
      ...c,
      text: c.text ? c.text + ' - cloned' : c.text,
      id: c.id + ' - cloned',
      uiBuilderId: genGuid(),
    }
    const newComps = insertComponentAfter(
      cloned,
      c,
      appReg.app_skeleton.components,
    )
    setAppComps(newComps)
  }

  const makeListFromComp = () => {
    if (cheatSheetLoaded) {
      const newList = {
        ...GenericList,
        emptycomponent: genIdsWithChildren(myComp),
        uiBuilderId: myComp.uiBuilderId,
        tab_id: isDefined(myComp.tab_id) ? myComp.tab_id : undefined,
      }
      const newComp = mergeDroppedWithCheatsheet(newList, compSheet)
      updateComp(newComp)
    }
  }

  const innerElement = (
    dragHandle: ConnectDragSource,
    changeOrder: (dragIndex: number, hoverIndex: number) => void,
    at: string,
  ) => (
    <ComponentToJsonXml myComp={myComp}>
      {(compToJson, compToXml, isLoading) => (
        <Box
          sx={{
            position: 'relative',
            height: '100%',
            outlineOffset: '2px',
            transition: 'all 0.16s ease',
            ...(isEditing &&
              !isDraggingFromDrawer && {
                padding: '4px',
                marginTop: '16px',
                borderRadius: '4px',
                outline: `2px dashed ${alpha(theme.palette.grey[700], 0.3)}`,
                cursor: 'move !important',
                '&:hover:not(:has(&:hover))': {
                  outline: `2px solid ${theme.palette.grey[700]}`,
                  '& > .options-box': {
                    display: 'flex',
                  },
                },
                '*': {
                  cursor: 'move',
                },
              }),
            ...(isCurrentlySelected &&
              isEditing && {
                outline: `2px solid ${theme.palette.tertiary.main} !important`,
              }),
          }}
          ref={isEditing ? dragHandle : null}
        >
          <OptionsBar>
            <Typography
              sx={{
                color: theme => theme.palette.secondary.main,
                padding: '0 0 0 8px',
                fontSize: '0.7em',
                fontWeight: 600,
                textTransform: 'uppercase',
              }}
            >
              {myComp.type}
            </Typography>
            <OptionsButton
              title="Delete"
              icon="fa-trash"
              onClick={() => removeComp(myComp)}
            />
            <OptionsButton
              title="Save component"
              icon="fa-save"
              onClick={() => saveUIBuilderComp(myComp)}
            />
            {hasCutComp && myComp.tchildren && (
              <OptionsButton
                title="Paste"
                icon="fa-paste"
                onClick={() => pasteComp(myComp)}
              />
            )}

            <OptionsButton
              title="Cut"
              icon="fa-scissors"
              onClick={() => cutComp(myComp)}
            />

            <OptionsButton
              title="Clone"
              icon="fa-clone"
              onClick={() => cloneComp(myComp)}
            />
            {myComp.type === 'folder' && (
              <OptionsButton
                title="Make list"
                icon="fas fa-sharp fa-rectangle-list"
                onClick={makeListFromComp}
              />
            )}
            <OptionsButton
              title="Comp XML"
              icon={isLoading ? 'fa-spin fa-spinner' : 'fa-file-xml'}
              onClick={compToXml}
              disabled={isLoading}
            />
            <OptionsButton
              title="Comp JSON"
              icon="fa-file-code"
              onClick={compToJson}
            />
            <OptionsButton
              title="Edit"
              icon="fa-pen-to-square"
              onClick={selectIfEditing}
            />
          </OptionsBar>

          {isDroppable ? (
            <DroppableWrapper myComp={myComp} addToChildComps={addToChildComps}>
              {children(changeOrder, at)}
            </DroppableWrapper>
          ) : (
            children(changeOrder, at)
          )}
        </Box>
      )}
    </ComponentToJsonXml>
  )

  return (
    <ReorderElement
      id={`${acceptType}_${myComp.uiBuilderId}`}
      acceptType={acceptType}
      index={index}
      key={index}
      updateOrder={moveItem}
    >
      {(_isDragging, dragHandle) =>
        !isDefined(myComp.tchildren) || myComp.tchildren.length === 0 ? (
          innerElement(dragHandle, moveItem, myComp.uiBuilderId)
        ) : (
          <ReorderContainer
            comp={myComp}
            acceptType={myComp.uiBuilderId}
            changeFn={isDefined(changeFn) ? changeFn : () => null}
          >
            {(changeOrder, at) => innerElement(dragHandle, changeOrder, at)}
          </ReorderContainer>
        )
      }
    </ReorderElement>
  )
}
