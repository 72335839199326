import React from 'react'

import SwitchYard from '../../juvo-component/SwitchYard/SwitchYard'
import {
  adjustStdComponentArgs,
  ComponentModel,
  StdComponentArgs,
  WhenModel,
  whenSelection,
} from '../../../types'
import { containerSetChild, getJuvoInfo } from '../../../store'
import { logErr } from '../../../utils/Logger'
import { findMatchingChildIndex } from '../../../utils/When'

/**
 * Renders a user branching decision that comes from a paired component (e.g. CheckBoxField, SelectField, etc.)
 */
const When: React.FC<StdComponentArgs<WhenModel>> = props => {
  const { comp, onComponentChange } = props
  const children = comp.tchildren || []
  const whenBranch = whenSelection(comp)

  //whenBranch will be null if:
  //  * when component is added as new record in a list
  //  * backend returns msg data for union/ enum which value is not covered by the 'jf-when' in xml (no-exhaustive match in the app)
  //  * can be caused by earaser
  // this are the known cases I can think about now,  it is important to note that this appears to be a catch-all situation for
  // non-exhaustive data-UI matching (xml does not cover all union cases situations)
  const childIndex: number = findMatchingChildIndex(children, whenBranch)

  if (childIndex == -1) {
    logErr('No matching child found or invalid child type encountered')
    return <div className="when-no-selection" />
  } else {
    const childComp: ComponentModel | null = children[childIndex]
    if (childComp === null) {
      //addins sometimes do not provide all choices, especially with checkbox (nothing on false)
      //this needs to be supported as a valid case
      return (
        <div {...getJuvoInfo('When', comp)} className="when-mismatch"></div>
      )
    } else {
      const childProps = adjustStdComponentArgs(
        props,
        childComp,
        nc => onComponentChange(containerSetChild(nc, childIndex, comp)),
        childIndex,
      )

      return (
        <div {...getJuvoInfo('When', comp)} className="when-selection">
          <SwitchYard key={childIndex} {...childProps} />
        </div>
      )
    }
  }
}

export default When
