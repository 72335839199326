import {
  AccordionModel,
  AppRegistration,
  ButtonsModel,
  CarouselModel,
  CheckboxModel,
  ColumnModel,
  ComponentModel,
  CurrencyInputModel,
  DateModel,
  FolderModel,
  ListModel,
  MultiSelectModel,
  NumberInputModel,
  OptionsModel,
  RatingModel,
  SelectModel,
  StaticTextModel,
  StepModel,
  StepperModel,
  TabSelectModel,
  TagListModel,
  TextInputModel,
  UploadModel,
  WhenDefault,
} from '../../../types/Model'
import { Nullable } from '../../../utils'

export const genericApp: AppRegistration = {
  app_agent: {
    icon: 'fas fa-hand-peace',
  },
  app_doclink: undefined,
  app_id: 'mod-your-id-here',
  app_name: 'WIP',
  app_help: undefined,
  design: {},
  app_popup: undefined,
  app_actions: [],
  app_skeleton: {
    actions: [],
    command: undefined,
    components: [],
    tabs: [],
  },
  app_version: undefined,
  out_command: undefined,
}

export type DroppableItem = {
  comp: ComponentModel
  init?: boolean // Is true when the droppable is being dragged from the component drawer
}

export type UIBuilderComponent<T> = Partial<T> & {
  uiBuilderId: Nullable<string>
  design?: any
}

export const GenericTabSelect: UIBuilderComponent<TabSelectModel> = {
  type: 'tab-select',
  options: ['Tab-1', 'Tab-2'],
  uiBuilderId: undefined,
  value: 'Tab-1',
  design: {
    options_binding: "['Tab-1', 'Tab-2']",
  },
  tchildren: [
    {
      design: {
        inclusive: undefined,
        jf_for: undefined,
        jf_when: 'Tab-1',
      },
      indent: 0,
      tchildren: [],
      type: 'folder',
      when: 'Tab-1',
      columns: undefined,
      when_default: undefined,
      render_as: undefined,
      uiBuilderId: undefined,
    },
    {
      design: {
        inclusive: undefined,
        jf_for: undefined,
        jf_when: 'Tab-2',
      },
      indent: 0,
      tchildren: [],
      type: 'folder',
      when: 'Tab-2',
      columns: undefined,
      when_default: undefined,
      render_as: undefined,
      uiBuilderId: undefined,
    },
  ],
}

export const GenericStepper: UIBuilderComponent<StepperModel> = {
  perform_validation: false,
  tchildren: [
    {
      tchildren: [
        {
          indent: 1,
          type: 'folder',
          id: 'generic-step-folder-1',
          tchildren: [],
        },
      ],
      id: 'generic-step-1',
      type: 'step',
      label: 'Step 1',
    },
    {
      tchildren: [
        {
          indent: 1,
          type: 'folder',
          id: 'generic-step-folder-2',
          tchildren: [],
        },
      ],
      id: 'generic-step-2',
      type: 'step',
      label: 'Step 2',
    },
    {
      tchildren: [
        {
          indent: 1,
          type: 'folder',
          id: 'generic-step-folder-3',
          tchildren: [],
        },
      ],
      id: 'generic-step-3',
      type: 'step',
      label: 'Step 3',
    },
  ],
  id: 'GenericStepper',
  uiBuilderId: undefined,
  type: 'stepper',
  std_buttons: true,
}

export const GenericCarousel: UIBuilderComponent<CarouselModel> = {
  type: 'list',
  render_as: 'carousel',
  position: null,
  emptycomponent: {
    tchildren: [],
    id: null,
    when_default: null,
    render_as: null,
    type: 'folder',
    design: {
      inclusive: null,
      jf_for: null,
      jf_when: null,
    },
    label: 'Carousel-page',
  },
  tchildren: [
    {
      tchildren: [],
      id: null,
      when_default: null,
      render_as: null,
      type: 'folder',
      columns: null,
      label: 'Carousel-1',
    },
    {
      tchildren: [],
      id: null,
      when_default: null,
      render_as: null,
      type: 'folder',
      columns: null,
      label: 'Carousel-2',
    },
    {
      tchildren: [],
      id: null,
      when_default: null,
      render_as: null,
      type: 'folder',
      columns: null,
      label: 'Carousel-3',
    },
  ],
  uiBuilderId: null,
  addlabel: 'test',
  id: 'mod-hello-carousel-1',
}

export const GenericList: UIBuilderComponent<ListModel> = {
  type: 'list',
  render_as: 'list',
  carousel_type: null,
  position: null,
  tchildren: [],
  emptycomponent: null,
  label: 'Generic list',
  addlabel: 'Add',
  id: null,
  uiBuilderId: null,
}

export const GenericFolder: UIBuilderComponent<
  (FolderModel & { columns: Nullable<number> }) & WhenDefault
> = {
  label: 'Generic folder',
  uiBuilderId: undefined,
  when_default: undefined,
  columns: undefined, // Adding a columns property since columns and folders are not distinct in xml
  render_as: 'border-box-default',
  type: 'folder',
  padding: 2,
  sub_type: undefined,
}

export const GenericColumns: UIBuilderComponent<ColumnModel & WhenDefault> = {
  label: 'Generic columns',
  columns: 2,
  padding: 2,
  when_default: undefined,
  render_as: 'border-box-default',
  uiBuilderId: undefined,
  type: 'folder',
}

export const GenericAccordion: UIBuilderComponent<AccordionModel> = {
  label: 'Generic accordion',
  type: 'accordion',
  labelVariant: 'subtitle2',
  uiBuilderId: undefined,
}

export const GenericTextInput: UIBuilderComponent<TextInputModel> = {
  type: 'input',
  text: 'Text input',
  uiBuilderId: undefined,
  input_options: {
    'read-only': false,
    'display-only': false,
    hidden: false,
    password: false,
  },
}

export const GenericNumberInput: UIBuilderComponent<NumberInputModel> = {
  type: 'input',
  sub_type: 'number',
  text: 'Number input',
  uiBuilderId: undefined,
}

export const GenericCurrencyInput: UIBuilderComponent<CurrencyInputModel> = {
  type: 'input',
  sub_type: 'currency',
  text: 'Currency Input',
  uiBuilderId: undefined,
}

export const GenericCheckbox: UIBuilderComponent<CheckboxModel> = {
  type: 'checkbox',
  text: 'Generic checkbox',
  sub_type: undefined,
  render_as: undefined,
  uiBuilderId: undefined,
}

export const GenericDateInput: UIBuilderComponent<DateModel> = {
  type: 'input',
  sub_type: 'date',
  text: 'Generic Date',
  uiBuilderId: undefined,
}

export const GenericTagList: UIBuilderComponent<TagListModel> = {
  type: 'tag-list',
  placeholder: 'placeholder',
  title: 'Generic Tag List',
  value: ['tag1', 'tag2'],
  uiBuilderId: undefined,
}

export const GenericRatingsInput: UIBuilderComponent<RatingModel> = {
  type: 'rating',
  text: 'Generic Ratings',
  value: '',
  options: ['Rating1', 'Rating2', 'Rating3', 'Rating4'],
  uiBuilderId: undefined,
}

export const GenericFileDrop: UIBuilderComponent<UploadModel> = {
  type: 'file-uploads',
  title: 'Generic File Drop',
  uiBuilderId: undefined,
  accept: undefined,
}

export const GenericRadioInput: UIBuilderComponent<OptionsModel> = {
  type: 'options',
  text: 'Generic radio',
  value: 'Option 1',
  options: ['Option 1', 'Option 2'],
  uiBuilderId: undefined,
}

export const GenericSelect: UIBuilderComponent<SelectModel> = {
  type: 'select',
  text: 'Generic Select',
  options: ['Option 1', 'Option 2'],
  uiBuilderId: undefined,
}

export const GenericMultiselect: UIBuilderComponent<MultiSelectModel> = {
  type: 'multi-select',
  text: 'Generic Multiselect',
  uiBuilderId: undefined,
}

export const GenericH1Text: UIBuilderComponent<StaticTextModel> = {
  type: 'text',
  title: 'H1 Title',
  text: 'Generic Text',
  variant: 'h1',
  uiBuilderId: undefined,
  'theme-style': undefined,
}

export const GenericH2Text: UIBuilderComponent<StaticTextModel> = {
  type: 'text',
  title: 'H2 Title',
  text: 'Generic Text',
  variant: 'h2',
  uiBuilderId: undefined,
  'theme-style': undefined,
}

export const GenericH3Text: UIBuilderComponent<StaticTextModel> = {
  type: 'text',
  title: 'H3 Title',
  text: 'Generic Text',
  variant: 'h3',
  uiBuilderId: undefined,
  'theme-style': undefined,
}

export const GenericSub1Text: UIBuilderComponent<StaticTextModel> = {
  type: 'text',
  title: 'Sub1 Title',
  text: 'Generic Text',
  variant: 'subtitle1',
  uiBuilderId: undefined,
  'theme-style': undefined,
}

export const GenericSub2Text: UIBuilderComponent<StaticTextModel> = {
  type: 'text',
  title: 'Sub2 Title',
  text: 'Generic Text',
  variant: 'subtitle2',
  uiBuilderId: undefined,
  'theme-style': undefined,
}

export const GenericBodyText: UIBuilderComponent<StaticTextModel> = {
  type: 'text',
  title: 'Body Title',
  text: 'Generic Text',
  variant: 'body1',
  uiBuilderId: undefined,
  'theme-style': undefined,
}

export const GenericButtons: UIBuilderComponent<ButtonsModel> = {
  type: 'buttons',
  uiBuilderId: undefined,
  buttons: [
    {
      type: 'button',
      id: undefined,
      icon: null,
      input_actions: [
        {
          destination: 'changeme1',
          event: 'Click',
          key: null,
          perform_validation: false,
          trigger_loading: true,
        },
      ],
      text: 'changeme1',
      variant: 'contained',
    },
  ],
}

export const GenericStep: UIBuilderComponent<StepModel> = {
  type: 'step',
  label: 'Generic step',
  tchildren: [
    {
      tchildren: [],
      id: null,
      when_default: null,
      render_as: null,
      type: 'folder',
      design: {
        inclusive: null,
        jf_for: null,
        jf_when: null,
      },
    },
  ],
  uiBuilderId: undefined,
}
