import React from 'react'
import InputLabel from '@mui/material/InputLabel/InputLabel'
import Box from '@mui/material/Box/Box'
import List from '@mui/material/List/List'
import ListItem from '@mui/material/ListItem/ListItem'
import Button from '@mui/material/Button/Button'
import Icon from '@mui/material/Icon/Icon'
import { SxProps } from '@mui/material'

import { ComponentModel, StepModel } from '../../../types'
import { useUIBuilder } from '../../../providers/UIBuilderProvider'
import { GenericStep } from '../data/generics'

import { TabNameInput } from './TabsAttributeInput'

interface StepperAttributeInputProps {
  labelStyles: SxProps
  inputStyles: SxProps
  applyVal: (comp: ComponentModel) => void
  comp: ComponentModel
}

export const StepperAttributeInput: React.FC<StepperAttributeInputProps> = ({
  applyVal,
  labelStyles,
  inputStyles,
  comp,
}) => {
  const { genIdsWithChildren } = useUIBuilder()

  const addStepperPage = () => {
    const newPage = genIdsWithChildren({ ...GenericStep })
    const newComp = {
      ...comp,
      tchildren: comp.tchildren.concat([newPage]),
    }
    applyVal(newComp)
  }

  const updateTab = (val: string, index: number) => {
    const oldValue = { ...comp.tchildren[index] }
    oldValue.label = val
    const newValues = [...comp.tchildren]
    newValues[index] = oldValue
    const newComp = {
      ...comp,
      tchildren: newValues,
    }
    applyVal(newComp)
  }

  const deleteStep = (index: number) => {
    if (comp.tchildren.length <= 2) {
      return
    }
    const childrenToMove = comp.tchildren[index]?.tchildren ?? []
    const newValues = [...comp.tchildren].toSpliced(index, 1)
    newValues[index - 1].tchildren =
      newValues[index - 1].tchildren.concat(childrenToMove)
    const newPos = comp.position === index ? index - 1 : comp.position
    const newComp = {
      ...comp,
      tchildren: newValues,
      position: newPos,
      value: comp.value === index ? index - 1 : comp.value,
      design: { ...comp.design },
    }
    applyVal(newComp)
  }

  return (
    <Box>
      <InputLabel sx={labelStyles}>Pages</InputLabel>
      <List
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '4px',
        }}
      >
        {comp.tchildren.map((s: StepModel, index: number) => (
          <ListItem
            key={index}
            sx={{
              padding: 0,
              position: 'relative',
            }}
          >
            <TabNameInput
              inputStyles={inputStyles}
              value={s.label}
              updateTab={v => updateTab(v, index)}
              deleteTab={() => deleteStep(index)}
            />
          </ListItem>
        ))}
      </List>
      <Button
        variant="outlined"
        color="secondary"
        sx={{
          width: '100%',
        }}
        onClick={addStepperPage}
        startIcon={<Icon className="fas fa-sharp fa-plus" />}
      >
        Step
      </Button>
    </Box>
  )
}
