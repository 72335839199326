import React from 'react'
import Button from '@mui/material/Button/Button'
import { Box, Icon } from '@mui/material'

import { JuvoDialog } from '../../JuvoModal/JuvoDialog'
import { JuvoAlertContainer } from '../../JuvoAlert/JuvoAlertContainer'
import { useXmlConvert } from '../../../hooks/useXmlConvert'

import { CodeSection } from './CodeSection'

interface XmlConverterProps {
  showModal: boolean
  handleClose: () => void
}

export const XmlConverter: React.FC<XmlConverterProps> = ({
  showModal,
  handleClose,
}) => {
  const {
    xml,
    setXml,
    json,
    setJson,
    error,
    setError,
    isLoading,
    sendJson,
    sendXml,
  } = useXmlConvert()

  return (
    showModal && (
      <JuvoDialog
        title="XML to JSON"
        severity="info"
        open={true}
        handleClose={handleClose}
        sx={{
          '.MuiDialog-paper': {
            width: { xs: '90vw', sm: '75vw' },
            borderRadius: 0,
            maxWidth: '1200px',
            height: '800px',
          },
        }}
      >
        {error && (
          <JuvoAlertContainer
            title={error}
            onClose={() => setError('')}
            severity="error"
          />
        )}

        <Box
          sx={{
            display: 'grid',
            gap: '12px',
            height: '100%',
            gridTemplateColumns: '1fr 1fr',
          }}
        >
          <CodeSection
            label="XML"
            value={xml}
            setValue={setXml}
            convertButton={
              <Button
                variant="outlined"
                onClick={() => sendXml()}
                sx={{ width: '100%' }}
                disabled={isLoading}
                endIcon={<Icon className="fas fa-sharp fa-arrow-right" />}
              >
                Convert to JSON
              </Button>
            }
          />

          <CodeSection
            label="JSON"
            value={json}
            setValue={setJson}
            convertButton={
              <Button
                variant="outlined"
                onClick={() => sendJson()}
                sx={{ width: '100%' }}
                startIcon={<Icon className="fas fa-sharp fa-arrow-left" />}
                disabled={isLoading}
              >
                Convert to XML
              </Button>
            }
          />
        </Box>
      </JuvoDialog>
    )
  )
}
