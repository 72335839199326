import React from 'react'

import { ComponentModel } from '../../../types'
import { isDefined, reorder } from '../../../utils'

export type ReorderContainer = (
  changeOrder: (dragIndex: number, hoverIndex: number) => void,
  acceptType: string,
) => React.ReactElement<HTMLElement>

export const ReorderContainer: React.FC<{
  comp: ComponentModel
  acceptType: string
  changeFn: (c: ComponentModel) => void
  children: ReorderContainer
  overrideChildren?: ComponentModel[]
}> = ({ comp, acceptType, changeFn, children, overrideChildren }) => {
  const changeOrder = (dragIndex: number, hoverIndex: number) => {
    const items = reorder(
      isDefined(overrideChildren) ? overrideChildren : comp.tchildren,
      dragIndex,
      hoverIndex,
    )

    if (isDefined(overrideChildren)) {
      changeFn({
        ...comp,
        components: items,
      })
    } else {
      changeFn({
        ...comp,
        tchildren: items,
      })
    }
  }

  return <>{children(changeOrder, acceptType)}</>
}
