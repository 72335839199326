import React, { useEffect, useMemo, useState } from 'react'
import Button from '@mui/material/Button/Button'
import useTheme from '@mui/material/styles/useTheme'
import { alpha } from '@mui/material/styles'
import AppBar from '@mui/material/AppBar/AppBar'
import Toolbar from '@mui/material/Toolbar/Toolbar'
import Box from '@mui/material/Box/Box'
import Tooltip from '@mui/material/Tooltip/Tooltip'
import Icon from '@mui/material/Icon/Icon'

import { useUIBuilder } from '../../../providers/UIBuilderProvider'
import { JuvoDialog } from '../../JuvoModal/JuvoDialog'
import { xmlToJsonApp } from '../../../network/XmlInterop'
import { AppRegistration, AppSkeleton } from '../../../types'
import { InteropResType } from '../ResponseModal'
import { UIBuilderHeaderButton } from '../HeaderButton/UIBuilderHeaderButton'
import { ComponentAttributeSwitchyard } from '../ComponentAttribute/ComponentAttributeSwitchyard'
import { logErr } from '../../../utils'
import { APP_PROPERTY_WHITELIST, EditableProperty } from '../data/properties'
import { TopLevelTabsInput } from '../ComponentAttribute/TopLevelTabsInput'
import { CodeSection } from '../Conversion/CodeSection'
import { JuvoAlertContainer } from '../../JuvoAlert/JuvoAlertContainer'

import { DrawerWrapper } from './DrawerWrapper'

interface UIBuilderAppDrawerProps {
  appReg: AppRegistration
  updateAppReg: (a: AppRegistration) => void
}

export const UIBuilderAppDrawer: React.FC<UIBuilderAppDrawerProps> = ({
  appReg,
  updateAppReg,
}) => {
  const { updateFunctionMode, setAppReg } = useUIBuilder()
  const theme = useTheme()

  const [appRegState, setAppRegState] = useState(appReg)
  const [error, setError] = useState('')
  const [loadMode, setLoadMode] = useState<InteropResType>('json')
  const [showModal, setShowModal] = useState(false)
  const [loadString, setLoadString] = useState('')

  const labelStyles = {
    color: theme.palette.grey[300],
    fontSize: '0.9em',
  }

  const inputStyles = {
    padding: '6px',
    background: alpha(theme.palette.grey[800], 0.5),
    borderRadius: 0,
    boxSizing: 'border-box',
    width: '100% !important',
    '& *': {
      color: `${theme.palette.grey[200]} !important`,
      border: 'none',
      outline: 'none',
    },
    '.MuiSelect-select': {
      padding: '4px 2px',
    },
  }

  useEffect(() => {
    setAppRegState(appReg)
  }, [appReg])

  const keyVals: [string, EditableProperty][] = useMemo(() => {
    return Object.keys(appRegState)
      .filter(val => {
        return APP_PROPERTY_WHITELIST.has(val)
      })
      .map(k => {
        if (APP_PROPERTY_WHITELIST.has(k)) {
          return [k, APP_PROPERTY_WHITELIST.get(k) ?? { type: 'UNDEFINED' }]
        } else {
          return [k, { type: 'UNDEFINED' }]
        }
      })
  }, [appRegState])

  const applyAppRegChanges = (key: string) => (value: any) => {
    updateAppReg({ ...appRegState, [key]: value })
  }

  const showFromJson = () => {
    setLoadMode('json')
    setLoadString('')
    setShowModal(true)
  }

  const showFromXml = () => {
    setLoadMode('xml')
    setLoadString('')
    setShowModal(true)
  }

  const doLoad = () => {
    if (loadMode === 'json') {
      loadFromJson()
    } else {
      loadFromXml()
    }
    setShowModal(false)
  }

  const loadFromJson = () => {
    try {
      const reg = JSON.parse(loadString)
      setAppReg(reg)
      setShowModal(false)
    } catch (e) {
      logErr(e)
      setLoadString('')
      setShowModal(false)
      setError('Failed to parse JSON')
    }
  }

  const loadFromXml = () => {
    return xmlToJsonApp(loadString)
      .then(res => {
        if (res.type === 'right') {
          updateAppReg(res.content)
        } else {
          logErr(res)
          setError('Failed to parse XML')
        }
      })
      .catch(e => {
        logErr(e)
        setError('Something unexpected happened')
      })
  }

  const closeMeta = () => {
    updateFunctionMode('Default')
  }

  const applyTab = (newSkel: AppSkeleton) => {
    setAppReg({ ...appReg, app_skeleton: newSkel })
  }

  return (
    <DrawerWrapper
      sx={{ position: 'absolute', zIndex: theme => theme.zIndex.drawer + 1 }}
    >
      <>
        {showModal && (
          <JuvoDialog
            title="Load"
            severity="info"
            open={true}
            handleClose={() => setShowModal(false)}
            buttons={
              <>
                <Button onClick={() => setShowModal(false)}>Close</Button>
                <Button variant="contained" onClick={doLoad}>
                  Load
                </Button>
              </>
            }
            sx={{
              '.MuiDialog-paper': {
                width: { xs: '90vw', sm: '75vw', lg: '50vw' },
                borderRadius: 0,
                height: '800px',
                maxWidth: 'unset',
              },
            }}
          >
            {error && (
              <JuvoAlertContainer
                title={error}
                onClose={() => setError('')}
                severity="error"
              />
            )}
            <CodeSection
              label={loadMode === 'json' ? 'JSON' : 'XML'}
              value={loadString}
              setValue={setLoadString}
            />
          </JuvoDialog>
        )}

        <AppBar
          position="absolute"
          sx={{
            background: style => style.palette.grey[800],
            height: {
              xs: 'var(--header-height-mobile)',
              sm: 'var(--header-height)',
            },
            boxSizing: 'border-box',
          }}
        >
          <Toolbar
            sx={{
              flexDirection: 'row',
              minHeight: 'unset !important',
              height: '100%',
              gap: '6px',
              justifyContent: 'flex-end',
              padding: '0 16px !important',
            }}
          >
            <UIBuilderHeaderButton
              icon="fa-xmark"
              action={closeMeta}
              tooltip="Close"
            />
          </Toolbar>
        </AppBar>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
            marginTop: {
              xs: 'var(--header-height-mobile)',
              sm: 'var(--header-height)',
            },
            '.fas': {
              fontSize: '1em',
            },
          }}
        >
          <Tooltip arrow title="Initialize from XML">
            <Button
              variant="contained"
              onClick={showFromXml}
              startIcon={<Icon className="fas fa-sharp fa-upload" />}
            >
              XML
            </Button>
          </Tooltip>
          <Tooltip arrow title="Initialize from JSON">
            <Button
              variant="contained"
              onClick={showFromJson}
              startIcon={<Icon className="fas fa-sharp fa-upload" />}
            >
              JSON
            </Button>
          </Tooltip>
          {keyVals.map((pair, index) => {
            const commonProps = {
              attrKey: pair[0],
              attrVal: (appRegState as any)[pair[0]],
              ...(pair[1].multiline && { multiline: true, minRows: 3 }),
            }
            return (
              <ComponentAttributeSwitchyard
                key={index}
                applyVal={applyAppRegChanges(pair[0])}
                valType={pair[1]}
                inputStyles={inputStyles}
                labelStyles={labelStyles}
                {...commonProps}
              />
            )
          })}
          <TopLevelTabsInput
            applyVal={applyTab}
            inputStyles={inputStyles}
            labelStyles={labelStyles}
            appSkel={appReg.app_skeleton}
          />
        </Box>
        <Button
          variant="contained"
          onClick={closeMeta}
          sx={{
            marginTop: '8px',
            width: '100%',
          }}
        >
          Done
        </Button>
      </>
    </DrawerWrapper>
  )
}
