import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns as DateAdapter } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import { CombineProviders, provider } from '../utils/CombineProviders'

import GuidProvider from './GuidProvider'
import SnackbarProvider from './SnackbarProvider'
import UploadingProvider from './UploadingProvider'
import FavoritesProvider from './FavoritesProvider'

export const Providers = ({ children }: { children: React.ReactNode }) =>
  CombineProviders({
    providers: [
      provider(SnackbarProvider),
      provider(GuidProvider),
      provider(DndProvider, { backend: HTML5Backend }),
      provider(LocalizationProvider, { dateAdapter: DateAdapter }),
      provider(UploadingProvider),
      provider(FavoritesProvider),
    ],
    children,
  })
