import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import CopyToClipboard from 'react-copy-to-clipboard'
import { IconButton, Tooltip } from '@mui/material'

import { JuvoCodeMirror } from '../../JuvoCodeMirror/JuvoCodeMirror'
import { useCopy } from '../../../hooks/useCopy'

export const CodeSection = ({
  label,
  value,
  setValue,
  convertButton,
}: {
  label: string
  value: string
  setValue: (v: string) => void
  convertButton?: React.ReactNode
}) => {
  const [isCopied, copyHandler] = useCopy()
  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'hidden',
      }}
    >
      <Box
        sx={{
          background: theme => theme.palette.grey[100],
          borderBottom: theme => `1px solid ${theme.palette.grey[300]}`,
          padding: '6px 10px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          overflowY: 'hidden',
          overflowX: 'hidden',
        }}
      >
        <Typography
          variant="h3"
          sx={{
            fontSize: '1.1em',
          }}
        >
          {label}
        </Typography>
        <Box>
          <Tooltip title="Delete">
            <IconButton
              onClick={() => setValue('')}
              className="fas fa-trash fa-sharp"
              sx={{
                borderRadius: '0',
                fontSize: '1em',
              }}
            />
          </Tooltip>
          <CopyToClipboard text={value} onCopy={copyHandler}>
            <Tooltip title="Copy">
              <IconButton
                disabled={isCopied}
                className="fas fa-copy fa-sharp"
                sx={{
                  borderRadius: '0',
                  fontSize: '1em',
                }}
              />
            </Tooltip>
          </CopyToClipboard>
        </Box>
      </Box>

      <Box
        sx={{
          overflowY: 'auto',
          overflowX: 'auto',
          height: '100%',
          '.cm-editor, & > div': {
            height: '100%',
          },
        }}
      >
        <JuvoCodeMirror value={value} onChange={setValue} />
      </Box>

      {convertButton && (
        <Box
          sx={{
            marginTop: '16px',
            gap: '8px',
            justifyContent: 'flex-end',
            display: 'flex',
          }}
        >
          {convertButton}
        </Box>
      )}
    </Box>
  )
}
