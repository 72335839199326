import React from 'react'
import InputLabel from '@mui/material/InputLabel/InputLabel'
import Box from '@mui/material/Box/Box'
import List from '@mui/material/List/List'
import ListItem from '@mui/material/ListItem/ListItem'
import Button from '@mui/material/Button/Button'
import Icon from '@mui/material/Icon/Icon'
import { IconButton, SxProps, Typography } from '@mui/material'

import { ComponentModel } from '../../../types'
import { useUIBuilder } from '../../../providers/UIBuilderProvider'

interface CarouselAttributeInputProps {
  labelStyles: SxProps
  inputStyles: SxProps
  applyVal: (comp: ComponentModel) => void
  comp: ComponentModel
}

export const CarouselAttributeInput: React.FC<CarouselAttributeInputProps> = ({
  applyVal,
  labelStyles,
  inputStyles,
  comp,
}) => {
  const { genIdsWithChildren } = useUIBuilder()

  const addCarouselPage = () => {
    if (!comp.emptycomponent) {
      return
    }
    const newPage = genIdsWithChildren({ ...comp.emptycomponent })
    const newComp = {
      ...comp,
      tchildren: comp.tchildren.concat([newPage]),
    }
    applyVal(newComp)
  }

  const deleteCarouselPage = (index: number) => {
    if (comp.tchildren.length <= 2) {
      return
    }
    const newValues = [...comp.tchildren].toSpliced(index, 1)
    const newPos = comp.position === index ? index - 1 : comp.position
    const newComp = {
      ...comp,
      tchildren: newValues,
      position: newPos,
      design: { ...comp.design },
    }
    applyVal(newComp)
  }

  return (
    <Box>
      <InputLabel sx={labelStyles}>Pages</InputLabel>
      <List
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '4px',
        }}
      >
        {comp.tchildren.map((_c: ComponentModel, index: number) => (
          <ListItem
            key={index}
            sx={{
              padding: 0,
              position: 'relative',
            }}
          >
            <Typography
              sx={{
                color: theme => theme.palette.common.white,
                ...inputStyles,
              }}
            >{`Page ${index + 1}`}</Typography>
            <IconButton
              className="fas fa-sharp fa-trash"
              onClick={() => deleteCarouselPage(index)}
              sx={{
                position: 'absolute',
                fontSize: '0.8em',
                color: theme => theme.palette.tertiary.main,
                top: '50%',
                right: '6px',
                transform: 'translateY(-50%)',
              }}
            />
          </ListItem>
        ))}
      </List>
      <Button
        variant="outlined"
        color="secondary"
        sx={{
          width: '100%',
        }}
        onClick={addCarouselPage}
        startIcon={<Icon className="fas fa-sharp fa-plus" />}
      >
        Carousel Page
      </Button>
    </Box>
  )
}
