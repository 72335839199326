import React from 'react'
import TabList from '@mui/lab/TabList'
import Tab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext'

import { TabComponent } from '../../types'
import { createSlug, isDefined } from '../../utils'

import MakeTabs from './MakeTabs'
import TabPanel from './TabPanel'

const Tabs: React.FC<{
  data: TabComponent[]
  renderTab: (tab: TabComponent) => JSX.Element
  currentTabUpdate?: (tab: string) => void
}> = ({ data, renderTab, currentTabUpdate }) => {
  const tabChange = (
    newValue: string,
    setCurrentTab: (newTab: string) => void,
  ) => {
    if (isDefined(currentTabUpdate)) {
      currentTabUpdate(data[parseInt(newValue)].tab.id.toString())
    }
    setCurrentTab(newValue)
  }

  return (
    <MakeTabs defaultTab="0">
      {({ currentTab, setCurrentTab }) => (
        <TabContext value={currentTab}>
          <TabList
            variant="scrollable"
            onChange={(_: any, newValue: string) =>
              tabChange(newValue, setCurrentTab)
            }
            aria-label="tabs"
            // top is negative because of the app-content style, we want to avoid the gap from the tabs and panels
            // values match the responsive design of app-content
            // zIndex is one less than the backdrop
            sx={{
              position: 'sticky',
              top: { xs: '-28px' },
              zIndex: theme => theme.zIndex.appBar,
              backgroundColor: theme => theme.palette.common.white,
              '.MuiTabs-scrollButtons': {
                display: { xs: 'flex' },
                width: '20px',
              },
            }}
          >
            {data.map(({ tab }, index) => (
              <Tab
                label={tab.tab_name}
                value={`${index}`}
                key={index}
                sx={{
                  padding: {
                    sm: '0px 4px !important',
                    md: '12px 48px !important',
                  },
                  '&.selected': {
                    color: theme => theme.palette.primary.main,
                  },
                }}
                className="juvo-tab"
                data-testid={`tab-${createSlug(tab.tab_name)}`}
              />
            ))}
          </TabList>
          {data.map((tab, index) => (
            <TabPanel
              value={`${index}`}
              key={index}
              sx={{ padding: '1rem 0' }}
              className="tab-container"
            >
              {renderTab(tab)}
            </TabPanel>
          ))}
        </TabContext>
      )}
    </MakeTabs>
  )
}

export default Tabs
