import Box from '@mui/material/Box/Box'
import TextField from '@mui/material/TextField/TextField'
import React, { useState } from 'react'

interface ModalTextInputProps {
  val: string
  onChange: (v: string) => void
}

export const ModalTextInput: React.FC<ModalTextInputProps> = ({
  val,
  onChange,
}) => {
  const [localValue, setLocalValue] = useState(val)

  return (
    <Box>
      <TextField
        sx={{ width: '100%' }}
        value={localValue}
        onChange={event => setLocalValue(event.target.value)}
        onBlur={() => onChange(localValue)}
      />
    </Box>
  )
}
