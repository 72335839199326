import React from 'react'
import MenuItem from '@mui/material/MenuItem/MenuItem'
import Menu from '@mui/material/Menu/Menu'

import {
  UIBuilderHeaderButton,
  UIBuilderHeaderVariant,
} from './UIBuilderHeaderButton'

export type HeaderMenuItem = {
  label: string
  action: () => void
}

interface UIBuilderHeaderMenuProps {
  icon: string
  items: HeaderMenuItem[]
  disabled?: boolean
  variant?: UIBuilderHeaderVariant
}

export const UIBuilderHeaderMenu: React.FC<UIBuilderHeaderMenuProps> = ({
  icon,
  items,
  disabled,
  variant,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const closeAndAction = (action: () => void) => {
    handleClose()
    action()
  }
  return (
    <>
      <UIBuilderHeaderButton
        action={e => handleClick(e)}
        icon={icon}
        disabled={disabled}
        variant={variant}
      />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          '.MuiPopover-paper': {
            borderRadius: 0,
          },
        }}
        MenuListProps={{
          sx: {
            background: style => style.palette.grey[600],
            borderRadius: 0,
            li: {
              color: style => style.palette.common.white,
              fontWeight: 600,
              fontSize: '0.8em',
              '&:hover': {
                background: style => style.palette.grey[700],
              },
            },
          },
        }}
      >
        {items.map((i, key) => (
          <MenuItem key={key} onClick={() => closeAndAction(i.action)}>
            {i.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
