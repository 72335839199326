import React, { useEffect, useState } from 'react'
import InputLabel from '@mui/material/InputLabel/InputLabel'
import Box from '@mui/material/Box/Box'
import InputBase from '@mui/material/InputBase/InputBase'
import Tooltip from '@mui/material/Tooltip/Tooltip'
import IconButton from '@mui/material/IconButton/IconButton'
import List from '@mui/material/List/List'
import ListItem from '@mui/material/ListItem/ListItem'
import Button from '@mui/material/Button/Button'
import Icon from '@mui/material/Icon/Icon'
import { SxProps } from '@mui/material'

import { ComponentModel } from '../../../types'
import { genId } from '../../../utils'

interface TabsAttributeInputProps {
  inputStyles: SxProps
  labelStyles: SxProps
  attrKey: string
  applyVal: (comp: ComponentModel) => void
  attrVal: string[]
  comp: ComponentModel
}

export const TabNameInput: React.FC<{
  value: string
  inputStyles: any
  updateTab: (v: string) => void
  deleteTab: () => void
}> = ({ value, inputStyles, updateTab, deleteTab }) => {
  const [localVal, setLocalVal] = useState('')

  useEffect(() => {
    setLocalVal(value)
  }, [value])

  return (
    <InputBase
      onChange={event => {
        setLocalVal(event.target.value)
      }}
      onBlur={() => {
        updateTab(localVal)
      }}
      value={localVal}
      endAdornment={
        <Tooltip title="Delete tab" arrow>
          <IconButton
            onClick={deleteTab}
            sx={{
              fontSize: '0.8em',
              color: theme => `${theme.palette.tertiary.main}!important`,
            }}
            className="fas fa-trash"
          />
        </Tooltip>
      }
      sx={inputStyles}
    />
  )
}

export const TabsAttributeInput: React.FC<TabsAttributeInputProps> = ({
  attrKey,
  applyVal,
  labelStyles,
  inputStyles,
  attrVal,
  comp,
}) => {
  const [listedValues, setListedValues] = useState<string[]>([])

  const updateTab = (val: string, index: number) => {
    const oldValue = listedValues[index]
    const newValues = [...listedValues]
    newValues[index] = val
    const newComp = {
      ...comp,
      options: newValues,
      design: { ...comp.design, options_binding: newValues },
    }
    newComp.tchildren.map((child: ComponentModel) => {
      if (child.when === oldValue) {
        child.when = val
        child.design.jf_when = val
      }
    })
    if (newComp.value === oldValue) {
      newComp.value = val
    }
    applyVal(newComp)
  }

  const deleteTab = (index: number) => {
    if (listedValues.length === 1) {
      return
    }
    const oldValue = listedValues[index]
    const newValues = [...listedValues].toSpliced(index, 1)
    const newComp = {
      ...comp,
      options: newValues,
      design: { ...comp.design, options_binding: newValues },
    }
    const newChildren = newComp.tchildren.filter((child: ComponentModel) => {
      return child.when !== oldValue
    })
    newComp.tchildren = newChildren
    if (newComp.value === oldValue) {
      newComp.value = newComp.options[0]
    }
    applyVal(newComp)
  }

  const addTab = () => {
    const tabName = `Tab-${listedValues.length + 1}`
    const newValues = [...listedValues].concat([tabName])
    const newComp = {
      ...comp,
      options: newValues,
      design: { ...comp.design, options_binding: newValues },
    }
    const newChildren = newComp.tchildren.concat({
      design: {
        inclusive: null,
        jf_for: null,
        jf_when: tabName,
      },
      indent: 0,
      tchildren: [],
      type: 'folder',
      when: tabName,
      uiBuilderId: genId(),
    })
    newComp.tchildren = newChildren
    applyVal(newComp)
  }

  useEffect(() => {
    setListedValues(attrVal)
  }, [attrVal])

  return (
    <Box>
      <InputLabel sx={labelStyles}>{attrKey}</InputLabel>
      <List
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '4px',
        }}
      >
        {listedValues.map((v, index) => (
          <ListItem
            key={index}
            sx={{
              padding: 0,
            }}
          >
            <TabNameInput
              inputStyles={inputStyles}
              value={v}
              updateTab={v => updateTab(v, index)}
              deleteTab={() => deleteTab(index)}
            />
          </ListItem>
        ))}
      </List>
      <Button
        sx={{
          width: '100%',
        }}
        onClick={addTab}
        variant="outlined"
        color="secondary"
        startIcon={<Icon className="fa fa-plus" />}
      >
        Tab
      </Button>
    </Box>
  )
}
