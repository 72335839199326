import React from 'react'
import IconButton from '@mui/material/IconButton'
import clsx from 'clsx'
import { NavLink } from 'react-router-dom'
import { Box, Typography, useTheme } from '@mui/material'

import AddletList from '../AddletList/AddletList'
import { AppItem } from '../../types'
import { useFavorites } from '../../providers/FavoritesProvider'
import { getJuvoConfig } from '../../utils'
import { ENVIRONMENT } from '../../utils/Environment'

import logo from './assets/juvo-logo.svg'
import { AnimatedHamburger } from './AnimatedHamburger.tsx/AnimatedHamburger'
import { SpecialDrawerButton } from './SpecialDrawerButton'

export const sortAlphaFavoritesFirst = (
  apps: AppItem[],
  favorites: string[],
) => {
  const alphaSortedApps = apps.sort((a: AppItem, b: AppItem) => {
    const nameA = a.app_name.toUpperCase()
    const nameB = b.app_name.toUpperCase()
    if (nameA < nameB) return -1
    else if (nameA > nameB) return 1
    else return 0
  })
  const alphaFavorites = alphaSortedApps.filter(item =>
    favorites.includes(item.app_id),
  )
  const otherApps = alphaSortedApps.filter(
    item => !favorites.includes(item.app_id),
  )
  return { faves: alphaFavorites, others: otherApps }
}

const Drawer: React.FC<{
  appList: AppItem[]
  open: boolean
  onOpen: () => void
  onClose: () => void
}> = ({ appList, open, onOpen, onClose }) => {
  const { platformHttpUrl, environment } = getJuvoConfig()
  const isNotProduction = environment !== ENVIRONMENT.production
  const theme = useTheme()
  const { favorites } = useFavorites()
  const apps = sortAlphaFavoritesFirst(appList, favorites)
  const orderAppList = apps.others
  const faves = apps.faves

  const hrefTerminateSession = `${platformHttpUrl}/terminate-session`

  return (
    <>
      <Box
        role="presentation"
        className={clsx('juvo-drawer', open && 'juvo-drawer-open')}
        data-testid="app-drawer"
        sx={{
          position: 'absolute',
          left: 0,
          top: 0,
          bottom: 0,
          overflowX: 'visible',
          height: '100%',
          zIndex: theme => theme.zIndex.drawer,
          transform: 'translateX(-100%)',
          transition: 'transform 300ms ease-in-out',
          '&.juvo-drawer-open': {
            transform: 'translateX(0) !important',
          },
        }}
      >
        <IconButton
          onClick={open ? onClose : onOpen}
          size="small"
          sx={{
            zIndex: 10,
            color: style => style.palette.grey[100],
            position: 'absolute',
            top: 0,
            right: {
              xs: open ? '0' : 'calc(var(--header-height-mobile) * -1)',
              sm: 'calc(var(--header-height) * -1)',
            },
            borderRadius: 0,
            width: {
              xs: 'var(--header-height-mobile)',
              sm: 'var(--header-height)',
            },
            height: {
              xs: 'var(--header-height-mobile)',
              sm: 'var(--header-height)',
            },
            background: style => style.palette.tertiary.main,
            '&:hover': {
              background: style => style.palette.tertiary.dark,
            },
          }}
          data-testid="hamburguer-menu-button"
        >
          <AnimatedHamburger active={open} />
        </IconButton>
        <Box
          sx={{
            width: { xs: '100%', sm: 'var(--drawer-width)' },
            overflowY: 'auto !important',
            height: '100%',
            backgroundColor: theme => theme.palette.primary.dark,
          }}
        >
          <Box
            className="drawer-headline"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: { xs: 'var(--header-height)', sm: 'auto' },
              margin: { xs: '12px 0', sm: '42px 0' },
              img: {
                width: { xs: '110px', sm: '150px' },
              },
            }}
          >
            <NavLink to="/home" onClick={onClose}>
              <img src={logo} alt="juvo" />
            </NavLink>
          </Box>
          {faves.length > 0 && (
            <Box>
              <Typography
                variant="subtitle2"
                id="favorites-title"
                sx={{
                  margin: { xs: '0 0 0 12px', sm: '0 0 0 20px' },
                  color: theme => theme.palette.primary.light,
                }}
              >
                Favorites ({faves.length})
              </Typography>
              <AddletList appList={faves} closeFn={onClose} open={open} />
            </Box>
          )}

          <Box
            sx={{
              marginTop: '32px',
            }}
          >
            <Typography
              variant="subtitle2"
              id="all-apps-title"
              sx={{
                margin: { xs: '0 0 0 12px', sm: '0 0 0 20px' },
                color: theme => theme.palette.primary.light,
              }}
            >
              All apps ({orderAppList.length})
            </Typography>
            <AddletList appList={orderAppList} closeFn={onClose} open={open} />
          </Box>
          <Box
            sx={{
              textAlign: 'center',
              display: 'flex',
              flexDirection: 'column',
              background: theme => theme.palette.primary.dark,
              padding: {
                xs: '12px 24px 12px 10px !important',
                sm: '12px 24px !important',
              },
            }}
          >
            {isNotProduction && (
              <SpecialDrawerButton
                href="/ui-builder"
                text="UI Builder"
                icon="fa-hammer"
              />
            )}
            <SpecialDrawerButton
              href={hrefTerminateSession}
              text="Refresh Session"
              icon="fa-arrows-rotate"
            />
          </Box>
        </Box>
      </Box>
      <Box
        className={clsx(
          'juvo-drawer-overlay',
          open && 'juvo-drawer-overlay-open',
        )}
        sx={{
          display: 'none',
          [theme.breakpoints.up('xs')]: {
            position: 'absolute',
            top: 0,
            bottom: 0,
            right: 0,
            // to avoid losing the functionality on small screens, left: 200px and z-index are set
            left: '200px',
            zIndex: theme => theme.zIndex.drawer - 1,
            '&.juvo-drawer-overlay-open': {
              display: 'block',
            },
          },
        }}
        onClick={onClose}
      />
    </>
  )
}

export default Drawer
