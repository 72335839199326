import React, { useState, useEffect } from 'react'
import MuiStepper from '@mui/material/Stepper'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material'

import { containerSetChild, getJuvoInfo } from '../../../store'
import {
  adjustStdComponentArgs,
  isStepFinalModel,
  isStepModel,
  StdComponentArgs,
  StepFinalModel,
  StepModel,
  StepperModel,
} from '../../../types'
import { isDefined, Nullable, scrollAppContentToTop } from '../../../utils'
import {
  validateComponentRecursive,
  ValidationError,
} from '../../../store/Validation'

import StepContent from './StepContent'
import { generateStyledSteps } from './generateStyledSteps'

const Stepper: React.FC<{
  stdCompArgs: StdComponentArgs<StepperModel>
  validationState: {
    validationErrs: ValidationError[]
    setValidationErrs: (_: ValidationError[]) => void
  }
}> = ({ stdCompArgs, validationState }) => {
  const props = stdCompArgs
  const { comp, onComponentChange } = stdCompArgs
  const { validationErrs, setValidationErrs } = validationState
  const [propagateValidation, setPropagateValidation] = useState(false)

  const theme = useTheme()

  const stepmodels: StepModel[] = (comp.tchildren || []).filter(isStepModel)
  const finalstepmodel: Nullable<StepFinalModel> = (comp.tchildren || []).find(
    isStepFinalModel,
  )
  const finalstepPosition = stepmodels.length
  const performValidation = comp.perform_validation || false

  //active step is the value of the stepper
  //future code will do comp.value || comp.recommendation || 0
  //to enable server based stepping
  const activeStepIdx = comp.value || 0

  const setActiveStep = (n: number): void => {
    onComponentChange({ ...comp, value: n })
  }

  const activeStep =
    activeStepIdx < stepmodels.length ? stepmodels[activeStepIdx] : null

  const isFinalStep = isDefined(finalstepmodel)
    ? activeStep === null
    : activeStepIdx === stepmodels.length - 1

  useEffect(() => {
    scrollAppContentToTop()
  }, [activeStepIdx])

  const activeStepProps = adjustStdComponentArgs(
    props,
    activeStep,
    nc => onComponentChange(containerSetChild(nc, activeStepIdx, comp)),
    activeStepIdx,
  )

  const finalStepProps = adjustStdComponentArgs(
    props,
    finalstepmodel,
    nc => onComponentChange(containerSetChild(nc, finalstepPosition, comp)),
    finalstepPosition,
  )

  const stepProps =
    isFinalStep && isDefined(finalstepmodel)
      ? finalStepProps
      : {
          propagateValidation: propagateValidation,
          ...activeStepProps,
        }

  const myKey =
    isFinalStep && isDefined(finalstepmodel) ? finalstepPosition : activeStepIdx

  const handleNext = () => {
    const valErrs: ValidationError[] =
      isDefined(activeStep) && performValidation
        ? validateComponentRecursive(activeStep)
        : []
    if (valErrs.length > 0) setValidationErrs(valErrs)
    else {
      setValidationErrs([])
      setActiveStep(activeStepIdx + 1)
    }
    setPropagateValidation(true)
  }

  const handleBack = () => {
    setActiveStep(activeStepIdx - 1)
    if (isFinalStep && isDefined(finalstepmodel)) {
      setPropagateValidation(false)
    }
  }

  const handleOnBlur = () => {
    if (validationErrs.length === 0) {
      setValidationErrs([])
    }
  }

  if (stepmodels.length <= 0) {
    return (
      <Typography variant="h2" {...getJuvoInfo('StepperComponent', comp)}>
        Developer Error, Invalid stepper model
      </Typography>
    )
  }

  return (
    <Box
      {...getJuvoInfo('StepperComponent', comp)}
      sx={{
        '.MuiStepper-root': {
          padding: '16px 8px',
        },
      }}
    >
      <MuiStepper
        activeStep={activeStepIdx}
        sx={{
          border: `1px solid ${theme.palette.primary.dark}`,
          background: theme.palette.primary.dark,
          '.MuiStepConnector-horizontal span': {
            borderWidth: '2px',
          },
          '.Mui-active span, .Mui-completed span': {
            borderColor: theme.palette.common.white,
          },
        }}
      >
        {/* The following has to be done with a method rather than a component, otherwise it adds an extra connector element. 
        See https://github.com/mui/material-ui/issues/38871 */}
        {generateStyledSteps(activeStepIdx, stepmodels, theme)}
      </MuiStepper>

      <StepContent {...stepProps} key={myKey} />
      {validationErrs.length > 0 && (
        <Typography
          variant="body1"
          sx={{
            display: 'flex',
            justifyContent: 'end',
            color: theme.palette.error.main,
            margin: '16px 0px',
          }}
        >
          You have not filled all the required inputs. Please check the form and
          try again.
        </Typography>
      )}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          background: theme.palette.grey[100],
          border: '1px solid var(--color-border)',
          borderTop: 'none',
          padding: '0.5em 1em',
        }}
      >
        <Button
          color="inherit"
          disabled={activeStepIdx === 0}
          onClick={handleBack}
          data-testid={`btn-back-${activeStepIdx}`}
        >
          Back
        </Button>
        {!isFinalStep && (
          <Button
            onClick={handleNext}
            onBlur={handleOnBlur}
            data-testid={`btn-next-${activeStepIdx}`}
          >
            {activeStepIdx === comp.tchildren.length - 1 ? 'Finish' : 'Next'}
          </Button>
        )}
      </Box>
    </Box>
  )
}

export default Stepper
