import React, { useState } from 'react'
import { format } from 'date-fns'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import InputLabel from '@mui/material/InputLabel'
import FormHelperText from '@mui/material/FormHelperText'
import CalendarToday from '@mui/icons-material/CalendarTodayOutlined'
import { useTheme } from '@mui/system'
import clsx from 'clsx'
import Box from '@mui/material/Box'

import { Command, DateModel } from '../../../types'
import { setComponentValue } from '../../../store'
import {
  getJuvoProps,
  sanitizeInputName,
  ValidationProps,
  logWarn,
} from '../../../utils'
import { useOnSmallScreen } from '../../../hooks/useOnSmallScreen'

const DateField: React.FC<{
  comp: DateModel
  onCommand: (cmd: Command) => void
  onChange: (_: DateModel) => void
  validationProps: ValidationProps
}> = ({ comp, onCommand, onChange, validationProps }) => {
  const theme = useTheme()
  const onSmallScreen = useOnSmallScreen()
  const [isFocused, setFocus] = useState(false)

  const handleChange = (date: Date) => {
    try {
      onChange(setComponentValue(comp)(format(date, 'yyyy-MM-dd')))
    } catch (error) {
      logWarn('Invalid date value', error)
    }
  }

  const label = comp.text || ''
  const { eventHandlers, className, value, juvoInfo } = getJuvoProps<any, any>(
    'DateField',
    comp,
    onCommand,
    handleChange,
  )
  const {
    onChange: onChangeFromServer,
    onBlur: onBlurFromServer,
    onFocus: onFocusFromServer,
    ...handlersFromServer
  } = eventHandlers
  const { handleVisited, hasErrors, errorMessage } = validationProps
  const inputName = sanitizeInputName(label)

  return (
    <Box
      className={clsx('juvo-date-field', isFocused && 'date-field-focus')}
      sx={{
        '&.date-field-focus .MuiInputBase-formControl': {
          outline: theme =>
            `2px solid ${theme.palette.primary.main} !important`,
        },
        '.MuiInputBase-formControl': {
          outline: theme => `1px solid ${theme.palette.grey[400]}`,
          '&:hover': {
            outline: theme => `2px solid ${theme.palette.grey[500]}`,
          },

          '.MuiInputBase-input': {
            padding: '10px 12px !important',
          },

          '.MuiOutlinedInput-notchedOutline': {
            border: '0 !important',
          },
        },
        marginBottom: '8px',
      }}
    >
      <InputLabel
        error={hasErrors}
        shrink
        htmlFor={inputName}
        className="juvo-input-label"
        data-testid={comp.data_testid}
      >
        {label}
      </InputLabel>
      <DesktopDatePicker
        {...juvoInfo}
        {...handlersFromServer}
        value={value ? new Date(`${value}T00:00:00`) : null}
        onChange={event => {
          onChangeFromServer(event)
          handleVisited()
        }}
        slots={{
          openPickerIcon: CalendarToday,
        }}
        slotProps={{
          openPickerButton: {
            style: {
              fontSize: '1.25rem',
              color: `${theme.palette.primary.main}`,
            },
          },
          textField: {
            onFocus: () => {
              onFocusFromServer && onFocusFromServer()
              setFocus(true)
            },
            onBlur: () => {
              onBlurFromServer && onBlurFromServer()
              setFocus(false)
              handleVisited()
            },
            className: className,
            sx: { minWidth: onSmallScreen ? '100%' : 'auto' },
          },
        }}
      />
      <FormHelperText
        error={hasErrors}
        sx={{ visibility: hasErrors ? 'visible' : 'hidden' }}
        data-testid={`date-helptext-${comp.data_testid}`}
      >
        {errorMessage || ' '}
      </FormHelperText>
    </Box>
  )
}

export default DateField
