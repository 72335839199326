import React from 'react'
import { useLocation } from 'react-router-dom'

import { ComponentModel, isWhenModel, StdComponentArgs } from '../../../types'
import When from '../../juvo-container/When/When'
import { isDefined, isUndefined } from '../../../utils'
import { OptionsWrapper } from '../../../containers/UIBuilder/OptionsWrapper'

import SwitchYardNoWhen from './SwitchYardNoWhen'

const SwitchYard: React.FC<StdComponentArgs<ComponentModel>> = props => {
  const comp = props.comp
  const uiBuilderProps = props.uiBuilderProps
  const loc = useLocation()
  const useBuilder = loc.pathname === '/ui-builder'

  const subYard = (reorderProps?: {
    changeOrder: (dragIndex: number, hoverIndex: number) => void
    acceptType: string
  }) => {
    const newBuilderProps = {
      uiBuilderProps: {
        ...uiBuilderProps,
        acceptType: reorderProps?.acceptType,
        changeOrder: reorderProps?.changeOrder,
      },
    }
    return isWhenModel(comp) ? (
      <div className="when-combo">
        {/* The top component defines the when condition in model value */}
        <SwitchYardNoWhen {...props} {...newBuilderProps} />
        <When {...props} {...reorderProps} />
      </div>
    ) : (
      <SwitchYardNoWhen {...props} {...newBuilderProps} />
    )
  }

  if (isUndefined(comp)) {
    return <h2>Developer error, null model in switchyard</h2>
  }

  if (useBuilder && isDefined(uiBuilderProps)) {
    return (
      <OptionsWrapper
        myComp={comp}
        key={uiBuilderProps.index}
        isDroppable={isDefined(comp.tchildren)}
        addToChildComps={uiBuilderProps.addToChildComps ?? (() => null)}
        index={uiBuilderProps.index ?? 0}
        changeFn={props.onComponentChange}
        moveItem={
          isDefined(uiBuilderProps.changeOrder)
            ? uiBuilderProps.changeOrder
            : () => null
        }
        acceptType={uiBuilderProps.acceptType ?? ''}
      >
        {(changeOrder, at) =>
          subYard({
            changeOrder: changeOrder,
            acceptType: at,
          })
        }
      </OptionsWrapper>
    )
  } else {
    return subYard()
  }
}

export default SwitchYard
