import React, { useMemo } from 'react'
import { useDrag } from 'react-dnd/dist/hooks/useDrag/useDrag'
import Box from '@mui/material/Box/Box'
import IconButton from '@mui/material/IconButton/IconButton'
import Typography from '@mui/material/Typography/Typography'
import Menu from '@mui/material/Menu/Menu'
import MenuItem from '@mui/material/MenuItem/MenuItem'
import Icon from '@mui/material/Icon/Icon'

import { ComponentModel, JuvoDroppableType } from '../../../types'
import { isDefined } from '../../../utils/Undefined'
import { getNumChildren } from '../../../store/State'
import { ComponentToJsonXml } from '../ComponentToJsonXml'
import { COMP_IMAGE_MAP } from '../data/commonComponents'
import custom from '../icons/custom.svg'

const CustomComponentInfo = ({
  title,
  value,
}: {
  title: string
  value: string
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <Typography
        variant="caption"
        sx={{
          color: theme => theme.palette.common.white,
        }}
      >
        {title}:
      </Typography>
      <Typography
        variant="caption"
        sx={{
          fontWeight: 400,
          color: theme => theme.palette.grey[300],
        }}
      >
        {value}
      </Typography>
    </Box>
  )
}

export const CustomDrawerComponent = ({
  compModel,
  name,
  onClickToAdd,
  onEditName,
  deleteComponent,
}: {
  compModel: ComponentModel
  name: string
  onClickToAdd: () => void
  onEditName: () => void
  deleteComponent: () => void
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const [, drag] = useDrag(
    () => ({
      type: JuvoDroppableType.BUILDER_ITEM,
      item: { comp: compModel, init: true },
      collect: monitor => ({
        opacity: monitor.isDragging() ? 0.4 : 1,
      }),
    }),
    [name, JuvoDroppableType.BUILDER_ITEM],
  )

  const closeOnClick = (action: () => void) => {
    action()
    handleClose()
  }

  const topLevelType = compModel.type
  const children = useMemo(() => {
    return isDefined(compModel.tchildren)
      ? getNumChildren(compModel.tchildren)
      : 0
  }, [compModel.tchildren])

  const image = COMP_IMAGE_MAP.get(compModel.type) ?? custom

  return (
    <ComponentToJsonXml myComp={compModel}>
      {(compToJson, compToXml, isLoading) => (
        <Box
          ref={drag}
          sx={{
            display: 'flex',
            padding: '0 16px',
            cursor: 'grab',
            flexDirection: 'column',
            alignItems: 'center',
            position: 'relative',
            border: '1px solid transparent',
            '&:hover': {
              border: theme => `1px solid ${theme.palette.primary.light}`,
            },
            background: '#362e3a',
          }}
        >
          <IconButton
            className="fas fa-ellipsis-vertical fa-sharp"
            onClick={handleClick}
            sx={{
              position: 'absolute',
              borderRadius: '100%',
              width: '28px',
              height: '28px',
              fontSize: '1.1em',
              color: theme => theme.palette.grey[300],
              top: '4px',
              right: '4px',
              '&:hover': {
                color: theme => theme.palette.common.white,
              },
            }}
          />
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            sx={{
              '.MuiPopover-paper': {
                borderRadius: 0,
              },
            }}
            MenuListProps={{
              sx: {
                background: style => style.palette.grey[600],
                borderRadius: 0,
                li: {
                  color: style => style.palette.common.white,
                  fontWeight: 600,
                  fontSize: '0.8em',
                  '&:hover': {
                    background: style => style.palette.grey[700],
                  },
                },
              },
            }}
          >
            <MenuItem onClick={() => closeOnClick(onEditName)}>
              Edit name
            </MenuItem>
            <MenuItem onClick={() => closeOnClick(compToJson)}>
              Get JSON
            </MenuItem>
            <MenuItem onClick={() => closeOnClick(compToXml)}>
              {isLoading ? (
                <Icon className="fas fa-sharp fa-spinner fa-spin" />
              ) : (
                'Get XML'
              )}
            </MenuItem>
            <MenuItem onClick={() => closeOnClick(deleteComponent)}>
              Delete
            </MenuItem>
          </Menu>

          <Box
            sx={{
              padding: '6px',
              boxSizing: 'border-box',
              display: 'flex',
              flexDirection: 'column',
              textAlign: 'center',
              width: '92px',
              img: {
                border: '1px solid transparent',
                width: '100%',
                transition: 'border 0.2s ease',
              },
            }}
            onClick={onClickToAdd}
          >
            <img src={image} />
            <Typography
              variant="caption"
              sx={{
                textTransform: 'capitalize',
                color: theme => theme.palette.common.white,
              }}
            >
              {name}
            </Typography>
          </Box>
          <Box
            sx={{
              borderTop: '1px solid var(--color-border)',
              display: 'flex',
              flexDirection: 'column',
              padding: '8px 0',
              width: '100%',
            }}
          >
            <CustomComponentInfo title="Parent" value={topLevelType} />
            <CustomComponentInfo title="Children" value={children.toString()} />
          </Box>
        </Box>
      )}
    </ComponentToJsonXml>
  )
}
