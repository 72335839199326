import React, { ReactNode, SetStateAction, useEffect, useState } from 'react'
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom'
import { match } from 'ts-pattern'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'

import Header from '../Header/Header'
import Addlet from '../Addlet/Addlet'
import Test from '../Test/Test'
import Critical from '../../components/Critical/Critical'
import Drawer from '../../components/Drawer/Drawer'
import {
  bootstrapRes,
  clearState,
  CmdHandler,
  CustomComponentHandler,
  dismissGlobalWarn,
  emptyState,
  getGlobalWarn,
  LoginHandler,
  State,
  stateDebug,
  stateOverApp,
  stateSetComponent,
  stateSetSkeleton,
  updateState,
} from '../../store'
import { AppStyles, AppTheme } from '../AppStyles/AppStyles'
import { new_, user } from '../../network'
import {
  AppId,
  AppRegistration,
  AppSkeleton,
  ComponentModel,
  Context,
  critNetworkErr,
  CtxMessage,
  emptyMsg,
  Message,
  User,
  UtilsWord,
  WithCritErr,
} from '../../types'
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary'
import { useOnSmallScreen } from '../../hooks/useOnSmallScreen'
import Home from '../../components/Home/Home'
import { ENVIRONMENT } from '../../utils/Environment'
import ConnectionLost from '../../components/ErrorScreens/ConnectionLost/ConnectionLost'
import RouteWithConnection from '../../components/RouteWithConnection/RouteWithConnection'
import EnvironmentBar from '../../components/EnvironmentBar/EnvironmentBar'
import { useWsConnection, WebsocketState } from '../../hooks/useWsConnection'
import { getJuvoConfig } from '../../utils/JuvoConfig'
import {
  configureUserSession,
  initializeLogging,
  logDebug,
  logErr,
  logInfo,
  logWarn,
  terminateReplay,
} from '../../utils/Logger'
import { curry, pathContainsSegment } from '../../utils/Common'
import { getQueryParams } from '../../hooks/useQuery'
import { setJuvoAuthToken } from '../../utils/Fetch'
import { isDefined, isUndefined, Nullable } from '../../utils/Undefined'
import RedirectTopAppId from '../../components/RedirectToAppId/RedirectToAppId'
import { Providers } from '../../providers/Providers'
import { useHistoryState } from '../../hooks/useHistoryState'
import { useMessageHistory } from '../../hooks/useMessageHistory'
import { RouteTracker } from '../RouteTracker/RouteTracker'
import { UIBuilderRoute } from '../UIBuilder/UIBuilderRoute'

const tokenRoute = 'auth'

type NewSessionHandler = (a: Nullable<string>, u: User) => Promise<State>

/* REVIEW
Custom theme can be passed to JuvoApp as a prop for now, but later on we can change the source to
the backend data, or even the combination of the two.
 */
const JuvoApp: React.FC<{
  customReactComps: CustomComponentHandler
  officeCmdHandler: CmdHandler
  ctx: WithCritErr<Context>
  loginHandler: LoginHandler
  extraComponent?: React.ReactElement
  theme?: AppTheme
  logo?: ReactNode
  utilsWord?: UtilsWord
}> = ({
  customReactComps,
  officeCmdHandler,
  extraComponent,
  ctx,
  loginHandler,
  logo,
  theme,
  utilsWord,
}) => {
  const {
    basePath,
    defaultApp,
    headerConfig,
    environment,
    defaultConcurrencyLevel,
  } = getJuvoConfig()

  const { apps, prevState, setApps } = useHistoryState<State>(emptyState)
  const { addMessage, popMessage } = useMessageHistory()
  const [outMsg, setOutMessage] = useState<Message | CtxMessage>(emptyMsg)
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [showTestApp, setShowTestApp] = useState(false)
  const isNotProduction = environment !== ENVIRONMENT.production
  const [envDisplay, setEnvDisplay] = useState(isNotProduction)

  //state for Test page
  const [outTestMsgTxt, setOutTestMsgText] = useState('')
  const [inTestMsgTxt, setInTestMsgText] = useState('')

  //TODO consolidate into one
  const [testSkelTxt, setTestSkelTxt] = useState('')
  const [currentAppId, setCurrentAppId] = useState<string | null>('')
  const isUiBuilder = currentAppId === 'ui-builder'

  const onSmallScreen = useOnSmallScreen()

  useEffect(() => {
    initializeLogging()
    return () => terminateReplay()
  }, [])

  const updateLocation = () => {
    setCurrentAppId(window.location.href.split('/').pop() ?? null)
  }

  //TODO https://reactjs.org/docs/integrating-with-other-libraries.html
  //(integrate with context change)
  useEffect(() => {
    if (apps.type === 'success') {
      if (ctx.type === 'succ') {
        logDebug('Processing context update', { ctx: ctx })
        setApps(clearState(apps))
        setOutMessage({
          type: 'msgctx',
          payload: { ...ctx.pay, req_stamp: 'tmp' },
        })
      } else {
        logErr('Unexpected failed replacement context', { err: ctx })
      }
    } else {
      logDebug('Initalizing the app', { ctx: ctx })
      if (pathContainsSegment(tokenRoute)) {
        const query = getQueryParams(location.search)
        const token = query?.token
        setJuvoAuthToken(token)
      }
      configure(loginHandler, ctx, setApps, configureUserSession)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ctx, loginHandler]) //changing ctx is only possible in outlook where user selects new email

  //sets outMsg state, so it is available for the WS component
  const outMsgHandler = (c: ComponentModel) => async (msg: Message) => {
    logDebug('outmsg', msg)
    if (msg === null) {
      setOutMessage(msg)
    } else {
      const command = msg.payload?.command
      if (isUndefined(command)) {
        logWarn('Unexpected out message without command', { msg: msg })
        setOutMessage(msg)
      } else if (command.value === 'internalsink') {
        logInfo('internal test sink message')
      } else {
        const newcommand = { ...command, frontend_version: '1.0.0' }
        const newpayload = { ...msg.payload, command: newcommand }
        setOutMessage({ ...msg, payload: newpayload })
        if (prevState.type === 'success' && msg.payload.req_stamp) {
          const currentApp = prevState.apps.internal.get(currentAppId ?? '')
          if (isDefined(currentApp)) {
            const concurrencyProtocol =
              currentApp[1].app_concurrency ??
              defaultConcurrencyLevel ??
              'prefer_server'
            addMessage(
              msg.payload.req_stamp,
              c.id ? c : null,
              msg.payload.components ?? [],
              currentApp[1].app_skeleton.components ?? [],
              concurrencyProtocol,
            )
          }
        }
      }
    }
  }

  //handler for processing WebSocket inbound (from server) messages, needed for WebSocketEff component
  const inMsgHandler = (msg: Message): void => {
    const matchingMsg = isDefined(msg?.payload.req_stamp)
      ? popMessage(msg.payload.req_stamp)
      : null
    logDebug('inMsgHandler - setApps')
    setApps(prev => {
      return updateState(officeCmdHandler, msg, prev, matchingMsg)
    })
    // Patent OA Response initialization
    utilsWord && utilsWord.patentInitialization(ctx, msg, setApps, updateState)
  }
  //test version for troubleshooting
  const testInMsgHandler = (msg: Message): void => inMsgHandler(msg)

  const userComponentActionHandler = (appId: AppId) => (c: ComponentModel) => {
    const newApps = stateSetComponent(appId, c, apps)
    logDebug('userComponentActionHandler - setApps', {
      c: c,
      apps: stateDebug(newApps),
    })
    setApps(newApps)
  }

  const userSetSkeleton = (appId: AppId) => (appSkel: AppSkeleton) => {
    logDebug('userSetSkeleton - setApps')
    setApps(stateSetSkeleton(appId, appSkel, apps))
  }

  const onAppChange = (appId: AppId, appReg: AppRegistration): void => {
    logDebug('onAppChange - setApps', {
      appreg: appReg,
      oldapps: stateDebug(apps),
    })
    setApps(stateOverApp(appId, () => appReg, apps))
  }

  const dismissGlobalWarning = (): void => {
    logDebug('dismissGlobalWarning - setApps')
    setApps(dismissGlobalWarn(apps))
  }

  const websocketState = useWsConnection({
    outMsg,
    guid: apps.type === 'success' ? apps.guid : null,
    inMsg: inMsgHandler,
  })
  const isSocketDisconnected = websocketState === WebsocketState.DISCONNECTED

  const content = match(apps)
    .with({ type: 'success' }, res => {
      return (
        <Providers>
          <Router basename={basePath}>
            <ErrorBoundary>
              <RouteTracker updateLocation={updateLocation}>
                <Box
                  className="main-container"
                  sx={{
                    height: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: '#dfdfe1',
                  }}
                >
                  <Box
                    sx={{
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                      maxWidth: '1280px',
                      padding: {
                        xs: 0,
                        sm: '0 16px',
                        md: '0 64px',
                        lg: '0 128px',
                      },
                      marginTop: '0',
                      ...(isUiBuilder && {
                        maxWidth: 'unset',
                        padding: {
                          xs: '0 var(--ui-drawer-width) 0 0 ',
                          sm: '0 calc(var(--ui-drawer-width) + 16px) 0 16px',
                          md: '0 calc(var(--ui-drawer-width) + 64px) 0 64px',
                          lg: '0 calc(var(--ui-drawer-width) + 128px) 0 128px',
                        },
                      }),
                    }}
                  >
                    {logo}
                    <Paper
                      className="application-box"
                      elevation={onSmallScreen ? 0 : 6}
                      sx={{
                        flex: 1,
                        overflowY: 'hidden',
                        borderRadius: 0,
                        position: 'relative',
                      }}
                    >
                      <Header
                        apps={res.apps}
                        onAppSkelChange={userSetSkeleton}
                        onAppRegChange={curry(onAppChange)}
                        toggleTestApp={() =>
                          setShowTestApp(prevState => !prevState)
                        }
                        isTestApp={showTestApp}
                        isDisconnected={isSocketDisconnected}
                      />
                      <EnvironmentBar
                        environment={environment}
                        display={envDisplay}
                        setEnvDisplay={setEnvDisplay}
                      />
                      {headerConfig.menu && (
                        <Drawer
                          appList={res.appList}
                          open={drawerOpen}
                          onOpen={() => setDrawerOpen(true)}
                          onClose={() => setDrawerOpen(false)}
                        />
                      )}
                      <Box
                        className="app-content"
                        id="app-scroll-content"
                        sx={{
                          height: `calc(100% - var(--header-height))`,
                          padding: envDisplay
                            ? {
                                xs: 'calc(20px + var(--ui-environment-bar)) 20px 20px',
                                md: 'calc(28px + var(--ui-environment-bar)) 28px 28px',
                              }
                            : { xs: '20px', md: '28px' },
                          overflowY: 'auto',
                        }}
                      >
                        {!showTestApp &&
                          websocketState < WebsocketState.TERMINATED && (
                            <Routes>
                              {/* Navigate just used in the intake portal app, the other apps render the dashboard */}
                              <Route
                                path="/"
                                element={
                                  defaultApp ? (
                                    <Navigate
                                      to={`/apps/${defaultApp}`}
                                      replace
                                    />
                                  ) : (
                                    <RouteWithConnection
                                      isOpen={isSocketDisconnected}
                                    >
                                      <Home
                                        apps={res.apps}
                                        suggestions={res.suggestions}
                                        onAllAppsClicked={() =>
                                          setDrawerOpen(true)
                                        }
                                      />
                                    </RouteWithConnection>
                                  )
                                }
                              />
                              <Route
                                path="ui-builder"
                                element={
                                  <UIBuilderRoute
                                    user={res.juvoUser}
                                    apps={res.apps}
                                    customReactComps={customReactComps}
                                  />
                                }
                              />
                              <Route
                                path="apps/:id"
                                element={
                                  <RouteWithConnection
                                    isOpen={isSocketDisconnected}
                                  >
                                    <>
                                      <Addlet
                                        customReactComps={customReactComps}
                                        state={res}
                                        onAppChange={onAppChange}
                                        onComponentChange={
                                          userComponentActionHandler
                                        }
                                        onOutMsg={outMsgHandler}
                                        globalWarn={getGlobalWarn(apps)}
                                        dismissGlobalWarn={dismissGlobalWarning}
                                      />
                                      {extraComponent && extraComponent}
                                    </>
                                  </RouteWithConnection>
                                }
                              />
                              <Route
                                path={tokenRoute}
                                element={
                                  <Navigate
                                    to={`/apps/${defaultApp}`}
                                    replace
                                  />
                                }
                              />
                              <Route
                                path={`${tokenRoute}/:appId`}
                                element={<RedirectTopAppId />}
                              />
                              <Route
                                path="*"
                                element={<Navigate to="/" replace />}
                              />
                            </Routes>
                          )}
                        {showTestApp &&
                          websocketState < WebsocketState.TERMINATED && (
                            <Test
                              onOutMsg={outMsgHandler}
                              onInMsg={testInMsgHandler}
                              outTestMsgTxt={outTestMsgTxt}
                              setOutTestMsgText={setOutTestMsgText}
                              inTestMsgTxt={inTestMsgTxt}
                              setInTestMsgText={setInTestMsgText}
                              testSkelTxt={testSkelTxt}
                              setTestSkelTxt={setTestSkelTxt}
                              testSkelAppId={currentAppId}
                              setTestSkelAppId={setCurrentAppId}
                              onAppDataChange={userSetSkeleton}
                              closeTestApp={() => setShowTestApp(false)}
                            />
                          )}
                        {websocketState === WebsocketState.TERMINATED && (
                          <ConnectionLost />
                        )}
                      </Box>
                    </Paper>
                  </Box>
                </Box>
              </RouteTracker>
            </ErrorBoundary>
          </Router>
        </Providers>
      )
    })
    .with({ type: 'critical' }, res => {
      logDebug('setup, WS not started', { err: res.err })
      return <Critical err={res.err} />
    })
    .exhaustive()

  return (
    <AppStyles customTheme={theme}>
      {/*This is needed to measure texts' visual length*/}
      <span id="ruler" />
      {content}
    </AppStyles>
  )
}

const loginWithToken = async (
  newSession: NewSessionHandler,
): Promise<SetStateAction<State>> => {
  const usrRes = await user()
  logDebug('userCall res', { user: usrRes })
  if (usrRes.type === 'left') {
    const errmsg =
      usrRes.content.status === 401 ? 'Token Expired' : 'Invalid Token'
    return {
      type: 'critical',
      err: critNetworkErr(errmsg, usrRes.content),
    }
  } else {
    return await newSession(null, usrRes.content)
  }
}

const loginWithAuth = async (
  loginHandler: LoginHandler,
  newSession: NewSessionHandler,
): Promise<SetStateAction<State>> => {
  const usrRes = await user()
  logDebug('userCall res', { user: usrRes })

  if (usrRes.type == 'right') {
    return await newSession(null, usrRes.content)
  } else if (usrRes.type == 'left' && usrRes.content.status === 401) {
    //User is not logged in, fetch login flow and retry retrieving user info
    const res = await loginHandler()
    logDebug('loginHandler res', { loginRes: res })
    if (res.type === 'cookie') {
      const usrResRetry = await user()
      if (usrResRetry.type == 'left') {
        return {
          type: 'critical',
          err: critNetworkErr('Not Logged In', usrResRetry.content),
        }
      } else {
        return await newSession(null, usrResRetry.content)
      }
    } else if (res.type === 'user') {
      const usr: User = JSON.parse(res.payload)
      return await newSession(usr.token, usr)
    } else {
      return {
        type: 'critical',
        err: critNetworkErr('Not Logged In', usrRes.content),
      }
    }
  } else {
    return {
      type: 'critical',
      err: critNetworkErr('Authentication error', usrRes.content),
    }
  }
}

const configure = async (
  loginHandler: LoginHandler,
  context: WithCritErr<Context>,
  setApps: React.Dispatch<React.SetStateAction<State>>,
  configureUserSession: (u: User) => void,
) => {
  if (context.type === 'succ') {
    const newSessionHandler = async (token: Nullable<string>, user: User) => {
      const nextRes = await new_(token, context.pay)
      const nextState = bootstrapRes(user, nextRes)
      configureUserSession(user)
      return nextState
    }
    const nextState = await (pathContainsSegment(tokenRoute)
      ? loginWithToken(newSessionHandler)
      : loginWithAuth(loginHandler, newSessionHandler))
    setApps(nextState)
  } else {
    setApps({ type: 'critical', err: context.pay })
  }
}

export default JuvoApp
