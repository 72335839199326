import React from 'react'
import Box from '@mui/material/Box/Box'
import FormControlLabel from '@mui/material/FormControlLabel/FormControlLabel'
import Checkbox from '@mui/material/Checkbox/Checkbox'
import { CheckBoxOutlineBlankSharp, CheckBoxSharp } from '@mui/icons-material'
import { SxProps } from '@mui/material/styles'
import { Typography } from '@mui/material'

import { useUIBuilderHint } from '../Hint/UIBuilderHintContext'

interface BooleanAttributeInputProps {
  inputStyles: SxProps
  attrKey: string
  fireChange: (val: boolean) => void
  val: string | undefined
  hint?: string
}

export const BooleanAttributeInput: React.FC<BooleanAttributeInputProps> = ({
  attrKey,
  fireChange,
  inputStyles,
  val,
  hint,
}) => {
  const { hintButton } = useUIBuilderHint()

  return (
    <Box>
      <FormControlLabel
        label={
          <Typography>
            {attrKey}
            {hint && hintButton({ title: attrKey, body: hint })}
          </Typography>
        }
        sx={{
          ...inputStyles,
          margin: 0,
          padding: 0,
        }}
        control={
          <Checkbox
            icon={<CheckBoxOutlineBlankSharp />}
            checkedIcon={<CheckBoxSharp />}
            checked={Boolean(val)}
            onClick={() => fireChange(!val)}
          />
        }
      />
    </Box>
  )
}
