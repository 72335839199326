import Box from '@mui/material/Box'
import React from 'react'
import { Link } from 'react-router-dom'
import MuiButton from '@mui/material/Button'

interface SpecialDrawerButtonProps {
  href: string
  text: string
  icon: string
}

export const SpecialDrawerButton: React.FC<SpecialDrawerButtonProps> = ({
  href,
  text,
  icon,
}) => {
  return (
    <Box component={Link} to={href}>
      <MuiButton
        variant="text"
        sx={{
          color: theme => theme.palette.primary.light,
          gap: '6px',
          fontWeight: 600,
          width: '100%',
          a: {
            textDecoration: 'none',
            color: 'inherit',
          },
        }}
      >
        {text}
        <i className={`fas fa-solid ${icon}`} />
      </MuiButton>
    </Box>
  )
}
