import React from 'react'
import InputLabel from '@mui/material/InputLabel/InputLabel'
import Box from '@mui/material/Box/Box'
import InputBase, { InputBaseProps } from '@mui/material/InputBase/InputBase'
import { SxProps } from '@mui/material/styles'

import { useUIBuilderHint } from '../Hint/UIBuilderHintContext'

interface NumberAttributeInputProps extends InputBaseProps {
  inputStyles: SxProps
  labelStyles: SxProps
  attrKey: string
  fireChange: (val: number) => void
  val: string | undefined
  hint?: string
}

export const NumberAttributeInput: React.FC<NumberAttributeInputProps> = ({
  attrKey,
  fireChange,
  labelStyles,
  inputStyles,
  val,
  hint,
  ...otherProps
}) => {
  const { hintButton } = useUIBuilderHint()

  return (
    <Box>
      <InputLabel variant="standard" sx={labelStyles}>
        {attrKey}
        {hint && hintButton({ title: attrKey, body: hint })}
      </InputLabel>
      <InputBase
        type="number"
        id={`${attrKey}-input`}
        onChange={event => fireChange(parseInt(event.target.value))}
        value={val ?? ''}
        sx={inputStyles}
        {...otherProps}
      />
    </Box>
  )
}
