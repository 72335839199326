import React, { createContext, useContext, useEffect, useState } from 'react'

import { AppRegistration, ComponentModel } from '../types'
import { genId, isDefined, logErr, Nullable } from '../utils'
import { appComponentsSetComponent, removeById } from '../store/State'
import { getCheatsheet } from '../network/XmlInterop'
import { genericApp } from '../containers/UIBuilder/data/generics'

export type FunctionMode = 'Edit' | 'Default' | 'Meta'

export interface UploadingContextProps {
  functionMode: FunctionMode
  updateFunctionMode: (value: FunctionMode) => void
  selectedComponent: ComponentModel
  setSelectedComponent: (select: ComponentModel) => void
  appReg: AppRegistration
  setAppReg: (a: AppRegistration) => void
  setAppComps: (newComps: ComponentModel[]) => void
  addToComps: (c: ComponentModel[]) => void
  addToChildComps: (nc: ComponentModel, pc: ComponentModel) => void
  updateComp: (c: ComponentModel) => void
  cutComp: (c: ComponentModel) => void
  pasteComp: (c: ComponentModel) => void
  pasteCompTop: () => void
  removeComp: (c: ComponentModel) => void
  genIdsWithChildren: (c: ComponentModel) => void
  compSheet: Map<string, ComponentModel> | undefined
  hasCutComp: boolean
}

export const UIBuilderContext = createContext<UploadingContextProps>({
  functionMode: 'Default',
  updateFunctionMode: () => undefined,
  selectedComponent: undefined,
  setSelectedComponent: () => undefined,
  appReg: genericApp,
  setAppComps: () => undefined,
  setAppReg: () => undefined,
  addToComps: () => undefined,
  cutComp: () => undefined,
  pasteComp: () => undefined,
  pasteCompTop: () => undefined,
  addToChildComps: () => undefined,
  updateComp: () => undefined,
  removeComp: () => undefined,
  genIdsWithChildren: () => undefined,
  compSheet: new Map<string, ComponentModel>(),
  hasCutComp: false,
})

export const useUIBuilder = () => useContext(UIBuilderContext)

const UIBuilderProvider: React.FC<{ children: React.ReactElement }> = ({
  children,
}) => {
  const [functionMode, setFunctionMode] = useState<FunctionMode>('Default')
  const [currentlyCut, setCurrentlyCut] = useState<Nullable<string>>(null)
  const [compSheet, setCompSheet] = useState<Map<string, ComponentModel>>()
  const [selectedComponent, setSelectedComponent] =
    useState<ComponentModel>(null)
  const [appReg, setAppReg] = useState<AppRegistration>(genericApp)

  useEffect(() => {
    initCompCheatsheet()
  }, [])

  const [componentTracker, setComponentTracker] = useState<Map<string, number>>(
    new Map(),
  )

  const initCompCheatsheet = () => {
    getCheatsheet()
      .then(res => {
        if (res.type === 'right') {
          const compMap = new Map<string, ComponentModel>()
          res.content.map((comp: ComponentModel) => {
            const key = isDefined(comp.sub_type)
              ? `${comp.type}.${comp.sub_type}`
              : comp.type
            compMap.set(key, comp)
          })
          setCompSheet(compMap)
        }
      })
      .catch(e => {
        logErr(e)
      })
  }

  const setAppComps = (newComps: ComponentModel[]) => {
    const currentSkeleton = appReg.app_skeleton
    setAppReg({
      ...appReg,
      app_skeleton: { ...currentSkeleton, components: newComps },
    })
  }

  const genIds = (comp: ComponentModel) => {
    const numComps = componentTracker.get(comp.type)
    if (isDefined(numComps)) {
      setComponentTracker(componentTracker.set(comp.type, numComps + 1))
      return {
        ...comp,
        uiBuilderId: genId(),
        id: `${comp.type}-${numComps + 1}`,
      }
    } else {
      setComponentTracker(componentTracker.set(comp.type, 1))
      return { ...comp, uiBuilderId: genId(), id: `${comp.type}-${1}` }
    }
  }

  const genIdsWithChildren = (comp: ComponentModel) => {
    const idComp = genIds(comp)
    if (isDefined(idComp.tchildren) && idComp.tchildren.length > 0) {
      return {
        ...idComp,
        tchildren: idComp.tchildren.map((child: ComponentModel) =>
          genIdsWithChildren(child),
        ),
      }
    } else {
      return idComp
    }
  }

  const cutComp = (comp: ComponentModel) => {
    const jsonComp = JSON.stringify(comp)
    setCurrentlyCut(jsonComp)
    removeComp(comp)
  }

  const pasteComp = (comp: ComponentModel) => {
    if (currentlyCut) {
      try {
        const compToAdd = JSON.parse(currentlyCut)
        addToChildComps(compToAdd, comp)
      } catch (e) {
        logErr(e)
      }
    }
  }

  const pasteCompTop = () => {
    if (currentlyCut) {
      try {
        const compToAdd = JSON.parse(currentlyCut)
        addToComps([compToAdd])
      } catch (e) {
        logErr(e)
      }
    }
  }

  const updateComp = (comp: ComponentModel) => {
    const currentComps = appReg.app_skeleton.components
    const newCompChildren = appComponentsSetComponent(comp)(currentComps)
    setAppComps(newCompChildren)
  }

  const updateAppRegWithIds = (newReg: AppRegistration) => {
    const newComps = newReg.app_skeleton.components
    const compsWithId = newComps.map(c => genIdsWithChildren(c))
    const updatedSkel = { ...newReg.app_skeleton, components: compsWithId }
    setAppReg({ ...newReg, app_skeleton: updatedSkel })
  }

  const addToComps = (newComps: ComponentModel[]) => {
    const currentComps = appReg.app_skeleton.components
    const compsWithId = newComps.map(c => genIdsWithChildren(c))
    const newChildren = currentComps.concat(compsWithId)
    setAppComps(newChildren)
  }

  const addToChildComps = (newComp: ComponentModel, pc: ComponentModel) => {
    const currentComps = appReg.app_skeleton.components
    const compWithId = genIdsWithChildren(newComp)
    const newParent = {
      ...pc,
      tchildren: pc.tchildren
        ? pc.tchildren.concat([compWithId])
        : [compWithId],
    }
    const newCompChildren = appComponentsSetComponent(newParent)(currentComps)
    setAppComps(newCompChildren)
  }

  const removeComp = (comp: ComponentModel) => {
    const currentComps = appReg.app_skeleton.components
    const [, newChildren] = removeById(currentComps, comp)
    if (isDefined(newChildren)) {
      setAppComps(newChildren)
    }
  }

  const updateFunctionMode = (mode: FunctionMode) => {
    setFunctionMode(prevMode => {
      if (prevMode === 'Edit') {
        setSelectedComponent(null)
      }
      return mode
    })
    if (mode) {
      document
        .getElementsByClassName('application-box')[0]
        .classList.add('ui__editing')
    } else {
      document
        .getElementsByClassName('application-box')[0]
        .classList.remove('ui__editing')
      setSelectedComponent(undefined)
    }
  }

  return (
    <UIBuilderContext.Provider
      value={{
        functionMode,
        updateFunctionMode,
        selectedComponent,
        setSelectedComponent,
        appReg,
        setAppComps,
        setAppReg: updateAppRegWithIds,
        addToComps,
        addToChildComps,
        updateComp,
        removeComp,
        cutComp,
        genIdsWithChildren,
        compSheet,
        pasteComp,
        pasteCompTop,
        hasCutComp: isDefined(currentlyCut),
      }}
    >
      {children}
    </UIBuilderContext.Provider>
  )
}

export default UIBuilderProvider
