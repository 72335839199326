import { ThemeOptions, createTheme } from '@mui/material'
import { deepmerge } from '@mui/utils'

import { isDefined } from '../../utils'

const defaultTheme: ThemeOptions = {
  typography: {
    allVariants: {
      color: '#290235',
    },
    fontFamily: `'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif`,
    h1: {
      fontSize: '2em',
      fontWeight: 700,
    },
    h2: {
      fontWeight: 600,
      fontSize: '1.65em',
    },
    h3: {
      fontWeight: 600,
      fontSize: '1.5em',
    },
    h4: {
      fontSize: '1.3em',
      fontWeight: 600,
    },
    h5: {
      fontSize: '1.2em',
      fontWeight: 500,
    },
    h6: {
      fontSize: '1.1em',
      fontWeight: 600,
    },
    subtitle1: {
      fontSize: '1em',
      lineHeight: '22px',
      letterSpacing: '0.2px',
      fontWeight: 500,
    },
    subtitle2: {
      fontSize: '0.75em',
      lineHeight: '16px',
      textTransform: 'uppercase',
      letterSpacing: '1px',
      fontWeight: 600,
    },
    caption: {
      fontSize: '0.7em',
      fontWeight: 600,
    },
  },
  zIndex: {
    snackbar: 1299,
  },
  palette: {
    primary: {
      main: '#6B337C',
      light: '#af80cd',
      dark: '#492354',
    },
    secondary: {
      main: '#F3F3F7',
      light: '#f9f9fd',
      dark: '#dcdce0',
    },
    info: {
      main: '#f7edfd',
      light: '#FFFFF5',
      dark: '#FFFF94',
    },
    error: {
      main: '#d32f2f',
      light: '#f3b4b4',
      dark: '#A72323',
      contrastText: '#290235',
    },
    text: {
      primary: '#290235',
      secondary: '#856686',
      disabled: '#b49ab2',
    },
  },
  components: {
    // Icons in the drawer items take up too much space by default.
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: '1.75rem',
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          marginBottom: '18px !important',
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        root: {
          marginTop: 'unset',
          marginBottom: '18px',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '0px',
        },
      },
    },
    // This is so that we can customize icon color in the parent element.
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: 'inherit',
        },
      },
    },
    MuiIcon: {
      styleOverrides: {
        root: {
          // Match 24px = 3 * 2 + 1.125 * 16
          boxSizing: 'content-box',
          padding: 3,
          fontSize: '1.125rem',
        },
      },
    },
    // To make sure the drawer items and their icons use the default juvo purple.
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: '#8d6cd9',

            '& .MuiListItemIcon-root': {
              color: 'inherit',
            },
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        formControl: {
          borderRadius: '0 !important',
        },
      },
    },
    // This makes the input fields' hover color a dark hue of purple instead of the default black.
    MuiInput: {
      styleOverrides: {
        root: {
          '&:not(.Mui-disabled):hover::before': {
            borderColor: '#502ba6',
          },
          '&.no-underline::before, &.no-underline::after': {
            display: 'none',
          },
        },
      },
    },

    // Tooltip override styles arrow presence is defined in every tooltip implementation
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: 'white',
          borderRadius: 0,
          color: 'black',
          maxWidth: '50vw',
          fontSize: '0.875rem',
          boxShadow: '0px 2px 6px 0px rgba(0,0,0,0.41)',
        },
        arrow: {
          color: 'white',
        },
      },
    },
    // Override Alert colors
    MuiAlert: {
      styleOverrides: {
        standardSuccess: {
          backgroundColor: '#D9F4D9',
        },
        standardError: {
          backgroundColor: '#ffd0d2',
        },
        standardWarning: {
          backgroundColor: '#F9E3C6',
        },
        standardInfo: {
          backgroundColor: '#f7edfd',
          color: '#290235',
          '.MuiAlert-icon': {
            color: '#290235 !important',
          },
        },
      },
    },
  },
}

export const createAppTheme = (customTheme?: ThemeOptions) => {
  if (isDefined(customTheme)) {
    return createTheme(deepmerge(defaultTheme, customTheme))
  } else {
    return createTheme(defaultTheme)
  }
}
