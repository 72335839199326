import React, { useEffect, useState } from 'react'

import {
  ComponentModel,
  ModalModel,
  StdComponentArgs,
  adjustStdComponentArgs,
} from '../../types/Model'
import { containerSetChild } from '../../store'
import { getAlertSeverityBySubType } from '../../utils/Notifications'
import { SwitchYard } from '../../components'

import { JuvoDialog } from './JuvoDialog'

const JuvoComponentModal: React.FC<StdComponentArgs<ModalModel>> = props => {
  const { comp, onComponentChange } = props
  const children: ComponentModel[] = comp.tchildren || []
  const [open, setOpen] = useState(true)

  useEffect(() => {
    setOpen(true)
  }, [comp])

  return (
    <JuvoDialog
      title={comp.title}
      severity={getAlertSeverityBySubType(comp.sub_type)}
      open={open}
      handleClose={() => setOpen(false)}
    >
      <>
        {children.map((cp, index) => {
          const childProps = adjustStdComponentArgs(props, cp, nc =>
            onComponentChange(containerSetChild(nc, index, comp)),
          )
          return <SwitchYard key={index} {...childProps} />
        })}
      </>
    </JuvoDialog>
  )
}

export default JuvoComponentModal
