import { AppRegistration, ComponentModel } from '../types/Model'
import { Either } from '../utils/Common'
import { betterFetchJson, FetchError, fetchXML } from '../utils/Fetch'
import { getJuvoConfig } from '../utils/JuvoConfig'

type CheatsheetResponse = any

export const getCheatsheet = (): Promise<
  Either<FetchError, CheatsheetResponse>
> => {
  const { platformHttpUrl } = getJuvoConfig()

  return betterFetchJson<CheatsheetResponse>(
    `${platformHttpUrl.replace('ms-platform-web', 'ms-configuration')}/xmlinterop/cheatsheet`,
    {
      method: 'GET',
      headers: {
        'Content-type': 'application/json',
      },
    },
  )
}

export const xmlToJsonApp = (
  app: string,
): Promise<Either<FetchError, CheatsheetResponse>> => {
  const { platformHttpUrl } = getJuvoConfig()

  return betterFetchJson<CheatsheetResponse>(
    `${platformHttpUrl.replace('ms-platform-web', 'ms-configuration')}/xmlinterop/fromxml/app-reg`,
    {
      method: 'POST',
      headers: {
        'Content-type': 'text/plain; charset=utf-8',
      },
      body: app,
    },
  )
}

export const xmlToJsonComponent = (
  component: string,
): Promise<Either<FetchError, CheatsheetResponse>> => {
  const { platformHttpUrl } = getJuvoConfig()

  return betterFetchJson<CheatsheetResponse>(
    `${platformHttpUrl.replace('ms-platform-web', 'ms-configuration')}/xmlinterop/fromxml/component`,
    {
      method: 'POST',
      headers: {
        'Content-type': 'text/plain; charset=utf-8',
      },
      body: component,
    },
  )
}

export const jsonToXmlComponent = async (
  component: ComponentModel,
): Promise<FetchError | string> => {
  const { platformHttpUrl } = getJuvoConfig()

  const res = await fetchXML(
    `${platformHttpUrl.replace('ms-platform-web', 'ms-configuration')}/xmlinterop/toxml/component`,
    {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify(component),
    },
  )
  if (res.type === 'right') {
    return new XMLSerializer().serializeToString(res.content.documentElement)
  } else {
    return res.content
  }
}

export const appRegToXml = async (
  app: AppRegistration,
): Promise<FetchError | string> => {
  const { platformHttpUrl } = getJuvoConfig()

  const res = await fetchXML(
    `${platformHttpUrl.replace('ms-platform-web', 'ms-configuration')}/xmlinterop/toxml/app-reg`,
    {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify(app),
    },
  )
  if (res.type === 'right') {
    return new XMLSerializer().serializeToString(res.content.documentElement)
  } else {
    return res.content
  }
}
