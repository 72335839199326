import { langs } from '@uiw/codemirror-extensions-langs'
import React from 'react'
import Button from '@mui/material/Button/Button'
import CopyToClipboard from 'react-copy-to-clipboard'

import { JuvoDialog } from '../JuvoModal/JuvoDialog'
import { useCopy } from '../../hooks/useCopy'
import { JuvoCodeMirror } from '../JuvoCodeMirror/JuvoCodeMirror'

export type InteropResType = 'xml' | 'json'

interface ResponseModalProps {
  response: string
  type: InteropResType
  closeFn: () => void
}

export const ResponseModal: React.FC<ResponseModalProps> = ({
  response,
  type,
  closeFn,
}) => {
  const [isCopied, copyHandler] = useCopy()

  return (
    <>
      {response && (
        <JuvoDialog
          title={type.toUpperCase()}
          severity="info"
          open={true}
          handleClose={closeFn}
          buttons={
            <>
              <Button onClick={closeFn}>Close</Button>
              {isCopied ? (
                <Button variant="contained" disabled>
                  Copied
                </Button>
              ) : (
                <CopyToClipboard text={response} onCopy={copyHandler}>
                  <Button variant="contained">Copy</Button>
                </CopyToClipboard>
              )}
            </>
          }
        >
          <JuvoCodeMirror
            value={response}
            extensions={[type === 'xml' ? langs.xml() : langs.json()]}
          />
        </JuvoDialog>
      )}
    </>
  )
}
