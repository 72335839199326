import React, { useRef, useState } from 'react'
import Box from '@mui/material/Box/Box'
import Typography from '@mui/material/Typography/Typography'
import Button from '@mui/material/Button/Button'

import { ComponentModel } from '../../../types'
import { useSavedUIBuilderComps } from '../../../hooks/useSavedUIBuilderComps'
import { JuvoDialog } from '../../JuvoModal/JuvoDialog'
import { isDefined } from '../../../utils/Undefined'
import { SaveableComp } from '../data/properties'

import { DrawerComponentGroup } from './DrawerComponentGroup'
import { CustomDrawerComponent } from './CustomDrawerComponent'
import { ModalTextInput } from './ModalTextInput'

export type UIBuilderCompProps = {
  addMe: (c: ComponentModel) => void
}

export const UIBuilderCustomComps: React.FC<UIBuilderCompProps> = ({
  addMe,
}) => {
  const [editNameDialog, setEditNameDialog] = useState<string | null>(null)
  const localName = useRef<string>('')
  const { currentComps, deleteSavedUIBuilderComp, updateSaveCompName } =
    useSavedUIBuilderComps()

  const editName = (comp: SaveableComp) => {
    setEditNameDialog(comp.saveId)
    localName.current = comp.saveId
  }

  const closeFn = () => {
    setEditNameDialog(null)
  }

  const saveNewName = () => {
    if (isDefined(editNameDialog)) {
      updateSaveCompName(editNameDialog, localName.current)
    }
    closeFn()
  }

  const updateName = (newName: string) => {
    localName.current = newName
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
      }}
    >
      {!currentComps.length && (
        <Box
          sx={{
            borderRadius: '4px',
            border: theme => `2px dashed ${theme.palette.primary.main}`,
            padding: '32px',
          }}
        >
          <Typography variant="subtitle2" align="center" color="white">
            You don't have any custom components yet
          </Typography>
        </Box>
      )}
      {currentComps.length && (
        <DrawerComponentGroup title="Custom Components" gridDisplay={true}>
          <>
            {currentComps.map((c, index) => (
              <CustomDrawerComponent
                name={c.saveId}
                key={index}
                compModel={c.comp}
                onClickToAdd={() => addMe(c)}
                onEditName={() => editName(c)}
                deleteComponent={() => deleteSavedUIBuilderComp(c)}
              />
            ))}
          </>
        </DrawerComponentGroup>
      )}
      {isDefined(editNameDialog) && (
        <JuvoDialog
          title="Edit Component Name"
          severity="info"
          open={true}
          handleClose={closeFn}
          buttons={
            <>
              <Button onClick={closeFn}>Close</Button>
              <Button variant="contained" onClick={saveNewName}>
                Save
              </Button>
            </>
          }
        >
          <ModalTextInput val={editNameDialog} onChange={updateName} />
        </JuvoDialog>
      )}
    </Box>
  )
}
