import { Map } from 'immutable'
import { match } from 'ts-pattern'

import { MessageLogEntry } from '../hooks/useMessageHistory'
import {
  AppConcurrency,
  AppId,
  AppItem,
  AppRegistration,
  AppSkeleton,
  clearAppRegistration,
  clearOutCommand,
  ComponentModel,
  CritErr,
  critNetworkErr,
  Guid,
  IdiosyncraticComponentType,
  InitialAppData,
  isCarouselModel,
  isFileDropModel,
  isIdiosyncraticCompType,
  isListModel,
  isMultiSelectModel,
  ListModel,
  Message,
  User,
  Warning,
  WarningIssue,
} from '../types'
import {
  imGet,
  imMapEmpty,
  ImmutableMap,
  imSet,
  imToJS,
} from '../utils/ImmutableMap'
import { logDebug, logErr, logFatal, logInfo, logWarn } from '../utils/Logger'
import { isDefined, isUndefined, Nullable } from '../utils/Undefined'
import {
  assumeRights,
  Either,
  getLocalFavorites,
  left,
  right,
  setLocalFavorites,
  zipWith,
} from '../utils/Common'
import { FetchError } from '../utils/Fetch'
import { getJuvoConfig } from '../utils/JuvoConfig'

import {
  addIdsToCompIfMissing,
  getUniqueId,
  msgListRecommendations,
  removeDuplicateHashes,
  userAcceptRecommendedBestEffort,
} from './Component'
import { CmdHandler, isCmdMessage } from './CustomComponents'

export type SuccessState = {
  type: 'success'
  guid: Guid
  appList: AppItem[]
  apps: ImmutableMap<AppId, [Warning, AppRegistration]>
  suggestions: Nullable<AppId[]>
  juvoUser: User
  unexpectedWarn?: Nullable<WarningIssue>
}

export type ErrorState = {
  type: 'critical'
  err: CritErr
}

export const getSuggestedApps = (
  apps: ImmutableMap<AppId, [Warning, AppRegistration]>,
  suggestions: Nullable<AppId[]>,
): AppItem[] => {
  // If app not in the map, consider this as unexpected error (left)
  const interested = (
    appid: AppId,
    app: [Warning, AppRegistration] | undefined,
  ): Either<string, AppRegistration> => {
    return app ? right(app[1]) : left(`No registration for ${appid}`)
  }

  const suggestedApps = () => {
    const { environment } = getJuvoConfig()
    const appsIds = imToJS(apps).keys()
    const localFavorites = getLocalFavorites(environment)
    const appsArr = Array.from(appsIds)
    const intersection = localFavorites.filter(val => appsArr.includes(val))

    setLocalFavorites(environment, intersection) // Set favorites in case the outdated name was used

    if (intersection.length < localFavorites.length) {
      logWarn('Some favorited apps no longer exist')
    }

    // If favorites exist then return favorites
    if (intersection.length > 0) {
      return intersection
      // If suggestions exist then return suggestions
    } else if (suggestions && suggestions.length > 0) {
      return suggestions
    }
    // Return first 4 apps
    else {
      return appsArr.slice(0, 4)
    }
  }

  const eAppLst = suggestedApps().map(appId =>
    interested(appId, imGet(apps)(appId)),
  )

  //NOTE  assumeRights(eAppLst) returns AppRegistration[] but this can be used as is as AppItem[]
  return assumeRights(eAppLst)
}

export type State = SuccessState | ErrorState

export const clearState = (s: State): State =>
  match(s)
    .with({ type: 'success' }, st => {
      const appIds = st.appList.map(app => app.app_id)
      return appIds.reduce(
        (acc, appId) => stateOverApp(appId, clearAppRegistration, acc),
        s,
      )
    })
    .with({ type: 'critical' }, st => st)
    .exhaustive()

export const stateDebug = (s: State) =>
  match(s)
    .with({ type: 'success' }, st => imToJS(st.apps))
    .with({ type: 'critical' }, st => st.err)
    .exhaustive()

export const getGlobalWarn = (s: State): Nullable<WarningIssue> =>
  match(s)
    .with({ type: 'success' }, st => st.unexpectedWarn)
    .with({ type: 'critical' }, () => null)
    .exhaustive()

export const dismissGlobalWarn = (s: State): State =>
  match(s)
    .with({ type: 'success' }, st => {
      return { ...st, unexpectedWarn: null }
    })
    .with({ type: 'critical' }, st => st)
    .exhaustive()

export const containerSetChild = (
  childComp: ComponentModel,
  inx: number,
  containerComp: ComponentModel,
): ComponentModel => {
  const children: ComponentModel[] = containerComp.tchildren || []
  const newChildren = children.map((cp, ix) => (ix === inx ? childComp : cp))
  return { ...containerComp, tchildren: newChildren }
}

export const stateSetComponent = (
  appId: AppId,
  newcomp: ComponentModel,
  state: State,
): State => {
  const uniqueCid: string = getUniqueId(newcomp)
  return stateOverApp(
    appId,
    appreg => {
      // this changes top level components only, we can convert to using appRegistrationSetComponent
      // but is seems to be not needed as the updates compose as lenses
      const debugupdates: ComponentModel[] = []
      const newcoms: ComponentModel[] = appreg.app_skeleton.components.map(
        oldcomp => {
          if (getUniqueId(oldcomp) === uniqueCid) {
            debugupdates.push(oldcomp)
            return newcomp
          } else {
            return oldcomp
          }
        },
      )
      if (debugupdates.length === 0) {
        logWarn('stateSetComponent - no match', {
          newcomp: newcomp,
          oldcomps: appreg.app_skeleton.components,
        })
      } else if (debugupdates.length > 1) {
        logWarn('stateSetComponent - more than one match', {
          newcomp: newcomp,
          replaced: debugupdates,
        })
      }
      //keep useful for troubleshooting
      // else {
      //   logDebug('stateSetComponent - expected', {
      //     newcomp: newcomp,
      //     replaced: debugupdates,
      //     newcps: newcoms
      //   })
      // }
      const newAppSkeleton = { ...appreg.app_skeleton, components: newcoms }

      return { ...appreg, app_skeleton: newAppSkeleton }
    },
    state,
  )
}

// used to adjust out message only
export const appRegistrationSetComponent =
  (newcomp: ComponentModel) =>
  (appreg: AppRegistration): AppRegistration => {
    let alreadyReplacedFasthack = false //avoid fold on that level, stores if component was already replaced
    const newcoms: ComponentModel[] = appreg.app_skeleton.components.map(
      oldcomp => {
        if (alreadyReplacedFasthack) return oldcomp
        else {
          const [m, res] = setComponentRecursive(newcomp)(oldcomp)
          alreadyReplacedFasthack = m //avoid fold which recreates arrays
          return res
        }
      },
    )
    const newAppSkeleton = { ...appreg.app_skeleton, components: newcoms }

    return { ...appreg, app_skeleton: newAppSkeleton }
  }

export const removeById = (
  comps: ComponentModel[],
  targetComp: ComponentModel,
): [boolean, ComponentModel[]] => {
  for (let i = 0; i < comps.length; i++) {
    const c = comps[i]
    if (c.uiBuilderId === targetComp.uiBuilderId) {
      return [true, comps.filter(e => e.uiBuilderId !== c.uiBuilderId)]
    }

    if (c.tchildren) {
      const foundAndRemoved = removeById(c.tchildren, targetComp)

      // If we found and removed the item in children, no need to continue
      if (foundAndRemoved[0]) {
        const newC = { ...c, tchildren: foundAndRemoved[1] }
        const newComps = appComponentsSetComponent(newC)(comps)
        return [true, newComps]
      }
    }
  }
  return [false, comps]
}

export const getNumChildren = (
  compArray: ComponentModel[],
  total?: number,
): number => {
  return compArray.reduce(
    (accumulator, current) => {
      if (isDefined(current.tchildren)) {
        const result = getNumChildren(current.tchildren)
        return accumulator + result + 1
      } else {
        return accumulator + 1
      }
    },
    isDefined(total) ? total : 0,
  )
}

// Return first component within the provided array of components, and their children, whose id matches the given component
export const getComponentRecursive =
  (findComp: ComponentModel) =>
  (sourceComps: ComponentModel[]): ComponentModel | undefined => {
    for (const element of sourceComps) {
      if (isDefined(element.tchildren)) {
        const result = getComponentRecursive(findComp)(element.tchildren)
        if (result !== undefined) {
          return result
        }
      } else if (element.id === findComp.id) {
        return element
      }
    }
    return undefined
  }

export const removeDesignRecursive = (
  appComps: ComponentModel[],
): ComponentModel[] => {
  return appComps.map(c => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { design, ...cleaned } = c

    if (cleaned.tchildren) {
      const cleanedChildren = removeDesignRecursive(cleaned.tchildren)
      return { ...cleaned, tchildren: cleanedChildren }
    } else {
      return cleaned
    }
  })
}

export const insertComponentAfter = (
  toInsert: ComponentModel,
  targetComp: ComponentModel,
  appComps: ComponentModel[],
): ComponentModel[] => {
  const [injected, newChildren] = injectComponentRecursive(
    toInsert,
    targetComp,
    appComps,
  )
  if (injected) {
    return newChildren
  } else {
    logErr('Failed to inject component')
    return appComps
  }
}

export const injectComponentRecursive = (
  toInsert: ComponentModel,
  targetComp: ComponentModel,
  children: ComponentModel[],
): [boolean, ComponentModel[]] => {
  const getId = (comp: ComponentModel) => comp.uiBuilderId

  const uniqueCid: string = getId(targetComp)

  const { injected, newChildren } = children.reduce(
    (
      acc: { injected: boolean; newChildren: ComponentModel[] },
      child: ComponentModel,
      index,
    ) => {
      if (acc.injected) {
        return acc
      } else {
        if (getId(child) === uniqueCid) {
          const newArr = [...children]
          newArr.splice(index + 1, 0, toInsert)
          return { injected: true, newChildren: newArr }
        } else if (child.tchildren) {
          const [cInjected, cNewChildren] = injectComponentRecursive(
            toInsert,
            targetComp,
            child.tchildren,
          )
          if (cInjected) {
            child.tchildren = cNewChildren
            return { injected: cNewChildren, newChildren: children }
          }
        }
        return { injected: false }
      }
    },
    { injected: false, newChildren: [] as ComponentModel[] },
  )
  return [injected, newChildren]
}

export const appComponentsSetComponent =
  (newcomp: ComponentModel) =>
  (appComps: ComponentModel[]): ComponentModel[] => {
    let alreadyReplacedFasthack = false //avoid fold on that level, stores if component was already replaced
    const newComps: ComponentModel[] = appComps.map(oldcomp => {
      if (alreadyReplacedFasthack) return oldcomp
      else {
        const [m, res] = setComponentRecursive(newcomp)(oldcomp, true)
        alreadyReplacedFasthack = m //avoid fold which recreates arrays
        return res
      }
    })
    return newComps
  }

export const setComponentRecursive =
  (newcomp: ComponentModel) =>
  (
    oldcomp: ComponentModel,
    useEditorId?: boolean,
  ): [boolean, ComponentModel] => {
    const getId = useEditorId
      ? (comp: ComponentModel) => comp.uiBuilderId
      : (comp: ComponentModel) => getUniqueId(comp)

    const uniqueCid: string = getId(newcomp)

    if (getId(oldcomp) === uniqueCid) {
      return [true, newcomp]
    } else if (Array.isArray(oldcomp.tchildren)) {
      // this is fold we could go to a hack like above if slow
      const { match, cs } = oldcomp.tchildren.reduce(
        (
          acc: { match: boolean; cs: ComponentModel[] },
          oldchild: ComponentModel,
        ) => {
          if (acc.match) return { match: true, cs: [...acc.cs, oldchild] }
          else {
            const [m, nc] = setComponentRecursive(newcomp)(
              oldchild,
              useEditorId,
            )
            return { match: m, cs: [...acc.cs, nc] }
          }
        },
        { m: false, cs: [] as ComponentModel[] },
      )

      if (match) {
        return [match, { ...oldcomp, tchildren: cs }]
      } else return [match, oldcomp]
    } else if (isDefined(oldcomp.child)) {
      const [changed, res] = setComponentRecursive(newcomp)(
        oldcomp.child,
        useEditorId,
      )
      if (changed) {
        return [changed, { ...oldcomp, child: res }]
      } else {
        return [false, oldcomp]
      }
    } else {
      return [false, oldcomp]
    }
  }

export const stateSetSkeleton = (
  appId: AppId,
  newskel: AppSkeleton,
  state: State,
): State => {
  return stateOverApp(
    appId,
    appreg => {
      return { ...appreg, app_skeleton: newskel }
    },
    state,
  )
}

export const stateOverApp = (
  appId: AppId,
  fn: (_: AppRegistration) => AppRegistration,
  state: State,
): State => {
  return match(state)
    .with({ type: 'success' }, st => {
      const old = imGet(st.apps)(appId)
      if (old) {
        const nmp = imSet(st.apps)(appId, [old[0], fn(old[1])])
        return { ...st, apps: nmp }
      } else {
        logWarn({
          type: 'stateOverAppErr',
          warn: 'Unexpected, missing app in stateOverApp:' + appId,
        })
        return st
      }
    })
    .with({ type: 'critical' }, () => {
      return state
    })
    .exhaustive()
}

export const appOverSkeleton = (
  fn: (_: AppSkeleton) => AppSkeleton,
  appReg: AppRegistration,
): AppRegistration => {
  return { ...appReg, app_skeleton: fn(appReg.app_skeleton) }
}

export const mergeListNoOverride = (
  debugLoc: string,
  storelist: ListModel,
  msglist: ListModel,
  concurrency: AppConcurrency,
): ListModel => {
  //components by id
  const msgChildrenById = Map<string, ComponentModel>(
    msglist.tchildren
      .filter(msgchildcomp => msgchildcomp.id) //remove recommended components, (no id)
      .map(msgchildcomp => [msgchildcomp.id, msgchildcomp]),
  )

  const newStateTChildren = storelist.tchildren.map(statecmp => {
    const frommsg = msgChildrenById.get(statecmp.id)
    //if matching id was sent from server merge, otherwise assume user just added this component
    //    const res = frommsg ? updateComp(statecmp, frommsg): statecmp
    //    return res;
    if (frommsg) {
      // logDebug({mergeListMsg: "merging", state: statecmp, msg: frommsg})
      return updateComp(debugLoc, true, false, concurrency)(statecmp, frommsg)
    } else {
      // logDebug({mergeListMsg: "assuming user just added", state: statecmp})
      return statecmp
    }
  })

  const newRecoms = msgListRecommendations(storelist.tignore ?? [])(msglist)

  const list: ListModel = {
    ...storelist,
    tchildren: newStateTChildren,
    numchildren: newStateTChildren.length,
    //numchildren is not changed
    trecommend: newRecoms,
  }

  if (newStateTChildren.length > 0) {
    return { ...list, trecommend: removeDuplicateHashes(newRecoms) }
  } else {
    //TODO we could do that faster without reusing userAcceptRecommended
    const res = newRecoms.reduce((acc, { content_hash, component }) => {
      return userAcceptRecommendedBestEffort(component)(content_hash)(acc)
    }, list)
    //Please keep this, much easier to debug
    //logDebug("mergeListNoOverride", {res: res})
    return res
  }
}

export const mergeListOverride =
  (storelist: ListModel) =>
  (msglist: ListModel): ListModel => {
    const newStateTChildren = msglist.tchildren.filter(
      msgchildcomp => msgchildcomp.id,
    )

    const newRecoms = msgListRecommendations([])(msglist)

    const list: ListModel = {
      ...storelist,
      tchildren: newStateTChildren,
      numchildren: newStateTChildren.length,
      trecommend: newRecoms,
      tignore: [],
    }

    //TODO we could do that faster without reusing userAcceptRecommended
    const res = newRecoms.reduce((acc, { content_hash, component }) => {
      return userAcceptRecommendedBestEffort(component)(content_hash)(acc)
    }, list)
    //please keep this, much easier to debug
    return res
  }

/**
 * With override in place just accept what server send
 */
export const mergeListOverrideWithPrevious = (
  debugLoc: string,
  storelist: ListModel,
  msglist: ListModel,
  prevlist: ListModel,
  triggerCompState: ComponentModel,
  concurrency: AppConcurrency,
): ListModel => {
  const childrenWithIds = msglist.tchildren.filter(child => child.id)

  const storeChildrenWithIds = storelist.tchildren.filter(child => child.id)

  const prevChildrenWithIds = prevlist.tchildren.filter(child => child.id)

  const newStateTChildren = childrenWithIds.map((child, index) => {
    const currentChild = storeChildrenWithIds[index]
    return updateComp(
      debugLoc,
      false,
      true,
      concurrency,
      prevChildrenWithIds,
      triggerCompState,
      true,
    )(currentChild, child)
  })

  const newRecoms = msgListRecommendations([])(msglist)

  const list: ListModel = {
    ...storelist,
    tchildren: newStateTChildren,
    numchildren: newStateTChildren.length,
    trecommend: newRecoms,
    tignore: [],
  }

  //TODO we could do that faster without reusing userAcceptRecommended
  const res = newRecoms.reduce((acc, { content_hash, component }) => {
    return userAcceptRecommendedBestEffort(component)(content_hash)(acc)
  }, list)
  //please keep this, much easier to debug
  return res
}

export const newValFromHistory = (
  resState: ComponentModel,
  currentState: ComponentModel,
  prevCompState: ComponentModel,
  triggerState: Nullable<ComponentModel>,
  id: string,
  concurrency: AppConcurrency,
): ComponentModel => {
  const newResState = { ...resState, id: id }
  const newCurrentState = { ...newResState, value: currentState.value }

  const onlyUserUpdated =
    isDefined(prevCompState) &&
    resState.value === prevCompState.value &&
    currentState.value !== prevCompState.value

  const onlyServerUpdated =
    isDefined(prevCompState) &&
    resState.value !== prevCompState.value &&
    currentState.value === prevCompState.value

  if (concurrency === 'force_server' || resState.value === currentState.value) {
    return newResState
  } else if (concurrency === 'prefer_server') {
    if (onlyUserUpdated) {
      return newCurrentState
    } else {
      return newResState
    }
  } else if (concurrency === 'prefer_user') {
    if (onlyServerUpdated) {
      return newResState
    } else {
      return newCurrentState
    }
  } else if (concurrency === 'ui_reconcile') {
    logWarn('ui_reconcile not yet implemented!')
    return newResState
  }
}

export const checkIds = (
  resState: ComponentModel,
  currentState: ComponentModel,
  inList: boolean,
  debugLoc: string,
) => {
  const responseCompIdDifferent = currentState.id !== resState.id
  const responseLostId = !resState.id && currentState.id && !inList
  const responseCompIdMismatch =
    currentState.id && resState?.id && responseCompIdDifferent

  if (responseCompIdMismatch) {
    logDebug(`updateComp - ${debugLoc}: updateComp handling incompatible IDs`, {
      updateCompErr: 'Incompatible Ids',
      statecomp: currentState,
      msgcomp: resState,
    })
    return false
  } else if (responseLostId) {
    logWarn(`${debugLoc} - server lost ID`, {
      was: currentState,
      now: resState,
    })
  } else if (responseCompIdDifferent) {
    logErr(`${debugLoc} - server changed ID`, {
      was: currentState,
      now: resState,
    })
  } else if (!currentState.id) {
    logWarn(`${debugLoc} - no component ID in state`, {
      dta: currentState,
    })
  }
  return true
}

export const updateListComps = (
  updatedComp: ComponentModel,
  currentState: ComponentModel,
  isOverride: boolean,
  prevCompState: Nullable<ComponentModel>,
  debugLoc: string,
  resState: ComponentModel,
  triggerCompState: ComponentModel,
  concurrency: AppConcurrency,
) => {
  const numPrevChildren =
    isDefined(prevCompState) && isDefined(prevCompState.tchildren)
      ? prevCompState.tchildren.length
      : 0
  const numNewChildren = isDefined(updatedComp.tchildren)
    ? updatedComp.tchildren.length
    : 0
  const numCurrentChildren = isDefined(currentState.tchildren)
    ? currentState.tchildren.length
    : 0

  // Is true if the number of response children matches the number at request time, but doesn't match the current number
  const compUpdatedDuringReq =
    numPrevChildren === numNewChildren && numNewChildren !== numCurrentChildren

  if (isListModel(updatedComp)) {
    if (isOverride) {
      if (isDefined(prevCompState)) {
        if (compUpdatedDuringReq) {
          return mergeListNoOverride(
            debugLoc,
            currentState,
            updatedComp,
            concurrency,
          )
        } else
          return mergeListOverrideWithPrevious(
            debugLoc,
            currentState,
            updatedComp,
            prevCompState,
            triggerCompState,
            concurrency,
          )
      }
      // TODO: But also need to check values of children, not just number of children
      return mergeListOverride(currentState)(updatedComp)
    } else
      return mergeListNoOverride(
        debugLoc,
        currentState,
        updatedComp,
        concurrency,
      )
  } else {
    logWarn(`${debugLoc}:`, {
      updateCompErr: 'Incompatible Nesting',
      statecomp: currentState,
      msgcomp: resState,
    })
    return updatedComp
  }
}

export const updateCarouselComps = (
  updatedComp: ComponentModel,
  currentState: ComponentModel,
  isOverride: boolean,
  debugLoc: string,
  resState: ComponentModel,
  triggerCompState: ComponentModel,
  inList: boolean,
  msgState: ComponentModel,
  concurrency: AppConcurrency,
): ComponentModel => {
  /**
    carousel starts empty in the skeleton and then will be server controlled
    any actions involving carousel need to force loading
    complete (or any non-loading action) hopefully does not set position or change list size,
    if it does we have no way to resolving
    the conflicts that will not upset the user
    special "rude" option allows to override components on each message. 
  */
  const nonRudeCarouselChildren =
    'rude' !== currentState.carousel_type &&
    Array.isArray(currentState.tchildren) &&
    currentState.tchildren.length > 0

  const newChildren = nonRudeCarouselChildren
    ? zipWith(
        currentState.tchildren,
        resState.tchildren,
        updateComp(
          debugLoc,
          inList,
          isOverride,
          concurrency,
          msgState,
          triggerCompState,
          true,
        ),
      )
    : resState.tchildren.map(addIdsToCompIfMissing)

  let newPos = 0
  if (isDefined(resState.position)) {
    if (
      'rude' === currentState.carousel_type ||
      isOverride ||
      !currentState.tchildren
    ) {
      newPos = resState.position
    }
  } else if (currentState.tchildren && isDefined(currentState.position)) {
    newPos = currentState.position
  }

  return { ...updatedComp, position: newPos, tchildren: newChildren }
}

export const handleNonOverrideParentComps = (
  updatedComp: ComponentModel,
  currentState: ComponentModel,
  debugLoc: string,
  resState: ComponentModel,
  triggerCompState: ComponentModel,
  inList: boolean,
  msgState: ComponentModel,
  concurrency: AppConcurrency,
): ComponentModel => {
  if (Array.isArray(currentState.tchildren)) {
    if (!Array.isArray(resState.tchildren)) {
      logWarn(`Incompatible Nesting ${debugLoc}`, {
        updateCompErr: 'Incompatible Nesting',
        statecomp: currentState,
        msgcomp: resState,
      })
    } else {
      return {
        ...updatedComp,
        tchildren: zipWith(
          currentState.tchildren,
          resState.tchildren,
          updateComp(
            debugLoc,
            inList,
            false,
            concurrency,
            msgState,
            triggerCompState,
          ),
        ),
      }
    }
  } else if (isDefined(currentState.child)) {
    if (isUndefined(resState.child)) {
      logWarn(`Incompatible Child ${debugLoc}`, {
        statecomp: currentState,
        msgcomp: resState,
      })
    } else {
      return {
        ...updatedComp,
        child: updateComp(
          debugLoc,
          inList,
          false,
          concurrency,
          msgState,
        )(currentState.child, resState.child),
      }
    }
  }
  return updatedComp
}

export const handleOverrideParentComps = (
  updatedComp: ComponentModel,
  currentState: ComponentModel,
  debugLoc: string,
  resState: ComponentModel,
  triggerCompState: ComponentModel,
  inList: boolean,
  msgState: ComponentModel,
  concurrency: AppConcurrency,
) => {
  if (!Array.isArray(resState.tchildren)) {
    logWarn(`Incompatible Nesting ${debugLoc}`, {
      updateCompErr: 'Incompatible Nesting',
      statecomp: currentState,
      msgcomp: resState,
    })
    return updatedComp
  } else if (!Array.isArray(currentState.tchildren)) {
    return updatedComp
  } else if (currentState.type === 'filter-results') {
    //TODO create idiosyncratic handling for this, fix server losing IDs, or rename tchildren
    return { ...updatedComp, tchildren: [...resState.tchildren] }
  } else {
    //TODO this is needed because server keeps dropping ids, this will try to reinstate them
    return {
      ...updatedComp,
      tchildren: zipWith(
        currentState.tchildren,
        resState.tchildren,
        updateComp(
          debugLoc,
          inList,
          true,
          concurrency,
          msgState,
          triggerCompState,
          true,
        ),
      ),
    }
  }
}

export const updateComp =
  (
    debugLoc: string,
    inList: boolean,
    isOverride: boolean,
    concurrency: AppConcurrency,
    msgState?: ComponentModel[],
    triggerCompState?: ComponentModel,
    checkChildren?: boolean,
  ) =>
  (currentState: ComponentModel, resState: ComponentModel): ComponentModel => {
    // The state of the component when the message was initially sent, AKA the "previous" state
    const prevCompState = checkChildren
      ? getComponentRecursive(resState)(msgState ?? [])
      : msgState?.find(c => c.id === resState.id)
    const id = inList ? resState.id : resState.id || currentState.id

    // ID checking
    const proceed = checkIds(resState, currentState, inList, debugLoc)

    // If a previous state was recorded, implement special merging rules
    const updatedComp: ComponentModel = isDefined(prevCompState)
      ? newValFromHistory(
          resState,
          currentState,
          prevCompState,
          triggerCompState,
          id,
          concurrency,
        )
      : { ...resState, id: id }

    if (!proceed) {
      return updatedComp
    }

    // Handle lists
    if (isListModel(currentState)) {
      return updateListComps(
        updatedComp,
        currentState,
        isOverride,
        prevCompState,
        debugLoc,
        resState,
        triggerCompState,
        concurrency,
      )
    }

    // Handle carousels
    if (isCarouselModel(currentState)) {
      return updateCarouselComps(
        updatedComp,
        currentState,
        isOverride,
        debugLoc,
        resState,
        triggerCompState,
        inList,
        msgState,
        concurrency,
      )
    }

    // Handle overriding parents
    if (isOverride) {
      return handleOverrideParentComps(
        updatedComp,
        currentState,
        debugLoc,
        resState,
        triggerCompState,
        inList,
        msgState,
        concurrency,
      )
    }

    // Handle idiosyncratic components
    if (isIdiosyncraticCompType(currentState.type)) {
      //isOverride is handled generically above
      return mergeIdiosyncratic(currentState.type, currentState, updatedComp)[1]
    }

    if (currentState.value) {
      updatedComp.value = currentState.value //we do not have any internal state on components at this time
    }

    // Handle non-overriding parents
    return handleNonOverrideParentComps(
      updatedComp,
      currentState,
      debugLoc,
      resState,
      triggerCompState,
      inList,
      msgState,
      concurrency,
    )
  }

/**
 * Define what to do when merging server messages for IdiosyncraticComponentType
 * decide what part of incomming 'msgcomp' to use vs what part of 'statecomp' to use.
 * These are currnetly synonyms of 'any' and to get type safety
 * we return a tuple.
 *
 * This is not used with "override" merges
 */
const mergeIdiosyncratic = (
  ctype: IdiosyncraticComponentType,
  statecomp: ComponentModel,
  msgcomp: ComponentModel,
): [IdiosyncraticComponentType, ComponentModel] => {
  //do not simplify return type to ComponentModel
  switch (ctype) {
    case 'file-drop': {
      // user owned we do not expect server suggestions here?
      if (
        isFileDropModel(statecomp) &&
        Array.isArray(statecomp.files_raw) &&
        statecomp.files_raw.length > 0
      )
        return [ctype, { ...msgcomp, files_raw: statecomp.files_raw }]
      else return [ctype, msgcomp]
    }
    case 'multi-select': {
      if (
        isMultiSelectModel(statecomp) &&
        Array.isArray(statecomp.selections) &&
        statecomp.selections.length > 0
      )
        return [ctype, { ...msgcomp, selections: statecomp.selections }]
      else return [ctype, msgcomp]
    }
    case 'text-snippets': {
      //TODO this is wrong but best we can do?
      //this assumes server can suggests, it is not safe
      logInfo('text-snippets are not supported yet')
      return [ctype, msgcomp]
    }
    case 'claim-list': {
      if (Array.isArray(statecomp.claims) && statecomp.claims.length > 0)
        return [ctype, statecomp]
      else return [ctype, msgcomp]
    }
    case 'filter-results': {
      return [ctype, msgcomp]
    }
  }
}

/**
 * This will clear clearOutCommand dismissing spaceship for valid msgAppId
 * It will also set global warning that will be displayed in current app until dismissed
 * @param w
 * @param st
 * @param msgAppId
 * @returns
 */
const processGlobalWarning = (
  w: Warning,
  st: SuccessState,
  msgAppId: Nullable<AppId>,
): State => {
  const st1 = match(w)
    .with({ type: 'warning' }, warn => {
      return { ...st, unexpectedWarn: warn }
    })
    .with({ type: 'noWarn' }, () => st)
    .exhaustive()
  if (msgAppId && msgAppId !== '' && msgAppId !== 'unknown') {
    return stateOverApp(msgAppId, clearOutCommand, st1)
  } else return st1
}

/**
 * Given a message from server update model state
 */
export const updateState = (
  officeCmdHandler: CmdHandler,
  msg: Message,
  state: State,
  matchingMsg?: Nullable<MessageLogEntry>,
): State => {
  return match(state)
    .with({ type: 'success' }, st => {
      const payload_any: any = msg?.payload
      //server can send error information without any components
      const hasComponents: boolean =
        isDefined(msg) && isDefined(msg.payload.components)
      if (
        msg?.payload.command &&
        (msg?.payload.command['@'] === 'display' ||
          msg?.payload.command['@'] === 'display-result')
      ) {
        const appid = msg?.payload.app_id
        const currentApp = imGet(st.apps)(appid)
        if (currentApp) {
          const [warn, stateappreg] = currentApp
          const isOverride = msg?.payload.command.sub_command === 'override'
          const concurrency = matchingMsg?.protocol ?? 'force_server'
          const napps: AppRegistration = hasComponents
            ? {
                ...stateappreg,
                app_skeleton: {
                  ...stateappreg.app_skeleton,
                  components: zipWith(
                    stateappreg.app_skeleton.components,
                    msg?.payload.components,
                    updateComp(
                      stateappreg.app_id,
                      false,
                      isOverride,
                      concurrency,
                      matchingMsg?.currentState,
                      matchingMsg?.triggeringComponent,
                    ),
                  ),
                  command: msg?.payload.command, //Note: this replaces command removing any internal state (e.g. dialog box dismissal)
                },
                out_command: null, //Note: this removes internal state (loading state) (do not remove comment)
              }
            : {
                ...stateappreg,
                app_skeleton: {
                  ...stateappreg.app_skeleton,
                  command: msg?.payload.command, //Note: this replaces command removing any internal state (e.g. dialog box dismissal)
                },
                out_command: null, //Note: this removes internal state (loading state) (do not remove comment)
              }
          const nmp = imSet(st.apps)(appid, [warn, napps])
          const res = { ...st, apps: nmp }
          logDebug(`updateState ${appid}`, {
            newReg: napps,
            updatedState: res,
          })
          return res
        } else {
          logDebug({
            updateStateErr:
              'Message for unknown component ' + msg?.payload?.app_id,
            msg: msg,
          })
          const warn: Warning = {
            type: 'warning',
            warn: 'Message for unknown component ' + msg?.payload?.app_id,
            dta: msg,
          }
          return processGlobalWarning(warn, st, null)
        }
      } else {
        const appid = msg?.payload.app_id
        if (isCmdMessage(payload_any)) {
          const cmdRes = officeCmdHandler(payload_any) //Do not update state, this  message was intended for office handler.
          return processGlobalWarning(cmdRes, st, appid)
        } else {
          //TODO need to invoke handler callback (to be defined and passed here)
          logWarn({ updateStateErr: 'Unsupported message', msg: msg })
          const warn: Warning = {
            type: 'warning',
            warn: 'Unsupported message',
            dta: msg,
          }
          return processGlobalWarning(warn, st, appid)
        }
      }
    })
    .with({ type: 'critical' }, () => {
      logFatal({
        updateStateErr: 'Not updatable (critical) state',
        msg: msg,
      })
      return state
    })
    .exhaustive()
}

export const emptyState: State = {
  type: 'critical',
  err: {
    msg: 'Not Initialized',
    hint: '',
    err: {},
  },
}

const bootstrapResR = (
  usr: User,
  { suggestions, guid, app_list }: InitialAppData,
): State => {
  const dta = app_list.reduce(
    (acc, appreg) => {
      const item: [Warning, AppRegistration] = [{ type: 'noWarn' }, appreg]

      return {
        lst: [
          ...acc.lst,
          {
            app_id: appreg.app_id,
            app_name: appreg.app_name,
            app_agent: appreg.app_agent,
          },
        ],
        mp: imSet(acc.mp)(appreg.app_id, item),
      }
    },
    {
      lst: [] as AppItem[],
      mp: imMapEmpty<AppId, [Warning, AppRegistration]>(),
    },
  )

  return {
    guid,
    suggestions,
    type: 'success',
    appList: dta.lst,
    apps: dta.mp,
    juvoUser: usr,
  }
}

const bootstrapResL = (fetchErr: FetchError): State => {
  return {
    type: 'critical',
    err: critNetworkErr('Failed to establish JUVO session', fetchErr),
  }
}

export const bootstrapRes = (
  usr: User,
  res: Either<FetchError, InitialAppData>,
): State => {
  if (res.type == 'left') return bootstrapResL(res.content)
  else return bootstrapResR(usr, res.content)
}
