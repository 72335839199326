import { Alert, Button, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { AppRegistration } from '../../../types'
import {
  getHelpDismissedForApp,
  getJuvoConfig,
  setHelpDismissedForApp,
} from '../../../utils'

const AppHelp: React.FC<{
  app: AppRegistration
}> = ({ app }) => {
  const [showHelp, setShowHelp] = useState(false)
  const { environment } = getJuvoConfig()
  const loc = useLocation()

  useEffect(() => {
    if (!app.app_help) {
      setShowHelp(false)
      return
    }
    setShowHelp(
      loc.pathname === '/ui-builder' // Always show it on ui-builder for the developers sake
        ? true
        : !getHelpDismissedForApp(app.app_id, app.app_version, environment),
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [app.app_id, app.app_version, app.app_help])

  const clearAndRemember = () => {
    setShowHelp(false)
    setHelpDismissedForApp(app.app_id, app.app_version, environment)
  }

  return (
    showHelp && (
      <Alert
        severity="info"
        sx={{
          border: style => `1px solid ${style.palette.primary.light}`,
          borderRadius: '0',
          display: 'flex',
          background: style => style.palette.info.main,
          alignItems: 'center',
          padding: '16px',
          margin: '8px 0 16px',
          gap: '8px',
        }}
        icon={<i className="fas fa-sharp fa-hand-wave fa"></i>}
        action={<Button onClick={clearAndRemember}>Got it</Button>}
      >
        <Typography
          variant="h6"
          sx={{
            color: theme => theme.palette.text.primary,
            marginBottom: '8px',
          }}
        >
          Welcome to {app.app_name}!
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{
            color: theme => theme.palette.primary.dark,
          }}
        >
          {app.app_help}
        </Typography>
      </Alert>
    )
  )
}

export default AppHelp
