import React, { useEffect, useMemo, useState } from 'react'
import { SxProps } from '@mui/material/styles'
import Box from '@mui/material/Box/Box'

import { ComponentModel } from '../../../types'
import { ComponentAttributeSwitchyard } from '../ComponentAttribute/ComponentAttributeSwitchyard'
import { COMP_PROPERTY_WHITELIST, EditableProperty } from '../data/properties'
import ArrayAttributeInput from '../ComponentAttribute/ArrayAttributeInput'
import { TabsAttributeInput } from '../ComponentAttribute/TabsAttributeInput'
import { UIBuilderHintProvider } from '../Hint/UIBuilderHintContext'

export const DrawerComponentEditor = ({
  comp,
  updateComp,
  labelStyles,
  inputStyles,
}: {
  comp: ComponentModel
  updateComp: (comp: ComponentModel) => void
  labelStyles: SxProps
  inputStyles: SxProps
}) => {
  const [compState, setCompState] = useState(comp)

  const keyVals: [string, EditableProperty][] = useMemo(() => {
    return Object.keys(comp)
      .filter(val => {
        return (
          COMP_PROPERTY_WHITELIST.has(val) ||
          COMP_PROPERTY_WHITELIST.has(`${comp.type}.${val}`)
        )
      })
      .map(k => {
        if (COMP_PROPERTY_WHITELIST.has(`${comp.type}.${k}`)) {
          return [
            k,
            COMP_PROPERTY_WHITELIST.get(`${comp.type}.${k}`) ?? {
              type: 'UNDEFINED',
            },
          ]
        } else if (COMP_PROPERTY_WHITELIST.has(k)) {
          return [k, COMP_PROPERTY_WHITELIST.get(k) ?? { type: 'UNDEFINED' }]
        } else {
          return [k, { type: 'UNDEFINED' }]
        }
      })
  }, [comp])

  useEffect(() => {
    setCompState(comp)
  }, [comp])

  const applyVal = (key: string) => (val: any) => {
    updateComp({ ...compState, [key]: val })
  }

  const applyTab = (newComp: ComponentModel) => {
    updateComp(newComp)
  }

  return (
    <UIBuilderHintProvider>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          gap: '12px',
          flexDirection: 'column',
          padding: '3px',
        }}
      >
        {keyVals.map((pair, index) => {
          const attrVal = comp[pair[0]] ?? pair[1].defaultValue ?? null
          const commonProps = {
            attrKey: pair[0],
            attrVal,
            ...(pair[1].multiline && { multiline: true, minRows: 3 }),
          }
          return pair[1].isArray ? (
            <ArrayAttributeInput
              key={index}
              applyVal={applyVal(pair[0])}
              {...commonProps}
            />
          ) : pair[1].type === 'tabs' ? (
            <TabsAttributeInput
              key={index}
              applyVal={applyTab}
              inputStyles={inputStyles}
              labelStyles={labelStyles}
              comp={comp}
              {...commonProps}
            />
          ) : (
            <ComponentAttributeSwitchyard
              key={index}
              applyVal={applyVal(pair[0])}
              valType={pair[1]}
              inputStyles={inputStyles}
              labelStyles={labelStyles}
              {...commonProps}
            />
          )
        })}
      </Box>
    </UIBuilderHintProvider>
  )
}
