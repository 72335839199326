import React from 'react'

import UIBuilderProvider from '../../providers/UIBuilderProvider'

import { UIBuilder, UIBuilderProps } from './UIBuilder'

export const UIBuilderRoute: React.FC<UIBuilderProps> = ({
  apps,
  user,
  customReactComps,
}) => {
  return (
    <UIBuilderProvider>
      <UIBuilder apps={apps} user={user} customReactComps={customReactComps} />
    </UIBuilderProvider>
  )
}
