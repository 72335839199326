import { IconButton, Tooltip } from '@mui/material'
import React from 'react'

export type UIBuilderHeaderVariant = 'primary' | 'secondary'

interface UIBuilderHeaderButtonProps {
  icon: string
  action: (event: React.MouseEvent<HTMLButtonElement>) => void
  tooltip?: string
  disabled?: boolean
  variant?: UIBuilderHeaderVariant
}

export const UIBuilderHeaderButton: React.FC<UIBuilderHeaderButtonProps> = ({
  icon,
  action,
  tooltip,
  disabled = false,
  variant = 'secondary',
}) => {
  return (
    <Tooltip arrow title={tooltip}>
      <IconButton
        size="small"
        onClick={action}
        // We cannot used disabled property otherwise it would break the tooltip
        sx={{
          background: style =>
            disabled
              ? style.palette.grey[700]
              : variant === 'primary'
                ? style.palette.tertiary.main
                : style.palette.grey[600],
          borderRadius: 0,
          height: '36px',
          width: '36px',
          padding: '0',
          fontSize: '1.1em',
          '&:hover': {
            background: style =>
              variant === 'primary'
                ? style.palette.tertiary?.dark
                : style.palette.grey[700],
          },
        }}
      >
        <i
          className={`fa-solid fa-sharp ${disabled ? 'fa-spinner fa-spin' : icon}`}
        />
      </IconButton>
    </Tooltip>
  )
}
